import { useEffect, useState } from "react";
import axios from "axios";
import { useAuth } from "../../../../../hooks/auth";
import { useUsers } from "../../../../../hooks/users";
import { useProjects } from "../../../../../hooks/projects";

import { IoArrowBack } from "react-icons/io5";
import { Card } from "../../styles";
import { FiUserPlus } from "react-icons/fi";
import { FaPlus, FaCrown, FaCheck } from "react-icons/fa";
import { AiOutlineUser } from "react-icons/ai";
import { MdAlternateEmail, MdOutlineAdminPanelSettings } from "react-icons/md";
import { CiLock } from "react-icons/ci";
import { PiIdentificationBadge, PiBlueprintLight } from "react-icons/pi";
import { IoCubeOutline } from "react-icons/io5";
import { GoShieldCheck } from "react-icons/go";
import { FiUser } from "react-icons/fi";

export default function NewUser({ setStatus, handleClose }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cargo, setCargo] = useState("user");
  const [sortedProjects, setSortedProjects] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]); // Adicionado para gerenciar projetos selecionados

  const { token, user } = useAuth();
  const { fetchUsers } = useUsers();
  const { userProjects, fetchUserProjects } = useProjects();

  useEffect(() => {
    fetchUserProjects();
  }, []);

  useEffect(() => {
    const initializeProjects = async () => {
      const sorted = userProjects.sort((a, b) => a.nome.localeCompare(b.nome));
      setSortedProjects(sorted);
    };

    initializeProjects();
  }, [fetchUserProjects]);

  const handleCreate = async () => {
    if (!["god", "admin"].includes(user.cargo)) {
      alert("Acesso negado");
      return;
    }

    if (!name || !email || !password) {
      alert("Please fill in all fields.");
      return;
    }

    try {
      await axios.post(
        "https://dev.vidal-app.com/users/add-user",
        {
          name,
          email,
          senha: password,
          cargo: cargo,
          projetos: selectedProjects,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setStatus("list");
      fetchUsers();
      handleClose();
    } catch (error) {
      console.error("Erro ao criar o usuário:", error);
    }
  };

  // Alterna a seleção de um projeto
  const handleSelectProject = (projectId) => {
    setSelectedProjects((prevSelectedProjects) => {
      if (prevSelectedProjects.includes(projectId)) {
        return prevSelectedProjects.filter((id) => id !== projectId);
      } else {
        return [...prevSelectedProjects, projectId];
      }
    });
  };

  return (
    <Card>
      <div className="header">
        <div className="btn" onClick={() => setStatus("list")}>
          <IoArrowBack />
        </div>
        <FiUserPlus />
        <p>Criando usuário</p>
      </div>

      <div className="display">
        <div className="item ativ">
          <div className="thumb">
            <div className="circle">
              <p>{name.charAt(0).toUpperCase()}</p>
              <FaPlus />
            </div>
          </div>
          <div className="content">
            <p>{name}</p>
            <p className="email">{email}</p>
          </div>
        </div>

        <div className="tag">
          <PiIdentificationBadge />
          <p>Identificação</p>
        </div>

        <div className="input">
          <AiOutlineUser />
          <input
            placeholder="Nome do usuário"
            value={name}
            onChange={(e) => setName(e.target.value)}
            autoComplete="off"
          />
        </div>

        <div className="input">
          <MdAlternateEmail />
          <input
            placeholder="E-mail"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            autoComplete="off"
          />
        </div>

        <div className="input">
          <CiLock />
          <input
            placeholder="Senha"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            autoComplete="off"
          />
        </div>

        <div className="tag">
          <MdOutlineAdminPanelSettings />
          <p>Permissões</p>
        </div>

        {/* Seleção de Cargo */}
        <div className="picker">
          <div
            className={`pick user ${cargo === "user" ? "ativ" : ""}`}
            onClick={() => setCargo("user")}
          >
            <GoShieldCheck />
            <p>Usuário</p>
          </div>
          <div
            className={`pick cliente ${cargo === "client" ? "ativ" : ""}`}
            onClick={() => setCargo("client")}
          >
            <FiUser />
            <p>Cliente</p>
          </div>
          <div
            className={`pick adm ${cargo === "admin" ? "ativ" : ""}`}
            onClick={() => setCargo("admin")}
          >
            <FaCrown />
            <p>Administrador</p>
          </div>
        </div>

        <div className="tag">
          <PiBlueprintLight />
          <p>Projetos</p>
        </div>

        {/* Exibe a lista de projetos em ordem alfabética */}
        <div className="display">
          {sortedProjects.map((project) => {
            const isSelected = selectedProjects.includes(project._id);

            return (
              <div
                className="card"
                key={project._id}
                onClick={() => handleSelectProject(project._id)}
                style={{
                  opacity: isSelected ? 1 : 0.4,
                }}
              >
                <div className="thumb">
                  <IoCubeOutline />
                </div>
                <div className="content">
                  <p>{project.nome}</p>
                  <p>{project.cnpj}</p>
                </div>
                <div
                  className="check"
                  style={{
                    color: isSelected ? "green" : "gray",
                  }}
                >
                  <FaCheck />
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="bottom">
        <div className="btn" onClick={handleCreate}>
          <p>CRIAR</p>
        </div>
      </div>
    </Card>
  );
}
