import React, { useState, useEffect, useRef } from "react";
import { Card, Display } from "./styles";
import { VscNewFile } from "react-icons/vsc";
import { RiCodeSSlashFill } from "react-icons/ri";
import { FaRegCalendar } from "react-icons/fa";
import { IoCubeOutline } from "react-icons/io5";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { useAuth } from "@hooks/auth";
import { AiOutlineHistory } from "react-icons/ai";
const NewNFeCard = () => {
  const [reports, setReports] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const { projeto } = useAuth();
  const wsRef = useRef(null);

  useEffect(() => {
    const ws = new WebSocket("wss://log.vidal-app.com/ws");
    wsRef.current = ws;

    ws.onopen = () => {
      console.log("Conectado ao servidor WebSocket");
      // Carrega a primeira página de mensagens
      loadReports(1);
    };

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.success && data.messages) {
        // Se receber um array de mensagens (resposta do getMessages)
        // Inverte as mensagens para exibir da mais recente para a mais antiga
        const messages = data.messages.reverse();
        setReports((prevReports) => [...prevReports, ...messages]);
        setLoading(false);
      } else if (data.success && data.message) {
        // Se receber uma nova mensagem em tempo real
        setReports((prevReports) => [data.message, ...prevReports]);
      } else {
        console.error("Erro na resposta do servidor:", data.error);
        setLoading(false);
      }
    };

    ws.onerror = (error) => {
      console.error("Erro de conexão:", error);
    };

    ws.onclose = () => {
      console.log("Conexão fechada");
    };

    // Fechar a conexão ao desmontar o componente
    return () => {
      ws.close();
    };
  }, [projeto._id]);

  const loadReports = (pageNumber) => {
    if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
      setLoading(true);
      const requestMessage = {
        action: "getMessages",
        type: "report",
        projectId: projeto._id,
        page: pageNumber,
        limit: 10,
      };
      wsRef.current.send(JSON.stringify(requestMessage));
    }
  };

  // Função para lidar com o scroll infinito
  useEffect(() => {
    const handleScroll = () => {
      if (loading) return;

      const { scrollTop, scrollHeight, clientHeight } =
        document.documentElement;

      if (scrollTop + clientHeight >= scrollHeight - 100) {
        // Quando o usuário chega perto do final da página
        const nextPage = page + 1;
        setPage(nextPage);
        loadReports(nextPage);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [loading, page]);

  if (reports.length === 0) {
    return (
      <Display>
        <div className="header">
          <div className="side">
            <AiOutlineHistory />
            <p>Movimentação</p>
          </div>
        </div>
      </Display>
    );
  }

  return (
    <Display>
      <div className="header head">
        <div className="side">
          <AiOutlineHistory />
          <p>Movimentação</p>
        </div>
      </div>
      <div className="display">
        {reports.map((report, index) => {
          const { nfe, dhEvento, cnpj, status } = report;
          return (
            <Card class="nfe" key={index}>
              <div className="header top">
                <div className="side">
                  <VscNewFile />
                  <p>Nova NFe</p>
                </div>
              </div>
              <div className="infos">
                <div className="items">
                  <div className="item">
                    <RiCodeSSlashFill />
                    <p>{nfe}</p>
                  </div>
                  <div className="item">
                    <FaRegCalendar />
                    <p>{new Date(dhEvento).toLocaleDateString()}</p>
                  </div>
                  <div className="item">
                    <IoCubeOutline />
                    <p>{cnpj}</p>
                  </div>
                  <div className="item">
                    <IoIosInformationCircleOutline />
                    <p>{status}</p>
                  </div>
                </div>
              </div>
            </Card>
          );
        })}
        {loading && (
          <div className="header">
            <p>Movimentação</p>
          </div>
        )}
      </div>
    </Display>
  );
};

export default NewNFeCard;
