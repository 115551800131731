import styled from "styled-components";

// Container principal
export const Container = styled.div`
  margin-top: 6em;
  width: 100%;
  height: 100vh;
  display: flex;



  p {
    font-weight: 400 !important;
  }
`;

// Lado
export const Side = styled.div`
  width: 24em;
  height: 90%;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-right: 1px solid ${({ theme }) => theme.border5};
`;

export const Display = styled.div`
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;

  .header {
    width: 100%;
    min-height: 2em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${({ theme }) => theme.border5};
    background-color: ${({ theme }) => theme.custom};
    padding-left: 1em;

    .side {
      width: max-content;
      height: 100%;
      align-items: center;
      display: flex;
    }

    .add-btn {
      cursor: pointer;
      margin-right: 0.5em;
      height: 2em;
      width: 2em;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        font-size: 0.8em;
        color: ${({ theme }) => theme.primary};
      }

      &:hover {
        opacity: 0.8;
      }
    }

    p {
      font-size: 0.8em;
      opacity: 0.7;
    }

    svg {
      opacity: 0.8;
      font-size: 0.9em;
      margin-right: 1em;
    }
  }

  .display {
    width: 100%;
    height: 100%;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    overflow: overlay;
    align-items: center;
  }
`;

// Estilo do Card
export const Card = styled.div`
  min-height: 11em;
  width: 93%;
  margin-top: 10px;
  height: max-content;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.custom};
  border: 1px solid ${({ theme }) => theme.border3};

  overflow: hidden;

  .head {
    width: 100%;
    height: 2em;
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${({ theme }) => theme.border5};
    justify-content: space-between;
    
    .side {
      width: max-content;
      height: 100%;
      align-items: center;
      display: flex;
    }

    &.top {
      border-left: 3px solid rgba(0, 150, 50);
    }

    p,
    svg {
      font-size: 0.8em;
      opacity: 0.7;
      margin-left: 5px;
      margin-right: 15px;
    }

    svg {
      margin-left: 10px;
    }
  }

  .custom-loader {
    width: 15px;
    height: 15px;
    border: 2px solid #3498db;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .task {
    padding: 20px;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .side {
      display: flex;
      align-items: center;
      height: max-content;
    }

    .thumb {
      width: 2em;
      height: 2em;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid ${({ theme }) => theme.border5};
      border-radius: 50%;
      margin-right: 10px;
    }

    p {
      font-size: 0.9em;
      font-weight: 300;
      opacity: 0.8;
    }
  }

  .load {
    width: 100%;
    height: 0.4em;
    display: flex;
    border: 1px solid ${({ theme }) => theme.border5};

    &.process {
      margin-bottom: 20px;
    }

    .progress {
      width: 30%;
      height: 100%;
      background-color: rgba(0, 150, 50);
    }
  }

  .desc {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid ${({ theme }) => theme.border5};

    .text {
      padding: 10px 15px;
      line-height: 20px;

      p {
        font-size: 0.8em;
        opacity: 0.7;
      }
    }
  }

  .infos {
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;

    .items {
      display: flex;
      flex-direction: column;

      .item {
        display: flex;
        align-items: center;
        padding: 10px 15px;
        border-bottom: 1px solid ${({ theme }) => theme.border5};

        p,
        svg {
          font-size: 0.8em;
          opacity: 0.7;
          font-weight: 300;
          margin-left: 5px;
        }

        p {
          margin-left: 10px;
        }
      }
    }
  }

  .user-list {
    width: 100%;
    height: 20em;
    background-color: ${({ theme }) => theme.custom};
    overflow: overlay;

    .user {
      width: 100%;
      height: 4em;
      border-bottom: 1px solid ${({ theme }) => theme.border3};
      display: flex;

      .thumb {
        width: 4em;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: 1px solid ${({ theme }) => theme.border3};

        .circle {
          width: 60%;
          height: 60%;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 100%;
          border: 1px solid ${({ theme }) => theme.border3};

          p {
            color: ${({ theme }) => theme.text};
          }
        }
      }

      .info {
        width: calc(100% - 4em);
        height: 100%;
        display: flex;

        .info-content {
          width: calc(100% - 3em);
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;

          p {
            height: 25%;
            margin-left: 15px;
            color: ${({ theme }) => theme.text};
            font-size: 0.9em;
            display: flex;
            align-items: center;
            opacity: 0.6;

            &:last-child {
              font-size: 0.7em;
              opacity: 0.4;
            }
          }
        }

        .delete {
          width: 3em;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          .icon {
            cursor: pointer;

            svg {
              font-size: 0.9em;
              opacity: 0.6;
              color: ${({ theme }) => theme.text};
            }
          }
        }
      }
    }
  }

  .drop {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 1em;
    input {
      width: 100%;
      height: 2em;
      border-radius: 0px;
      background-color: transparent;
      color: white;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    .drop-area {
      margin: 1em 0;
      width: 95%;
      height: 7em;
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        width: 99%;
        height: 5em;
        border-radius: 5px;
        font-size: 0.8em;
        opacity: 0.5;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px 40px;
        border: 1px dashed rgba(255, 255, 255, 0.5);
      }
    }

    .btn {
      width: max-content;
      height: max-content;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 50px;
      margin: auto;
      background-color: rgba(0, 150, 50);
      font-size: 0.8em;
      border-radius: 5px;
    }
  }
`;

// New styled component for UserCard
export const UserCardContainer = styled.div`
  width: 100%;
  max-height: max-content;
  align-items: center; /* Adjust as needed */
  display: flex;
  flex-direction: column;

  .user {
    width: 96%;
    height: 4em;
    display: flex;
    background-color: ${({ theme }) => theme.custom};
    border-radius: 5px;

    .thumb {
      width: 4em;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: 1px solid ${({ theme }) => theme.border3};

      .circle {
        width: 60%;
        height: 60%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border: 1px solid ${({ theme }) => theme.border3};

        p {
          color: ${({ theme }) => theme.text};
        }
      }
    }

    .info {
      width: calc(100% - 4em);
      height: 100%;
      display: flex;

      .info-content {
        width: calc(100% - 3em);
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        p {
          margin-left: 15px;
          color: ${({ theme }) => theme.text};
          font-size: 0.9em;
          opacity: 0.6;

          &:last-child {
            font-size: 0.7em;
            opacity: 0.4;
          }
        }
      }

      .delete {
        width: 3em;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .icon {
          cursor: pointer;

          svg {
            font-size: 0.9em;
            opacity: 0.6;
            color: ${({ theme }) => theme.text};
          }
        }
      }
    }
  }
`;

// Existing exports...

// Botão de Execução
export const ExecuteButton = styled.button`
  background: none;
  border: none;
  color: #4eb959;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 10px;
  padding: 10px 20px;
  &:hover {
    color: #12801d;
  }
`;

// Overlay do Modal
export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

// Conteúdo do Modal
export const ModalContent = styled.div`
  width: 400px;
  background-color: #05070a;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: fadeIn 0.5s ease;

  h2 {
    margin-bottom: 20px;
  }

  p {
    font-size: 1rem;
    margin-bottom: 10px;
  }

  button {
    padding: 10px 20px;
    background-color: ${({ theme }) => theme.primaryColor || "#3498db"};
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s;

    &:hover {
      background-color: ${({ theme }) => theme.secondaryColor || "#2ecc71"};
    }

    &:active {
      transform: scale(0.98);
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

// Botão de Fechar
export const CloseButton = styled.button`
  background-color: ${({ theme }) => theme.primaryColor || "#3498db"};
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 20px;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.secondaryColor || "#2ecc71"};
  }

  &:active {
    transform: scale(0.98);
  }
`;
