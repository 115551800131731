import React, { useEffect, useRef, useCallback, useState } from "react";
import {
  Bar,
  Card,
  Cards,
  Container,
  Menu,
  Relatorio,
  ReportDiv,
} from "./styles";
import {
  IoSearchOutline,
  IoCodeSlashOutline,
  IoCheckmark,
} from "react-icons/io5";
import ActiveFiltersModal from "./components/filtroHover/index";
import { IoMdDownload } from "react-icons/io";
import { CiCalendar } from "react-icons/ci";
import { useReports } from "@hooks/report";
import { useProjects } from "@hooks/projects";
import Mensal from "./components/mensal";
import ContentLoader from "react-content-loader";
import axios from "axios";
import { toast } from "react-toastify";
import ModalXML from "./components/modalXML";
import { useTema } from "../../../../../../hooks/theme";
import { LuFilter } from "react-icons/lu";
import { IoMdRefresh } from "react-icons/io";
import Filtro from "./components/filtro";

import { useAuth } from "@hooks/auth";

import XML from "../../../../../modals/xml";


export default function NFes() {
  const {
    documents,
    fetchDocuments,
    setPage,
    hasMore,
    page,
    infos,
    getInfos,
    documentType,
    documentStatus,
    handleDocumentTypeChange,
    handleDocumentStatusChange,
    loading,
    handleSearch,
  } = useReports();

  const { projeto } = useAuth();

  const { projectInfo, setXmlData } = useProjects();

  // Estados locais
  const [activeFilters, setActiveFilters] = useState(null);
  const [localSearchTerm, setLocalSearchTerm] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [filteredDocuments, setFilteredDocuments] = useState(null);
  const [isFiltering, setIsFiltering] = useState(false);
  const [abortController, setAbortController] = useState(null);

  // Novos estados para paginação de documentos filtrados
  const [filteredPage, setFilteredPage] = useState(1);
  const [filteredHasMore, setFilteredHasMore] = useState(true);
  const [isLoadingFiltered, setIsLoadingFiltered] = useState(false);

  // Refs
  const searchTimeoutRef = useRef(null);
  const observer = useRef();
  const scrollContainerRef = useRef(null);
  const scrollPositionRef = useRef(0);

  // Determina qual lista de documentos mostrar
  const displayDocuments = filteredDocuments || documents || [];

  // Handlers
  const toggleFilter = () => setIsFilterOpen((prev) => !prev);

  const clearFilters = useCallback(() => {
    // Cancela qualquer requisição em andamento
    if (abortController) {
      abortController.abort();
      setAbortController(null);
    }

    // Limpa todos os estados relacionados a filtragem
    setFilteredDocuments(null);
    setActiveFilters(null);
    setFilteredPage(1);
    setFilteredHasMore(true);
    setIsFiltering(false);
    setIsLoadingFiltered(false);

    // Reset dos estados de paginação
    setPage(1);
    // Limpa a posição do scroll
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = 0;
    }
    scrollPositionRef.current = 0;

    // Força uma nova busca dos documentos padrões
    fetchDocuments(1);

    // Atualiza as infos
    getInfos();

    // Cancela qualquer observador de intersecção ativo
    if (observer.current) {
      observer.current.disconnect();
      observer.current = null;
    }
  }, [fetchDocuments, getInfos, abortController]);

  const handleFilterResults = useCallback(
    (results) => {
      if ("abortController" in results) {
        setAbortController(results.abortController);
      }

      // Se recebemos apenas isLoading, atualizamos o estado de loading
      if ("isLoading" in results) {
        setIsFiltering(results.isLoading);
        setActiveFilters((prev) => ({
          ...prev,
          isLoading: results.isLoading,
        }));

        // Se estiver começando a carregar, limpa os documentos filtrados
        if (results.isLoading) {
          setFilteredDocuments(null);
        }
        return;
      }

      // Processamento normal dos resultados
      if (results?.hits) {
        const formattedResults = results.hits.map((doc) => ({
          ...doc,
          _source: {
            ...doc._source,
            isFiltered: true,
          },
        }));

        setFilteredDocuments(formattedResults);
        setFilteredHasMore(results.hasMore);
        setActiveFilters({
          startDate: results.activeFilters?.startDate,
          endDate: results.activeFilters?.endDate,
          emiDoc: results.activeFilters?.emiDoc,
          destDoc: results.activeFilters?.destDoc,
          cfop: results.activeFilters?.cfop,
          type: results.activeFilters?.type,
          status: results.activeFilters?.status,
          totalDocuments: results.totalDocs,
          fileId: results.fileId,
          projeto: projeto._id,
          isLoading: false,
        });

        setIsFiltering(false);
        setFilteredPage(1);
      }
    },
    [projeto._id]
  );
  const formatDateForAPI = (dateObj) => {
    if (!dateObj?.year) return null;

    const month = (dateObj.month + 1).toString().padStart(2, "0");
    const day = dateObj.day.toString().padStart(2, "0");
    return `${dateObj.year}-${month}-${day}T00:00:00.000Z`;
  };

  const loadMoreDocuments = useCallback(async () => {
    // Previne carregamentos simultâneos
    if (loading || isLoadingFiltered || isFiltering) return;

    // Se estamos com documentos filtrados
    if (filteredDocuments) {
      if (!filteredHasMore || isLoadingFiltered) return;

      setIsLoadingFiltered(true);
      try {
        const nextPage = filteredPage + 1;
        const params = new URLSearchParams();

        // Adiciona os parâmetros do filtro ativo
        if (activeFilters) {
          const formattedStartDate = formatDateForAPI(activeFilters.startDate);
          const formattedEndDate = formatDateForAPI(activeFilters.endDate);

          if (formattedStartDate)
            params.append("startDate", formattedStartDate);
          if (formattedEndDate) params.append("endDate", formattedEndDate);
          if (activeFilters.emiDoc)
            params.append("emiDoc", activeFilters.emiDoc);
          if (activeFilters.destDoc)
            params.append("destDoc", activeFilters.destDoc);
          if (activeFilters.cfop) params.append("cfop", activeFilters.cfop);
          if (activeFilters.type) params.append("type", activeFilters.type);
          if (activeFilters.status)
            params.append("status", activeFilters.status);
        }

        params.append("page", nextPage.toString());
        params.append("pageSize", "100");

        console.log(
          "Carregando mais documentos filtrados:",
          Object.fromEntries(params)
        );

        const response = await axios.get(
          `${process.env.REACT_APP_API}/report/xml/search-filter`,
          {
            params,
            headers: { projeto: projeto._id },
          }
        );

        if (response.data.success) {
          const newDocs = response.data.data.hits.map((doc) => ({
            ...doc,
            _source: {
              ...doc._source,
              isFiltered: true,
            },
          }));

          // Atualiza os documentos mantendo a referência correta
          setFilteredDocuments((prev) => [...prev, ...newDocs]);

          // Mantém o mesmo totalDocuments do filtro inicial
          setActiveFilters((prev) => ({
            ...prev,
            hasMore: response.data.data.hasMore,
            fileId: response.data.data.fileId,
            // NÃO atualiza o totalDocuments aqui
          }));

          setFilteredHasMore(response.data.data.hasMore);
          setFilteredPage(nextPage);
        }
      } catch (error) {
        console.error("Erro ao carregar mais documentos filtrados:", error);
        toast.error("Erro ao carregar mais documentos");
      } finally {
        setIsLoadingFiltered(false);
      }
    } else {
      // Carrega mais documentos normais
      if (!hasMore) return;
      setPage((prev) => prev + 1);
    }
  }, [
    loading,
    isLoadingFiltered,
    isFiltering,
    filteredDocuments,
    filteredHasMore,
    filteredPage,
    activeFilters,
    projeto._id,
    hasMore,
  ]);

  const lastDocumentElementRef = useCallback(
    (node) => {
      if (loading || isLoadingFiltered || isFiltering) return;
      if (filteredDocuments ? !filteredHasMore : !hasMore) return;

      // Limpa o observer anterior
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && scrollContainerRef.current) {
          const container = scrollContainerRef.current;
          const isNearBottom =
            container.scrollHeight -
              container.scrollTop -
              container.clientHeight <
            150;

          if (isNearBottom) {
            scrollPositionRef.current = container.scrollTop;
            loadMoreDocuments();
          }
        }
      });

      if (node) observer.current.observe(node);
    },
    [
      loading,
      isLoadingFiltered,
      isFiltering,
      filteredDocuments,
      filteredHasMore,
      hasMore,
      loadMoreDocuments,
    ]
  );

  const handleScroll = useCallback(() => {
    if (scrollContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        scrollContainerRef.current;
      const threshold = 150; // Aumentado para dar mais margem

      if (scrollHeight - scrollTop - clientHeight < threshold) {
        if (filteredDocuments) {
          // Se estamos com documentos filtrados
          if (!isLoadingFiltered && filteredHasMore && !isFiltering) {
            loadMoreDocuments();
          }
        } else {
          // Se estamos com documentos normais
          if (!loading && hasMore) {
            setPage((prev) => prev + 1);
          }
        }
      }
    }
  }, [
    loading,
    hasMore,
    isLoadingFiltered,
    filteredHasMore,
    isFiltering,
    filteredDocuments,
    loadMoreDocuments,
  ]);

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setLocalSearchTerm(value);

    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }

    searchTimeoutRef.current = setTimeout(() => {
      handleSearch(value);
    }, 500);
  };

  const handleSearchSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }
    handleSearch(localSearchTerm);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSearchSubmit(e);
    }
  };

  const handleDownloadXML = (nfe) => {
    axios
      .get(`${process.env.REACT_APP_API}/report/download/${nfe}`, {
        responseType: "blob",
      })
      .then((response) => {
        const contentType = response.headers["content-type"];
        if (
          !contentType.includes("application/xml") &&
          !contentType.includes("text/xml")
        ) {
          toast.error("Erro ao baixar o XML: Tipo de arquivo inválido.");
          return;
        }

        const blob = new Blob([response.data], { type: contentType });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${nfe}.xml`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);

        toast.success("Download realizado com sucesso!");
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          toast.error("Arquivo XML não encontrado.");
        } else {
          toast.error("Erro ao baixar o XML.");
        }
        console.error("Erro ao baixar o XML:", error);
      });
  };

  const openModal = (data) => {
    setXmlData(data);
    document.getElementById("xml").style.display = "flex";
  };



  // Effects

  useEffect(() => {
    return () => {
      // Cleanup ao desmontar
      if (observer.current) {
        observer.current.disconnect();
      }
      if (abortController) {
        abortController.abort();
      }
    };
  }, []);

  useEffect(() => {
    if (page > 1 && !filteredDocuments) {
      fetchDocuments(page);
    }
  }, [page, fetchDocuments, filteredDocuments]);

  useEffect(() => {
    getInfos();
    fetchDocuments(1);
  }, [getInfos, fetchDocuments]);

  useEffect(() => {
    if (!loading && !isLoadingFiltered && (page > 1 || filteredPage > 1)) {
      scrollContainerRef.current.scrollTop = scrollPositionRef.current;
    }
  }, [
    documents,
    filteredDocuments,
    loading,
    isLoadingFiltered,
    page,
    filteredPage,
  ]);

  useEffect(() => {
    const container = scrollContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      return () => container.removeEventListener("scroll", handleScroll);
    }
  }, [handleScroll]);

  useEffect(() => {
    if (isFiltering || isLoadingFiltered) {
      setPage(1); // Reset da página normal quando estiver filtrando
    }
  }, [isFiltering, isLoadingFiltered]);

  // Loader component
  function DocumentLoader() {
    const { theme } = useTema();
    const backgroundColor = theme === "light" ? "#f0f0f0" : "#616161";
    const foregroundColor = theme === "light" ? "#e0e0e0" : "#ecebeb";

    return (
      <ContentLoader
        speed={2}
        width={1500}
        height={50}
        viewBox="0 0 1500 50"
        backgroundColor={backgroundColor}
        foregroundColor={foregroundColor}
      >
        <circle cx="25" cy="25" r="5" />
        <rect x="70" y="20" rx="3" ry="3" width="130" height="6" />
        <rect x="250" y="20" rx="3" ry="3" width="400" height="6" />
        <rect x="730" y="20" rx="3" ry="3" width="100" height="6" />
        <rect x="860" y="20" rx="3" ry="3" width="200" height="6" />
      </ContentLoader>
    );
  }


  const closeModal = () => {
    console.log("Fechando o modal");
    setIsModalOpen(false);
  };

  return (
    <Container>
      {isFilterOpen && (
        <Filtro
          onFilterApply={handleFilterResults}
          activeFilters={activeFilters}
          onClose={() => setIsFilterOpen(false)}
        />
      )}
      {(activeFilters || isFiltering) && (
        <ActiveFiltersModal
          filters={activeFilters || {}}
          onClearFilters={clearFilters}
          totalDocuments={activeFilters?.totalDocuments || 0}
          isLoading={isFiltering}
          abortController={abortController}
        />
      )}
      <ReportDiv>
        <Cards>
          {infos !== undefined &&
            Object.keys(infos).map((key, index) => (
              <Card key={index}>
                <div className="header">
                  <p>{key}</p>
                </div>
                <div className="content">{infos[key].toLocaleString()}</div>
              </Card>
            ))}
        </Cards>
        <Relatorio>
          <div className="block">
            <div className="head">
              <div className="side">
                <div className="btns">
                  <div
                    className={`btn ${documentStatus === "all" && "ativ"}`}
                    onClick={() => handleDocumentStatusChange("all")}
                  >
                    <p>Todas</p>
                  </div>
                  <div
                    className={`btn auth ${
                      documentStatus === "auth" && "ativ"
                    }`}
                    onClick={() => handleDocumentStatusChange("auth")}
                  >
                    <p>Autorizadas</p>
                  </div>
                  <div
                    className={`btn cancel ${
                      documentStatus === "cancel" && "ativ"
                    }`}
                    onClick={() => handleDocumentStatusChange("cancel")}
                  >
                    <p>Canceladas</p>
                  </div>
                </div>
              </div>
              <div className="side">
                <div className="options">
                  <div className="btns">
                    <div
                      className={`btn all ${
                        documentType === "all" ? "active" : ""
                      }`}
                      onClick={() => handleDocumentTypeChange("all")}
                    >
                      <p>Todas</p>
                    </div>
                    <div
                      className={`btn nfe ${
                        documentType === "nfe" ? "active" : ""
                      }`}
                      onClick={() => handleDocumentTypeChange("nfe")}
                    >
                      <p>NFe's</p>
                    </div>
                    <div
                      className={`btn cte ${
                        documentType === "cte" ? "active" : ""
                      }`}
                      onClick={() => handleDocumentTypeChange("cte")}
                    >
                      <p>CTe's</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="side">
                <div className="search">
                  <div className="filter" onClick={toggleFilter}>
                    <LuFilter />
                  </div>
                  <div className="btn" onClick={handleSearchSubmit}>
                    <IoSearchOutline />
                  </div>
                  <input
                    value={localSearchTerm}
                    onChange={handleSearchChange}
                    onKeyDown={handleKeyDown}
                    placeholder="Buscar por NFe"
                  />
                </div>
              </div>
            </div>
            <div className="table">
              <div className="head">
                <div className="colum check">
                  <div className="thumb white"></div>
                </div>
                <div className="colum data">
                  <CiCalendar />
                  <p>Data</p>
                </div>
                <div className="colum chave">
                  <IoCodeSlashOutline />
                  <p>Chave</p>
                </div>
                <div className="colum status">
                  <IoCodeSlashOutline />
                  <p>Status</p>
                </div>
                <div className="colum cnpj">
                  <IoCodeSlashOutline />
                  <p>CNPJ</p>
                </div>
              </div>
              <div className="display" ref={scrollContainerRef}>
                {loading || isLoadingFiltered || isFiltering ? (
                  Array.from({ length: 5 }).map((_, index) => (
                    <DocumentLoader key={index} />
                  ))
                ) : displayDocuments.length > 0 ? (
                  displayDocuments.map((doc, index) => (
                    <div
                      className="items"
                      key={doc._id || index}
                      onClick={() => openModal(doc._source)}
                    >
                      <div className="item check">
                        <div
                          className="thumb"
                          style={{
                            backgroundColor: doc._source.check ? "#07bc0c" : "",
                          }}
                        >
                          {doc._source.check ? (
                            <IoCheckmark style={{ color: "white" }} />
                          ) : null}
                        </div>
                      </div>
                      <div className="item data">
                        <p>
                          {new Date(doc._source.dhEvento)
                            .toLocaleString("pt-BR")
                            .replace(",", " - ")}
                        </p>
                      </div>
                      <div className="item chave">
                        <p>{doc._source.chave}</p>
                      </div>
                      <div className="item status">
                        <p>{doc._source.status}</p>
                      </div>
                      <div className="item cnpj">
                        <p>{doc._source.cnpj}</p>
                      </div>
                      {doc._source.check && (
                        <div
                          className="item check download"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDownloadXML(doc._source.chave);
                          }}
                        >
                          <IoMdDownload color="white" />
                        </div>
                      )}
                    </div>
                  ))
                ) : (
                  <div className="no-results">
                    <p>Nenhum documento encontrado</p>
                  </div>
                )}
                {(loading || isLoadingFiltered) && (
                  <div className="loading-more">
                    <DocumentLoader />
                  </div>
                )}
              </div>
            </div>
          </div>
        </Relatorio>
      </ReportDiv>

      <Mensal />
    </Container>
  );
}
