import styled from "styled-components";

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  position: absolute;
  z-index: 10000;
`;

export const Card = styled.div`
  width: max-content;
  height: 70%;
  background-color: ${({ theme }) => theme.card};
  transform: translateY(-2em);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  .header {
    width: 100%;
    height: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid ${({ theme }) => theme.border3};
    background-color: ${({ theme }) => theme.custom};

    svg {
      fill: transparent;
      margin-right: 10px;
      font-size: 0.8em;
      opacity: 0.7;
    }

    .btn {
      width: 2em;
      height: 2em;
      display: flex;
      position: absolute;
      border-right: 1px solid ${({ theme }) => theme.border3};
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      left: 0;

      svg {
        fill: ${({ theme }) => theme.text};
        font-size: 0.8em;
        margin: 0;
      }
    }
  }

  .display {
    width: 100%;
    height: calc(100% - 2em);
    display: flex;
  }
`;
export const TabHeaders = styled.div`
  width: 100%;
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.border3};
  background-color: ${({ theme }) => theme.custom};

  .tab-header {
    flex: 1;
    height: 2.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-right: 1px solid ${({ theme }) => theme.border3};
    color: ${({ theme }) => theme.text};
    opacity: 0.7;

    p {
      font-size: 0.8em;
      margin-left: 10px;
    }

    &:last-child {
      border-right: none;
    }

    svg {
      margin-right: 5px;
    }

    &.active {
      background-color: ${({ theme }) => theme.card};
      opacity: 1;
      font-weight: bold;
    }
  }
`;

export const TabContent = styled.div`
  width: 30em;
  height: calc(
    100% - 4.5em
  ); /* Ajuste da altura com base no header e nas abas */
  overflow-y: auto;

  .dates {
    width: 30em;
    height: max-content;
    display: flex;

    .date {
      width: 50%;
      height: 100%;
      display: flex;
      flex-direction: column;

      svg {
        margin-right: 10px;
      }

      .current {
        width: 100%;
        height: 3em;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${({ theme }) => theme.custom};
        border-bottom: 1px solid ${({ theme }) => theme.border3};
        transition: height 0.3s ease;

        p {
          font-weight: 600;
          font-size: 0.9em;
        }
      }

      .current.complete {
        height: 4em;
      }

      .years,
      .months,
      .days {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
      }

      .year,
      .month,
      .day {
        width: 100%;
        height: 2em;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid ${({ theme }) => theme.border3};
        background-color: ${({ theme }) => theme.custom};
        opacity: 0.6;
        cursor: pointer;
        margin: 0 1px;
        &:hover {
          opacity: 1;
        }
        p {
          font-size: 0.8em;
        }

        &:hover {
          opacity: 1; /* Aumenta a opacidade no hover */
          border-color: ${({ theme }) =>
            theme.border7}; /* Borda verde suave no hover */
          box-shadow: 0 0 0 1px ${({ theme }) => theme.border7}; /* Sombra suave ao passar o mouse */
        }

        &.ativ {
          opacity: 1;
          border: 1px solid ${({ theme }) => theme.border7};
          box-shadow: 0 0 0 1px ${({ theme }) => theme.border7}; /* Sombra*/
        }
      }

      .year:nth-child(5n),
      .month:nth-child(5n),
      .day:nth-child(5n) {
        border-right: none;
      }

      /* Remover borda inferior da última linha */
      .years .year:last-child,
      .months .month:last-child,
      .days .day:last-child {
        border-bottom: none;
      }

      &.complete .years,
      &.complete .months,
      &.complete .days {
        height: 0;
        opacity: 0;
        overflow: hidden;
      }
    }
  }

  .parts {
    width: 30em;
    height: 100%;
    display: flex;
    flex-direction: column;

    .header {
      height: 1.5em;
      justify-content: start;

      svg {
        fill: ${({ theme }) => theme.text};
        color: ${({ theme }) => theme.text};
        margin: 0 10px;
        margin-right: 7px;
      }
      p {
        font-size: 0.75em;
        transform: translateY(1px);
        opacity: 0.7;
      }
    }

    .search {
      width: 100%;
      height: 2em;
      display: flex;
      align-items: center;

      position: relative;
      svg {
        position: absolute;
        left: 0;
        margin-left: 10px;
        font-size: 0.8em;
      }

      input {
        font-size: 0.8em;
        height: 100%;
        width: 100%;
        border-radius: 0;
        color: ${({ theme }) => theme.text};
        background-color: ${({ theme }) => theme.custom3};
        opacity: 0.9;
      }
    }

    .items {
      width: 100%;
      height: 10em;
      display: flex;
      flex-direction: column;

      .item {
        width: 100%;
        height: 3em;
        display: flex;
        align-items: center;
        background-color: ${({ theme }) => theme.custom};
        border-top: 1px solid ${({ theme }) => theme.border3};
        .thumb {
          width: 3em;
          height: 3em;
          display: flex;
          align-items: center;
          justify-content: center;
          border-right: 1px solid ${({ theme }) => theme.border3};
        }

        .content {
          width: calc(100% - 3em);
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;

          p {
            margin-left: 10px;

            line-height: 16px;
            font-size: 0.75em;
            &:first-child {
              font-size: 0.85em;
              font-weight: 600;
            }
          }
        }
      }
    }

    .types {
      width: 100%;
      display: flex;
      align-items: center;
      padding-bottom: 2px;

      .type {
        width: max-content;
        padding: 3px 18px; /* Usando o mesmo padding dos botões do Footer */
        background-color: ${({ theme }) => theme.custom};
        margin: 5px 5px;
        border-radius: 5px; /* Cantos arredondados */
        opacity: 0.6; /* Cor de fundo com opacidade */
        cursor: pointer; /* Cursor de ponteiro no hover */
        border: 1px solid ${({ theme }) => theme.border3}; /* Borda padrão */
        font-size: 16px; /* Tamanho da fonte */
        display: flex;
        align-items: center;
        justify-content: center;
        transition: background-color 0.3s, border-color 0.3s, box-shadow 0.3s; /* Transições suaves */

        p {
          font-size: 0.9em;
          margin: 0; /* Remover margens para melhor alinhamento */
        }

        &:hover {
          opacity: 1; /* Aumenta a opacidade no hover */
          border-color: ${({ theme }) =>
            theme.border7}; /* Borda verde suave no hover */
          box-shadow: 0 0 0 3px ${({ theme }) => theme.border7}; /* Sombra suave ao passar o mouse */
        }


    &:focus {
      outline: none; /* Remover contorno padrão */
      border: 1px solid ${({ theme }) => theme.border7};
      box-shadow: 0 0 0 3px ${({ theme }) => theme.border7}; /* Destaque de foco com sombra verde clara */
    }
    
    &.active {
    border-color: ${({ theme }) => theme.border7}; /* Borda verde ao clicar */
    opacity: 1; /* Manter a opacidade total quando ativo */

    }
  }
`;

export const Footer = styled.div`
  background: none;
  border: none;
  display: flex;
  width: 100%;
  height: 3em;
  align-items: center;
  justify-content: center;

  .send {
    background-color: ${({ theme }) => theme.custom};
    opacity: 0.6;
    color: ${({ theme }) => theme.text};
    border: 1px solid ${({ theme }) => theme.border3}; /* Borda padrão */
    padding: 9px 24px; /* Aumentando o espaço interno para simetria */
    border-radius: 5px; /* Cantos arredondados */
    cursor: pointer; /* Cursor de ponteiro no hover */
    font-size: 16px; /* Tamanho da fonte */
    transition: background-color 0.3s, border-color 0.3s, box-shadow 0.3s; /* Transições suaves */

    /* Alinhamento do texto */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .send:hover {
    opacity: 1;
    border-color: ${({ theme }) =>
      theme.border7}; /* Borda verde suave no hover */
    box-shadow: 0 0 0 3px rgba(118, 199, 118, 0.5); /* Sombra suave ao passar o mouse */
  }

  .send:focus {
    outline: none; /* Remover contorno padrão */
    border: 1px solid ${({ theme }) => theme.border7};
    box-shadow: 0 0 0 3px ${({ theme }) => theme.border7}; /* Destaque de foco com sombra verde clara */
  }
`;
