import styled from "styled-components";

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const Container = styled.div`
  width: max-content;
  min-width: 18em;
  height: 18em;
  display: flex;
  flex-direction: column;
  font-size: 1.2em;
  border-radius: 10px;
  z-index: 2;
  background-color: ${({ theme }) => theme.custom};
  border: 1px solid ${({ theme }) => theme.border5};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

export const Header = styled.div`
  width: 100%;
  height: 1.5em;
  border-bottom: 1px solid ${({ theme }) => theme.border5};
  display: flex;
  justify-content: center; /* Centraliza o texto horizontalmente */
  align-items: center;
  position: relative; /* Para que o botão de fechar possa ser posicionado com position: absolute */

  p {
    color: ${({ theme }) => theme.text};
    font-size: 0.6em;
    opacity: 0.5;
    z-index: 1;
  }

  .close {
    width: 1.5em;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    top: 0;
    opacity: 0.8;
    transition: all 0.2s;
    cursor: pointer;
    border-left: 1px solid ${({ theme }) => theme.border5};

    &:hover {
      opacity: 1;
    }

    svg {
      color: rgb(150, 0, 50);
      opacity: 0.4;
    }
  }
`;

export const Box = styled.div`
  min-width: 100%;
  height: 14em;
  display: flex;
  flex-direction: column;
  overflow: auto;

  .not-found {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center; /* Centraliza horizontalmente */
    align-items: center; /* Centraliza verticalmente */

    p {
      color: ${({ theme }) => theme.text};
      text-align: center;
      margin-top: 10em;
      opacity: 0.4;
    }
  }
`;

export const Projeto = styled.div`
  width: 100%;
  height: 3em;
  border-bottom: 1px solid ${({ theme }) => theme.border5};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.hover};
  }

  .header {
    width: 100%;
    height: 3em;
    display: flex;

    .thumb {
      min-width: 2em;
      height: 100%;
      border-right: 1px solid ${({ theme }) => theme.border5};
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        font-size: 0.8em;
        opacity: 0.5;
        color: #238636;
      }
    }

    .text {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 15px;
      p {
        color: ${({ theme }) => theme.text};
        width: 80%;
        font-size: 0.5em;
        margin-top: 5px;
        opacity: 0.6;

        &:first-child {
          opacity: 0.8;
          font-size: 0.5em;
        }
      }
    }

    .edit-icon {
      min-width: 2em;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .icon {
        svg {
          color: ${({ theme }) => theme.text};
          opacity: 0.6;
        }
      }
    }
  }
`;

export const CreateButton = styled.div`
  width: 100%;
  height: 4em;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .button {
    width: auto;
    height: auto;
    background-color: #418721;
    padding: 0.2em 1em;
    border-radius: 2px;
    opacity: 0.9;
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
      opacity: 1;
    }

    p {
      color: ${({ theme }) => theme.text};
      font-size: 0.7em;
    }
  }
`;
