import styled from "styled-components";

export const StyledProjectDropdown = styled.div`
  position: absolute;
  width: 25em;
  background-color: rgba(22, 27, 34, 0.95);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  overflow: hidden;
  z-index: 1001;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transform: translateY(.8em);

  p {
    font-size: 0.75em;
    opacity: 0.5;
    margin-top: 5px;
  }

  ul {
    width: 100%;
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;

    li {
      display: flex;
      padding: 10px 15px;
      cursor: pointer;
      transition: background-color 0.2s;
      color: white;
      flex-direction: row;

      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }

      .thumb {
        display: flex;
        align-items: center;
        width: 2em;
        height: 2em;
      }

      .text {
        h2 {
          font-size: 0.8em;
          opacity: 0.7;
          font-weight: 500;
        }

        p {
          font-size: 0.75em;
          opacity: 0.5;
          margin-top: 5px;
        }
      }

      &.actions {
        display: flex;
        justify-content: space-between;
        padding-top: 15px;
        cursor: default; /* Remove o cursor de hover do <li> */
        transition: none; /* Remove transições de hover do <li> */

        &:hover {
          background-color: transparent; /* Remove o hover da última div */
        }
      }

      button {
        background-color: transparent;
        border: 1px solid #4caf50;
        padding: 8px 12px;
        border-radius: 4px;
        cursor: pointer;
        color: #4caf50;
        display: flex;
        align-items: center;
        transition: background-color 0.2s; /* Adiciona a transição ao hover dos botões */

        svg {
          margin-right: 5px;
        }

        &:hover {
          background-color: rgba(
            76,
            175,
            80,
            0.1
          ); /* Aplica hover aos botões */
        }
      }

      .new-project {
        color: #4caf50;
      }

      .edit-project {
        color: #3498db;
        border-color: #3498db;

        &:hover {
          background-color: rgba(255, 152, 0, 0.1);
        }
      }
    }
  }
`;
