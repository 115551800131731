import { motion, AnimatePresence } from "framer-motion";
import { useEffect, useState } from "react";
import { Bar, Blur, Button, Container } from "./styles";
import { useDash } from "../../hooks/dash";

export default function Menu({ isOpen, toggleMenu }) {
  const [shouldRender, setShouldRender] = useState(isOpen);
  const { status, setStatus } = useDash();

  useEffect(() => {
    if (isOpen) setShouldRender(true);
  }, [isOpen]);

  const handleAnimationComplete = () => {
    if (!isOpen) setShouldRender(false);
  };

  function checkStatus(str) {
    if (status === str) {
      return "ativ";
    }

    return "";
  }

  return (
    <AnimatePresence>
      {shouldRender && (
        <Container>
          <motion.div
            initial={{ x: "-15em" }}
            animate={{ x: isOpen ? 0 : "-15em" }}
            exit={{ x: "-15em" }}
            transition={{ duration: 0.1 }}
            onAnimationComplete={handleAnimationComplete}
            style={{
              display: "flex",
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          >
            <Bar>
              <Button
                className={checkStatus("xmls")}
                onClick={() => setStatus("xmls")}
              >
                <svg
                  aria-hidden="true"
                  height="16"
                  viewBox="0 0 16 16"
                  version="1.1"
                  width="16"
                  data-view-component="true"
                  className="octicon octicon-code UnderlineNav-octicon d-none d-sm-inline"
                >
                  <path
                    fill="white"
                    d="m11.28 3.22 4.25 4.25a.75.75 0 0 1 0 1.06l-4.25 4.25a.749.749 0 0 1-1.275-.326.749.749 0 0 1 .215-.734L13.94 8l-3.72-3.72a.749.749 0 0 1 .326-1.275.749.749 0 0 1 .734.215Zm-6.56 0a.751.751 0 0 1 1.042.018.751.751 0 0 1 .018 1.042L2.06 8l3.72 3.72a.749.749 0 0 1-.326 1.275.749.749 0 0 1-.734-.215L.47 8.53a.75.75 0 0 1 0-1.06Z"
                  ></path>
                </svg>
                <p>XML's</p>
              </Button>

              <Button
                className={checkStatus("speds")}
                onClick={() => setStatus("speds")}
              >
                <svg
                  aria-hidden="true"
                  height="16"
                  viewBox="0 0 16 16"
                  version="1.1"
                  width="14"
                  data-view-component="true"
                  className="octicon octicon-table"
                >
                  <path
                    fill="white"
                    d="M0 1.75C0 .784.784 0 1.75 0h12.5C15.216 0 16 .784 16 1.75v12.5A1.75 1.75 0 0 1 14.25 16H1.75A1.75 1.75 0 0 1 0 14.25ZM6.5 6.5v8h7.75a.25.25 0 0 0 .25-.25V6.5Zm8-1.5V1.75a.25.25 0 0 0-.25-.25H6.5V5Zm-13 1.5v7.75c0 .138.112.25.25.25H5v-8ZM5 5V1.5H1.75a.25.25 0 0 0-.25.25V5Z"
                  ></path>
                </svg>
                <p>SPED's</p>
              </Button>
            </Bar>
          </motion.div>
          <Blur onClick={toggleMenu} />
        </Container>
      )}
    </AnimatePresence>
  );
}
