import styled from "styled-components";

export const ModalContainer = styled.div`
  background-color: #1a1a1d;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  color: white;
`;

export const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  background-color: #333;
  border: 1px solid #555;
  border-radius: 5px;
  color: white;
  font-size: 16px;

  ::placeholder {
    color: #888;
  }
`;

export const DropZone = styled.div`
  border: 2px dashed #444;
  padding: 20px;
  margin-bottom: 15px;
  text-align: center;
  cursor: pointer;

  input {
    display: none;
  }

  p {
    margin-top: 10px;
    color: #bbb;
  }
`;

export const Button = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
`;

export const ModalContent = styled.div`
  background: #05070a;
  padding: 20px;
  border-radius: 21px;
  border: 0.1px solid #505153;
  width: 290px;
  max-height: 80vh;
  overflow-y: auto;
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  color: #c21b0c;
  font-size: 1rem;
  cursor: pointer;

  &:hover {
    color: #8f150a;
  }
`;

export const DropzoneContainer = styled.div`
  border: 2px dashed #505153;
  opacity: 0.5;
  padding: 50px;
  text-align: left;
  border-radius: 10px;
  margin-bottom: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64.5%;
  height: ${(props) => (props.fullSize ? "300px" : "60px")};
`;

export const UploadIcon = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 30px;
  font-size: 20px;
  color: #1e1f22;
  border: 2px solid #1e1f22;
  border-radius: 50%;
  margin-right: 20px;
`;

export const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Message = styled.p`
  color: #bbb;
  font-size: 15.5px;
  font-weight: bold;
`;

export const SubMessage = styled.p`
  font-size: 11px;
  color: #67686a;
`;

export const FileList = styled.ul`
  list-style: none;
  background: #0d0d11; /* Fundo mais escuro para o FileList */
  padding: -1px;
  margin-left: -20px; /* Remove padding esquerdo */
  margin-right: -28px;
  border: 0.1px solid #252528;
  height: 130px;
  overflow-y: auto; /* Permite a rolagem vertical */
`;

export const FileItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #07090c;
  padding: 10px;
  margin-bottom: 0px;
  border: 0.1px solid #252528;
  color: #fff;
  font-size: 0.8rem;
`;

export const DeleteButton = styled.button`
  background: none;
  border: none;
  color: #c21b0c;
  font-size: 1.2rem;
  cursor: pointer;
  padding: 10px 20px;
  margin-left: auto;

  &:hover {
    color: #8f150a;
  }
`;

export const SendButton = styled.button`
  background: none;
  border: none;
  color: #4eb959;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 10px;
  padding: 10px 20px;
  &:hover {
    color: #12801d;
  }
`;
