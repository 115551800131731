import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import { ToastContainer, toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FiTrash } from "react-icons/fi";
import { PiFileTxtDuotone } from "react-icons/pi";
import { useAuth } from "@hooks/auth";
import RevisorExecution from "./RevisorExecution";
import {
  ModalOverlay,
  ModalContent,
  CloseButton,
  SendButton,
  UploadIcon,
  MessageWrapper,
  Message,
  SubMessage,
  DropzoneContainer,
  FileList,
  FileItem,
  DeleteButton,
} from "./styles";

const ModalRevisor = ({ isOpen, onClose }) => {
  const { projeto, token } = useAuth();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [uploadStartTime, setUploadStartTime] = useState(null);

  useEffect(() => {
    if (!isOpen) {
      setUploadedFiles([]);
      setIsProcessing(false);
      setUploadStartTime(null);
    }
  }, [isOpen]);

  const onDrop = (acceptedFiles) => {
    const filesWithPreview = acceptedFiles.map((file) => ({
      file,
      preview: URL.createObjectURL(file),
      sizeInKB: (file.size / 1024).toFixed(2),
    }));
    setUploadedFiles((prevFiles) => [...prevFiles, ...filesWithPreview]);
  };

  const removeFile = (fileName) => {
    setUploadedFiles((prevFiles) =>
      prevFiles.filter((fileData) => fileData.file.name !== fileName)
    );
  };

  const StyledDropzone = () => {
    const { getRootProps, getInputProps } = useDropzone({
      accept: { "text/plain": [] },
      onDrop,
    });

    return (
      <DropzoneContainer
        fullSize={uploadedFiles.length === 0}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <UploadIcon>+</UploadIcon>
        <MessageWrapper>
          <Message>Carregar arquivos</Message>
          <SubMessage>Arraste os arquivos SPED em .txt aqui</SubMessage>
        </MessageWrapper>
      </DropzoneContainer>
    );
  };

  const handleSend = async () => {
    if (!token) {
      toast.error("Usuário não autenticado. Por favor, faça login novamente.");
      return;
    }

    setLoading(true);
    const formData = new FormData();
    const startTime = Date.now();

    uploadedFiles.forEach((fileData) => {
      formData.append("files", fileData.file);
    });

    try {
      await axios.post(`${process.env.REACT_APP_API}/tools/revisor`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          projeto: projeto._id,
        },
      });

      setUploadStartTime(startTime);
      setIsProcessing(true);
      toast.success("Arquivos enviados com sucesso");
    } catch (error) {
      console.error("Erro no upload:", error);
      toast.error(
        error.response?.data?.message || "Erro no envio dos arquivos"
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      uploadedFiles.forEach((fileData) => {
        URL.revokeObjectURL(fileData.preview);
      });
    };
  }, [uploadedFiles]);

  if (!isOpen) return null;

  return (
    <>
      <ModalOverlay>
        <ModalContent>
          {isProcessing ? (
            <RevisorExecution
              files={uploadedFiles.map((file) => ({
                filename: file.file.name,
                size: file.sizeInKB,
              }))}
              onClose={() => {
                setIsProcessing(false);
                onClose();
              }}
              uploadStartTime={uploadStartTime}
            />
          ) : (
            <>
              <StyledDropzone />
              {uploadedFiles.length > 0 && (
                <FileList>
                  {uploadedFiles.map((fileData) => (
                    <FileItem key={fileData.file.name}>
                      <PiFileTxtDuotone
                        style={{
                          width: "30px",
                          height: "17px",
                          marginRight: "10px",
                        }}
                      />
                      <MessageWrapper>
                        <span>{fileData.file.name}</span>
                        <SubMessage>{fileData.sizeInKB} KB</SubMessage>
                      </MessageWrapper>
                      <DeleteButton
                        onClick={() => removeFile(fileData.file.name)}
                        disabled={loading}
                      >
                        <FiTrash
                          style={{
                            width: "30px",
                            height: "17px",
                            marginRight: "7px",
                            fill: "#0D0D11",
                          }}
                        />
                      </DeleteButton>
                    </FileItem>
                  ))}
                </FileList>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "20px",
                }}
              >
                {uploadedFiles.length > 0 && (
                  <SendButton onClick={handleSend} disabled={loading}>
                    {loading ? "Enviando..." : "Enviar"}
                  </SendButton>
                )}
                <CloseButton onClick={onClose}>Fechar</CloseButton>
              </div>
            </>
          )}
        </ModalContent>
      </ModalOverlay>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        transition={Bounce}
      />
    </>
  );
};

export default ModalRevisor;
