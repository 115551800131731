import React, { createContext, useContext, useEffect, useState } from "react";
import { useAuth } from "@hooks/auth";
import axios from "axios";

// Crie o contexto
const UsersContext = createContext();

// Crie o provider
export function UsersProvider({ children }) {
  const { token, projeto, user, setIsAdmin } = useAuth();

  const [users, setUsers] = useState([]);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(
        `https://dev.vidal-app.com/users/projeto`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            projeto: projeto._id,
            user: user._id,
          },
        }
      );

      setUsers(response.data);
      setIsAdmin(true);
    } catch (error) {
      setIsAdmin(false);
      console.error("Erro ao buscar usuários:", error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [token, projeto._id, user._id]);

  return (
    <UsersContext.Provider
      value={{
        users,
        setUsers,
        fetchUsers
      }}
    >
      {children}
    </UsersContext.Provider>
  );
}

// Crie um hook para usar o contexto
export function useUsers() {
  return useContext(UsersContext);
}
