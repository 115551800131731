import React, {
  useState,
  useEffect,
  useContext,
  createContext,
  useRef,
} from "react";

const DropdownContext = createContext();

export const DropdownProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal de criação
  const [isEditModalOpen, setIsEditModalOpen] = useState(false); // Modal de edição
  const triggerRef = useRef(null);

  const toggle = () => setIsOpen(!isOpen);

  useEffect(() => {
    if (isOpen && triggerRef.current) {
      const rect = triggerRef.current.getBoundingClientRect();
      setPosition({
        top: rect.bottom,
        left: rect.left,
      });
    }
  }, [isOpen]);

  const value = {
    isOpen,
    setIsOpen,
    toggle,
    position,
    triggerRef,
    isModalOpen,
    setIsModalOpen,
    isEditModalOpen,
    setIsEditModalOpen,
  };

  return (
    <DropdownContext.Provider value={value}>
      {children}
    </DropdownContext.Provider>
  );
};

export const useDropdown = () => {
  const context = useContext(DropdownContext);
  if (!context) {
    throw new Error("useDropdown must be used within a DropdownProvider");
  }
  return context;
};
