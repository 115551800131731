import React, { useEffect, useRef, useCallback, useState } from "react";
import {
  Bar,
  Card,
  Cards,
  Container,
  Menu,
  Relatorio,
  ReportDiv,
} from "./styles";
import {
  IoSearchOutline,
  IoCodeSlashOutline,
  IoCheckmark,
} from "react-icons/io5";
import ActiveFiltersModal from "./components/filtroHover/index";
import { IoMdDownload } from "react-icons/io";
import { CiCalendar } from "react-icons/ci";
import { useReports } from "@hooks/report";
import { useProjects } from "@hooks/projects";
import Mensal from "./components/mensal";
import ContentLoader from "react-content-loader";
import axios from "axios";
import { toast } from "react-toastify";
import { useTema } from "../../../../../../hooks/theme";
import { LuFilter } from "react-icons/lu";
import Filtro from "./components/filtro";
import { useAuth } from "@hooks/auth";
import XML from "../../../../../modals/xml";

export default function NFes() {
  const {
    documents,
    fetchDocuments,
    setPage,
    hasMore,
    page,
    infos,
    getInfos,
    documentType,
    documentStatus,
    handleDocumentTypeChange,
    handleDocumentStatusChange,
    loading,
    handleSearch,
  } = useReports();

  const { projeto } = useAuth();
  const { projectInfo, setXmlData } = useProjects();

  // Estados locais
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [filteredDocuments, setFilteredDocuments] = useState(null);
  const [isFiltering, setIsFiltering] = useState(false);
  const [abortController, setAbortController] = useState(null);
  const [activeFilters, setActiveFilters] = useState(null);
  const [localSearchTerm, setLocalSearchTerm] = useState("");

  // Estados para paginação de documentos filtrados
  const [filteredPage, setFilteredPage] = useState(1);
  const [filteredHasMore, setFilteredHasMore] = useState(true);
  const [isLoadingFiltered, setIsLoadingFiltered] = useState(false);

  // Refs
  const searchTimeoutRef = useRef(null);
  const observer = useRef();
  const scrollContainerRef = useRef(null);
  const scrollPositionRef = useRef(0);

  // Handlers
  const toggleFilter = () => setIsFilterOpen((prev) => !prev);

  const clearFilters = useCallback(() => {
    if (abortController) {
      abortController.abort();
      setAbortController(null);
    }

    setFilteredDocuments(null);
    setActiveFilters(null);
    setFilteredPage(1);
    setFilteredHasMore(true);
    setIsFiltering(false);
    setIsLoadingFiltered(false);
    setPage(1);

    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = 0;
    }
    scrollPositionRef.current = 0;

    if (observer.current) {
      observer.current.disconnect();
      observer.current = null;
    }
  }, [setPage]);

  const handleFilterResults = useCallback(
    (results) => {
      if ("abortController" in results) {
        setAbortController(results.abortController);
      }

      if ("isLoading" in results) {
        setIsFiltering(results.isLoading);
        setActiveFilters((prev) => ({
          ...prev,
          isLoading: results.isLoading,
        }));

        if (results.isLoading) {
          setFilteredDocuments(null);
        }
        return;
      }

      if (results?.hits) {
        const formattedResults = results.hits.map((doc) => ({
          ...doc,
          _source: {
            ...doc._source,
            isFiltered: true,
          },
        }));

        setFilteredDocuments(formattedResults);
        setFilteredHasMore(results.hasMore);
        setActiveFilters({
          startDate: results.activeFilters?.startDate,
          endDate: results.activeFilters?.endDate,
          emiDoc: results.activeFilters?.emiDoc,
          destDoc: results.activeFilters?.destDoc,
          cfop: results.activeFilters?.cfop,
          type: results.activeFilters?.type,
          status: results.activeFilters?.status,
          totalDocuments: results.totalDocs,
          fileId: results.fileId,
          projeto: projeto._id,
          isLoading: false,
        });

        setIsFiltering(false);
        setFilteredPage(1);
      }
    },
    [projeto._id]
  );

  // Function to load more filtered documents
  const loadMoreFilteredDocuments = useCallback(async () => {
    if (!filteredDocuments || !filteredHasMore || isLoadingFiltered) return;

    setIsLoadingFiltered(true);
    try {
      const nextPage = filteredPage + 1;
      const params = new URLSearchParams();

      if (activeFilters) {
        // Add all active filters to params
        const formattedStartDate = formatDateForAPI(activeFilters.startDate);
        const formattedEndDate = formatDateForAPI(activeFilters.endDate);

        if (formattedStartDate) params.append("startDate", formattedStartDate);
        if (formattedEndDate) params.append("endDate", formattedEndDate);
        if (activeFilters.emiDoc) params.append("emiDoc", activeFilters.emiDoc);
        if (activeFilters.destDoc)
          params.append("destDoc", activeFilters.destDoc);
        if (activeFilters.cfop) params.append("cfop", activeFilters.cfop);
        if (activeFilters.type) params.append("type", activeFilters.type);
        if (activeFilters.status) params.append("status", activeFilters.status);
      }

      params.append("page", nextPage.toString());
      params.append("pageSize", "100");

      const response = await axios.get(
        `${process.env.REACT_APP_API}/report/xml/search-filter`,
        {
          params,
          headers: { projeto: projeto._id },
        }
      );

      if (response.data.success) {
        const newDocs = response.data.data.hits.map((doc) => ({
          ...doc,
          _source: {
            ...doc._source,
            isFiltered: true,
          },
        }));

        setFilteredDocuments((prev) => [...prev, ...newDocs]);
        setFilteredHasMore(response.data.data.hasMore);
        setFilteredPage(nextPage);
      }
    } catch (error) {
      console.error("Erro ao carregar mais documentos filtrados:", error);
      toast.error("Erro ao carregar mais documentos");
    } finally {
      setIsLoadingFiltered(false);
    }
  }, [
    filteredDocuments,
    filteredHasMore,
    isLoadingFiltered,
    filteredPage,
    activeFilters,
    projeto._id,
  ]);

  const lastDocumentElementRef = useCallback(
    (node) => {
      if (loading || isLoadingFiltered || isFiltering) return;
      if (!filteredHasMore) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && scrollContainerRef.current) {
          const container = scrollContainerRef.current;
          const isNearBottom =
            container.scrollHeight -
              container.scrollTop -
              container.clientHeight <
            150;

          if (isNearBottom) {
            scrollPositionRef.current = container.scrollTop;
            loadMoreFilteredDocuments();
          }
        }
      });

      if (node) observer.current.observe(node);
    },
    [
      loading,
      isLoadingFiltered,
      isFiltering,
      filteredHasMore,
      loadMoreFilteredDocuments,
    ]
  );

  const handleDownloadXML = async (nfe) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/report/download/${nfe}`,
        {
          responseType: "blob",
        }
      );

      const contentType = response.headers["content-type"];
      if (
        !contentType.includes("application/xml") &&
        !contentType.includes("text/xml")
      ) {
        toast.error("Erro ao baixar o XML: Tipo de arquivo inválido.");
        return;
      }

      const blob = new Blob([response.data], { type: contentType });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${nfe}.xml`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      toast.success("Download realizado com sucesso!");
    } catch (error) {
      if (error.response && error.response.status === 404) {
        toast.error("Arquivo XML não encontrado.");
      } else {
        toast.error("Erro ao baixar o XML.");
      }
      console.error("Erro ao baixar o XML:", error);
    }
  };

  const openModal = (data) => {
    setXmlData(data);
    document.getElementById("xml").style.display = "flex";
  };

  // DocumentLoader component
  function DocumentLoader() {
    const { theme } = useTema();
    const backgroundColor = theme === "light" ? "#f0f0f0" : "#616161";
    const foregroundColor = theme === "light" ? "#e0e0e0" : "#ecebeb";

    return (
      <ContentLoader
        speed={2}
        width={1500}
        height={50}
        viewBox="0 0 1500 50"
        backgroundColor={backgroundColor}
        foregroundColor={foregroundColor}
      >
        <circle cx="25" cy="25" r="5" />
        <rect x="70" y="20" rx="3" ry="3" width="130" height="6" />
        <rect x="250" y="20" rx="3" ry="3" width="400" height="6" />
        <rect x="730" y="20" rx="3" ry="3" width="100" height="6" />
        <rect x="860" y="20" rx="3" ry="3" width="200" height="6" />
      </ContentLoader>
    );
  }

  // Effects for cleanup
  useEffect(() => {
    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
      if (abortController) {
        abortController.abort();
      }
    };
  }, []);

  // Effect for scroll position restoration
  useEffect(() => {
    if (!isLoadingFiltered && filteredPage > 1) {
      scrollContainerRef.current.scrollTop = scrollPositionRef.current;
    }
  }, [filteredDocuments, isLoadingFiltered, filteredPage]);

  return (
    <Container>
      {isFilterOpen && (
        <Filtro
          onFilterApply={handleFilterResults}
          activeFilters={activeFilters}
          onClose={() => setIsFilterOpen(false)}
        />
      )}

      {(activeFilters || isFiltering) && (
        <ActiveFiltersModal
          filters={activeFilters || {}}
          onClearFilters={clearFilters}
          totalDocuments={activeFilters?.totalDocuments || 0}
          isLoading={isFiltering}
          abortController={abortController}
        />
      )}

      <ReportDiv>
        <Cards>
          {infos !== undefined &&
            Object.keys(infos).map((key, index) => (
              <Card key={index}>
                <div className="header">
                  <p>{key}</p>
                </div>
                <div className="content">{infos[key].toLocaleString()}</div>
              </Card>
            ))}
        </Cards>
        <Relatorio>
          <div className="block">
            <div className="head">
              <div className="side">
                <div className="search">
                  <div className="filter" onClick={toggleFilter}>
                    <LuFilter />
                  </div>
                </div>
              </div>
            </div>
            <div className="table">
              {(filteredDocuments || isFiltering) && (
                <>
                  <div className="head">
                    <div className="colum check">
                      <div className="thumb white"></div>
                    </div>
                    <div className="colum data">
                      <CiCalendar />
                      <p>Data</p>
                    </div>
                    <div className="colum chave">
                      <IoCodeSlashOutline />
                      <p>Chave</p>
                    </div>
                    <div className="colum status">
                      <IoCodeSlashOutline />
                      <p>Status</p>
                    </div>
                    <div className="colum cnpj">
                      <IoCodeSlashOutline />
                      <p>CNPJ</p>
                    </div>
                  </div>
                  <div className="display" ref={scrollContainerRef}>
                    {isFiltering ? (
                      Array.from({ length: 5 }).map((_, index) => (
                        <DocumentLoader key={index} />
                      ))
                    ) : filteredDocuments?.length > 0 ? (
                      filteredDocuments.map((doc, index) => (
                        <div
                          className="items"
                          key={doc._id || index}
                          onClick={() => openModal(doc._source)}
                        >
                          <div className="item check">
                            <div
                              className="thumb"
                              style={{
                                backgroundColor: doc._source.check
                                  ? "#07bc0c"
                                  : "",
                              }}
                            >
                              {doc._source.check ? (
                                <IoCheckmark style={{ color: "white" }} />
                              ) : null}
                            </div>
                          </div>
                          <div className="item data">
                            <p>
                              {new Date(doc._source.dhEvento)
                                .toLocaleString("pt-BR")
                                .replace(",", " - ")}
                            </p>
                          </div>
                          <div className="item chave">
                            <p>{doc._source.chave}</p>
                          </div>
                          <div className="item status">
                            <p>{doc._source.status}</p>
                          </div>
                          <div className="item cnpj">
                            <p>{doc._source.cnpj}</p>
                          </div>
                          {doc._source.check && (
                            <div
                              className="item check download"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDownloadXML(doc._source.chave);
                              }}
                            >
                              <IoMdDownload color="white" />
                            </div>
                          )}
                        </div>
                      ))
                    ) : (
                      <div className="no-results">
                        <p>Nenhum documento encontrado</p>
                      </div>
                    )}
                    {isLoadingFiltered && (
                      <div className="loading-more">
                        <DocumentLoader />
                      </div>
                    )}
                  </div>
                </>
              )}
              {!filteredDocuments && !isFiltering && (
                <div className="empty-state">
                  <div className="message">
                    <LuFilter size={48} opacity={0.5} />
                    <p>Utilize o filtro para visualizar os documentos</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Relatorio>
      </ReportDiv>

      <Mensal />
    </Container>
  );
}

// Helper function for date formatting
function formatDateForAPI(dateObj) {
  if (!dateObj?.year) return null;

  const month = (dateObj.month + 1).toString().padStart(2, "0");
  const day = dateObj.day.toString().padStart(2, "0");
  return `${dateObj.year}-${month}-${day}T00:00:00.000Z`;
}
