import styled from "styled-components";

const colors = {
  background: "#05070a",
  text: "#757575e5",
  headerBorder: "#05070a",
  highlight: "#7b7b7dd9",
  buttonHover: "#ffffff",
};

//Modal Download container
export const ModalContainer = styled.div`
  background-color: ${({ theme }) => theme.custom};
  color: ${({ theme }) => theme.text};
  width: 30vw;
  max-height: 90vh;
  padding: 15px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.border};
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.border};
  margin-bottom: 10px;
  h2 {
    color: ${({ theme }) => theme.text};
    font-size: 1em;
    flex-grow: 1;
  }
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  svg {
    font-size: 2em;
    color: ${({ theme }) => theme.border2};
    transition: color 0.3s;
    &:hover {
      color: ${({ theme }) => theme.text};
    }
  }
`;

export const Body = styled.div`
  margin: 15px 1;
`;

export const SectionContainer = styled.div`
  background-color: ${({ theme }) => theme.custom};
  border-radius: 5px;
  padding: 8px;
  margin-bottom: 2px;

  h3 {
    color: ${({ theme }) => theme.text};
    margin: -14px 0 -2px;
    border-bottom: 2px solid ${({ theme }) => theme.border};
    font-size: 0.75em;
    font-weight: bold;
    text-align: center;
    padding: 2px 0;
  }

  p {
    margin: 3px 0;
    font-size: 0.5681em;

    strong {
      font-weight: bold;
      font-size: 1.1em;
    }
  }
`;
// Botões de ação
export const Footer = styled.div`
  display: flex;
  justify-content: space-around;

  button {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: transform 0.3s, color 0.3s;

    &:hover {
      color: ${({ theme }) => theme.text};
      transform: scale(1.1);
    }

    svg {
      font-size: 1em;
      color: ${({ theme }) => theme.border2};
      transition: color 0.3s;
    }

    span {
      font-size: 0.6em;
      margin-top: 5px;
      color: ${({ theme }) => theme.border2};
      transition: color 0.3s;
    }
  }

  button:hover svg,
  button:hover span {
    color: ${({ theme }) => theme.text};
  }
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
`;

export const OkButton = styled.button`
  background-color: ${({ theme }) => theme.custom};
  border: 1px solid ${({ theme }) => theme.border};
  color: ${({ theme }) => theme.text};
  padding: 5px 45px;
  margin: 0 auto; /* Centraliza o botão */
  margin-top: 20px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: normal;
  border-radius: 15px;
  display: block; /* Para centralização */

  &:hover {
    ${({ theme }) => theme.border};
  }
`;
