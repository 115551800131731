import React, { useState } from "react";
import { Container, Box, Input, Send, Button } from "./styles";
import { MdAlternateEmail } from "react-icons/md";
import { IoLockClosedSharp } from "react-icons/io5";

import { useAuth } from "@hooks/auth";
import axios from "axios";

export default function Login({ setStatus }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { projeto, setProjeto, getProjeto, setToken, setUser } = useAuth();

  function handleSubmit() {
    if (!email || !password) {
      alert("Por favor, preencha todos os campos.");
      return;
    }

    const payload = {
      email: email,
      password: password,
    };

    axios
      .post(`https://dev.vidal-app.com/auth`, payload)
      .then((response) => {
        if (response.status === 200) {
          setStatus("projetos");
          setToken(response.data.token);
          setUser(response.data.user);
          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${response.data.token}`;
          axios
            .get(`https://dev.vidal-app.com/projetos`, {
              headers: {
                User: "66a989da73705d08e443f6ef", // Valor do usuário
                Projeto: response.data.projeto || "639903d1e193693c0d03e01f", // Utilizando o valor do projeto que foi passado
              },
            })
            .then((res) => {
             
              if (res.status === 200) {
                getProjeto();
              } else {
                alert("Falha: Não foi possível autenticar o usuário.");
              }
            });
        } else {
          alert("Falha: Não foi possível autenticar o usuário.");
        }
      })
      .catch((error) => {
        if (error.response) {
          // O servidor retornou uma resposta
          alert(
            `Erro: ${
              error.response.data.message ||
              "Algo deu errado. Tente novamente mais tarde."
            }`
          );
        } else {
          // Ocorreu um erro durante a solicitação
          alert("Erro: Algo deu errado. Tente novamente mais tarde.");
        }
        console.error("Erro ao autenticar:", error);
      });
  }

  return (
    <Container id="CHECK">
      <Box>
        <Input>
          <MdAlternateEmail />

          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            placeholder="E-mail"
          />
        </Input>
        <Input>
          <IoLockClosedSharp />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            placeholder="Senha"
          />
        </Input>
        <Send>
          <Button onClick={handleSubmit}>
            <p>Entrar</p>
          </Button>
        </Send>
      </Box>
    </Container>
  );
}
