// styles.js
import styled from "styled-components";

// Modal Styles
export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
`;

export const ModalContent = styled.div`
  background: #05070a;
  padding: 20px;
  border-radius: 21px;
  border: 0.1px solid #505153;
  width: 400px;
  max-height: 80vh;
  overflow-y: auto;
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  color: #c21b0c;
  font-size: 1rem;
  cursor: pointer;

  &:hover {
    color: #8f150a;
  }
`;

export const DropzoneContainer = styled.div`
  border: 1.5px dashed #505153;
  opacity: 0.5;
  padding: 50px;
  text-align: left;
  border-radius: 10px;
  margin-bottom: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 74%;
  height: ${(props) => (props.fullSize ? "300px" : "60px")};
`;

export const UploadIcon = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  font-size: 24px;
  color: #1e1f22;
  border: 2px solid #1e1f22;
  border-radius: 50%;
  margin-right: 20px;
`;

export const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Message = styled.p`
  color: #bbb;
  font-size: 21px;
  font-weight: bold;
`;

export const SubMessage = styled.p`
  font-size: 13px;
  color: #67686a;
`;

// File List Styles
export const FileList = styled.ul`
  list-style: none;
  background: #0d0d11;
  padding: -1px;
  margin-left: -20px;
  margin-right: -28px;
  border: 0.1px solid #252528;
  height: 250px;
  overflow-y: auto;
`;

export const FileItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #07090c;
  padding: 10px;
  margin-bottom: 0px;
  border: 0.1px solid #252528;
  color: #fff;
  font-size: 0.8rem;
`;

export const DeleteButton = styled.button`
  background: none;
  border: none;
  color: #c21b0c;
  font-size: 1.2rem;
  cursor: pointer;
  padding: 10px 20px;
  margin-left: auto;

  &:hover {
    color: #8f150a;
  }
`;

// Action Buttons
export const SendButton = styled.button`
  background: none;
  border: none;
  color: #4eb959;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 10px;
  padding: 10px 20px;
  &:hover {
    color: #12801d;
  }
`;

// Info Section Styles
export const InfoSection = styled.div`
  background-color: #07090c;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
`;

export const InfoItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const InfoLabel = styled.span`
  color: #67686a;
  font-size: 14px;
`;

export const InfoValue = styled.span`
  color: #ffffff;
  font-size: 14px;
`;

export const DownloadButton = styled.button`
  background-color: #4eb959;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  margin-bottom: 10px;

  &:hover {
    background-color: #3da048;
  }
`;

// Execution Styles
export const ExecutionContainer = styled.div`
  position: relative;
  padding: 20px;
  background-color: #07090c;
  padding-bottom: 80px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
`;

export const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
`;

export const SectionTitle = styled.div`
  margin-bottom: 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #05080b;
  padding-bottom: 10px;
  margin-top: 20px;
`;

export const FileInfo = styled.div`
  background-color: #07090c;
  margin-top: 20px;
`;

export const FileRow = styled.div`
  margin-bottom: 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #05080b;
  padding: 10px 0;
  justify-content: space-between;
`;

export const ProgressBarContainer = styled.div`
  width: 100%;
  background-color: #1e1f22;
  height: 5px;
  margin-top: 20px;
  border-radius: 5px;
`;

export const ProgressBar = styled.div`
  width: ${(props) => props.progress}%;
  height: 100%;
  background-color: #12742c;
  border-radius: 5px;
  transition: width 0.5s ease-in-out;
`;

export const StageInfo = styled.div`
  margin-top: 10px;
  color: #b4b5b6;
  text-align: center;
`;

export const CloseButtonAbsolute = styled.button`
  position: absolute;
  bottom: 20px;
  right: 20px;
  background: none;
  border: none;
  color: #c21b0c;
  font-size: 1rem;
  cursor: pointer;

  &:hover {
    color: #8f150a;
  }

  &:disabled {
    cursor: not-allowed;
    color: #67686a;
  }
`;
