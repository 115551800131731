// components/CompletedRevisor.js

import React from "react";
import styled from "styled-components";
import {
  IoCloudDownloadOutline,
  IoCheckmarkCircleOutline,
} from "react-icons/io5";
import {
  ModalOverlay,
  ModalContent,
  CloseButton,
  InfoSection,
  InfoItem,
  InfoLabel,
  InfoValue,
  DownloadButton,
  MessageWrapper,
  SubMessage,
} from "./styles";
import axios from "axios";
import { useAuth } from "@hooks/auth";
import { toast } from "react-toastify";

// Estilizações mantidas
const MessageWrapper2 = styled(MessageWrapper)`
  margin-bottom: 10px;
`;

const SubMessage2 = styled(SubMessage)`
  color: #9c9d9e;
`;

const CompletedRevisor = ({ onClose, files, resumoProcessamento, zipPath }) => {
  const { token, projeto } = useAuth();

  const downloadZip = async () => {
    try {
      if (!zipPath) {
        toast.error("Arquivo ZIP não disponível.");
        return;
      }

      const response = await axios.get(
        `${process.env.REACT_APP_API}/tools/download-sped`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            projeto: projeto._id,
          },
          responseType: "blob",
        }
      );

      // Extrair o nome do arquivo do header 'Content-Disposition'
      let fileName = "resumos.zip"; // Nome padrão
      const contentDisposition = response.headers["content-disposition"];
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
        if (fileNameMatch && fileNameMatch.length === 2) {
          fileName = fileNameMatch[1];
        }
      }

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);

      toast.success("Download do ZIP iniciado com sucesso");
    } catch (error) {
      if (error.response?.status === 401) {
        toast.error("Erro de autenticação. Por favor, faça login novamente.");
      } else if (error.response?.status === 404) {
        toast.error("Arquivo ZIP não encontrado no servidor.");
      } else {
        toast.error("Erro ao fazer download do arquivo ZIP");
      }
      console.error("Erro no download do ZIP:", error);
    }
  };

  return (
    <ModalOverlay>
      <ModalContent>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "20px",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <IoCheckmarkCircleOutline color="#4eb959" size={24} />
            <span
              style={{ fontSize: "18px", marginLeft: "8px", color: "#4eb959" }}
            >
              Concluído
            </span>
          </div>
        </div>

        <hr style={{ border: "1px solid #1E1F22", margin: "10px 0" }} />

        <div
          style={{
            marginBottom: "20px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <IoCloudDownloadOutline size={20} color="#515254" />
          <span style={{ marginLeft: "8px", fontSize: "25px", color: "#fff" }}>
            Revisor SPED
          </span>
        </div>

        <InfoSection>
          <InfoItem>
            <InfoLabel>Arquivos processados:</InfoLabel>
            <InfoValue>
              {files.map((file) => (
                <div key={file.filename}>
                  <MessageWrapper2>
                    <span>{file.filename}</span>
                    <SubMessage2>
                      <span>{file.size} KB</span>
                    </SubMessage2>
                  </MessageWrapper2>
                </div>
              ))}
            </InfoValue>
          </InfoItem>

          {resumoProcessamento.map((item) => (
            <InfoItem key={item.filename}>
              <div style={{ width: "100%" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "15px",
                  }}
                >
                  <InfoLabel style={{ fontSize: "16px" }}>
                    {item.filename}
                  </InfoLabel>
                  {/* Removido o botão de download do resumo */}
                </div>

                <div
                  style={{
                    padding: "15px",
                    background: "#0D0D11",
                    borderRadius: "5px",
                    marginBottom: "15px",
                  }}
                >
                  <div className="grid gap-2">
                    {item.resumo.notasNoSPED !== undefined && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span style={{ color: "#67686A" }}>Notas no SPED:</span>
                        <span style={{ color: "#FFF" }}>
                          {item.resumo.notasNoSPED}
                        </span>
                      </div>
                    )}
                    {item.resumo.notasEncontradas !== undefined && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span style={{ color: "#67686A" }}>
                          Notas encontradas:
                        </span>
                        <span style={{ color: "#FFF" }}>
                          {item.resumo.notasEncontradas}
                        </span>
                      </div>
                    )}
                    {item.resumo.notasNaoEncontradas !== undefined && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span style={{ color: "#67686A" }}>
                          Notas não encontradas:
                        </span>
                        <span style={{ color: "#FFF" }}>
                          {item.resumo.notasNaoEncontradas}
                        </span>
                      </div>
                    )}
                    {item.resumo.notasComDiferencas !== undefined && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span style={{ color: "#67686A" }}>
                          Notas com diferenças:
                        </span>
                        <span style={{ color: "#FFF" }}>
                          {item.resumo.notasComDiferencas}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </InfoItem>
          ))}

          {/* Botão de Download do ZIP */}
          {zipPath && (
            <InfoItem>
              <div style={{ width: "100%" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "15px",
                  }}
                >
                  <InfoLabel style={{ fontSize: "16px" }}>
                    Arquivo ZIP de Resumos
                  </InfoLabel>
                  <DownloadButton
                    onClick={downloadZip}
                    style={{ width: "auto", padding: "8px 15px" }}
                  >
                    Baixar ZIP
                  </DownloadButton>
                </div>
              </div>
            </InfoItem>
          )}
        </InfoSection>

        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <CloseButton onClick={onClose}>Fechar</CloseButton>
        </div>
      </ModalContent>
    </ModalOverlay>
  );
};

export default CompletedRevisor;
