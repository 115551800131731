import styled from "styled-components";

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const Container = styled.div`
  width: 17em;
  height: 18em;
  display: flex;
  flex-direction: column;
  font-size: 1.2em;
  border-radius: 10px;
  z-index: 2;
  background-color: ${({ theme }) => theme.custom};
  border: 1px solid ${({ theme }) => theme.border5};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

export const Header = styled.div`
  width: 100%;
  height: 2em;
  border-bottom: 1px solid ${({ theme }) => theme.border5};
  display: flex;

  .thumb {
    height: 100%;
    width: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid ${({ theme }) => theme.border5};

    svg {
      font-size: 0.8em;
      opacity: 0.5;
      color: #238636;
    }
  }

  input {
    width: calc(100% - 4em);
    height: 100%;
    background-color: ${({ theme }) => theme.custom};
    display: flex;
    align-items: center;
    overflow: hidden;
    padding: 0 10px;
    color: ${({ theme }) => theme.text};
    font-size: 0.7em;
    opacity: 0.6;
  }

  .return {
    height: 100%;
    width: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.8;
    transition: all 0.2s;
    cursor: pointer;

    svg {
      opacity: 0.4;
      font-size: 0.8em;

      color: ${({ theme }) => theme.text};
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 13em;
  display: flex;
  flex-direction: column;

  .item {
    width: 100%;
    height: 2em;
    display: flex;
    align-items: center;
    justify-content: space-between;

    border-bottom: 1px solid ${({ theme }) => theme.border5};
    div {
      width: 50%;
      margin-right: 10px;
      color: ${({ theme }) => theme.text};
      font-size: 0.6em;
      text-align: right;
      opacity: 0.6;
    }

    .title {
      display: flex;
      justify-content: flex-start;

      p {
        font-size: 1em;
        color: ${({ theme }) => theme.text};
        opacity: 0.8;
        margin-left: 10px;
      }
    }
  }
`;

export const Footer = styled.div`
  width: 100%;
  height: 3em;
  border-top: 1px solid ${({ theme }) => theme.border5};
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .save-button {
    width: auto;
    height: auto;
    background-color: rgb(130, 185, 42, 0.5);
    padding: 0.2em 1em;
    border-radius: 2px;
    opacity: 0.9;
    cursor: pointer;
    transition: all 0.2s;
    margin-right: 1em;

    &:hover {
      opacity: 1;
    }

    p {
      color: ${({ theme }) => theme.text};
      font-size: 0.7em;
    }
  }

  .delete-button {
    width: auto;
    height: auto;
    background-color: rgba(200, 0, 100, 0.8);
    padding: 0.2em 1em;
    border-radius: 2px;
    opacity: 0.9;
    cursor: pointer;
    transition: all 0.2s;
    margin-right: 1em;

    &:hover {
      opacity: 1;
    }

    p {
      color: ${({ theme }) => theme.text};
      font-size: 0.7em;
    }
  }
`;
