// SPEDs.js (React component)
import { useState } from "react";
import { Container, Side } from "./styles";
import {
  IoSearchOutline,
  IoCubeOutline,
  IoCodeSlashOutline,
} from "react-icons/io5";
import { MdFileDownload } from "react-icons/md";
import { GoDatabase } from "react-icons/go";
import { FaCode } from "react-icons/fa6";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import { Bar, Line, Pie } from "react-chartjs-2";
import { FaLongArrowAltDown, FaLongArrowAltUp } from "react-icons/fa";
import { AiOutlineStock } from "react-icons/ai";
import { MdErrorOutline } from "react-icons/md";
import { IoMdCheckmark } from "react-icons/io";

// Import and register Chart.js components
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

Chart.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

export default function SPEDs() {
  // Controle de visibilidade do ícone de download
  const [graphType, setGraphType] = useState("bar");
  const [hover, setHover] = useState(false);
  const [showDownloadIcon, setShowDownloadIcon] = useState(false);

  // Funções para manipular a exibição dos ícones
  const handleMouseEnter = () => setShowDownloadIcon(true);
  const handleMouseLeave = () => setShowDownloadIcon(false);

  const data = {
    labels: ["71444475000115", "12345678000199", "98765432000188"],
    datasets: [
      {
        label: "Faltantes",
        data: [12, 19, 3],
        backgroundColor: ["#ff6384", "#36a2eb", "#ffce56"],
        barThickness: 24, // 1.5em = 24px
      },
    ],
  };

  const options = {
    responsive: false,
    maintainAspectRatio: false,
    layout: {
      padding: 0,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (context) {
            const xLabel = context.label;
            const value = context.parsed.y;
            return `${xLabel}: ${value}`;
          },
        },
      },
    },
    scales: {
      x: {
        display: false,
        grid: {
          display: false,
        },
        ticks: {
          padding: 0,
        },
        categoryPercentage: 0.1, // Diminui a largura das categorias
        barPercentage: 1.0, // As barras ocupam todo o espaço da categoria
      },
      y: {
        display: false,
        grid: {
          display: false,
        },
        ticks: {
          display: false,
          padding: 0,
          beginAtZero: true,
          max: Math.max(...data.datasets[0].data),
        },
      },
    },
  };

  const renderGraph = () => {
    const chartProps = {
      data,
      options,
      style: {
        backgroundColor: "#fff",
        opacity: hover ? 1 : 0.8,
        transition: "opacity 0.3s",
      },
      onMouseEnter: () => setHover(true),
      onMouseLeave: () => setHover(false),
    };

    switch (graphType) {
      case "bar":
        return <Bar {...chartProps} />;
      case "line":
        return <Line {...chartProps} />;
      case "pie":
        return <Pie {...chartProps} />;
      default:
        return <Bar {...chartProps} />;
    }
  };

  return (
    <Container>
      <Side className="left">
        <div className="header">
          <div className="search">
            <IoSearchOutline />
            <input placeholder="Procurar empresa" />
          </div>
        </div>
        <div className="display">
          <div
            className="card"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <div className="header">
              <div className="thumb">
                <IoCubeOutline
                  className={`cube ${showDownloadIcon ? "hidden" : ""}`}
                />
                <MdFileDownload
                  className={`download ${showDownloadIcon ? "visible" : ""}`}
                />
              </div>
              <div className="content">
                <p className="nome">AUTOMEC COMERCIAL</p>
                <p className="cnpj">71444475000115</p>
              </div>
            </div>
            <div className="infos">
              <div className="item">
                <div className="tag">
                  <GoDatabase />
                  <p>SEFAZ</p>
                </div>
                <div className="value">
                  <FaCode />
                  <p>9.320</p>
                </div>
                <div className="value">
                  <MdErrorOutline />


                  <p>9.320</p>
                </div>
                <div className="value">
                  <IoMdCheckmark />
                  <p>9.320</p>
                </div>
              </div>
              <div className="item">
                <div className="tag">
                  <FaCode />
                  <p>SPED's</p>
                </div>
                <div className="value">
                  <FaCode />
                  <p>9.320</p>
                </div>
                <div className="value">
                  <MdErrorOutline />


                  <p>9.320</p>
                </div>
                <div className="value">
                  <IoMdCheckmark />
                  <p>9.320</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Side>
      <Side>
        <div className="cards">
          <div className="card">
            <div className="header">
              <FaCode />
              <p>Total</p>
            </div>
            <div className="content">
              <p>9.320</p>
            </div>
          </div>
          <div className="card">
            <div className="header">
              <MdErrorOutline />

              <p>Faltantes</p>
            </div>
            <div className="content">
              <p>9.320</p>
            </div>
          </div>
          <div className="card">
            <div className="header">
              <IoMdCheckmark />
              <p>Encontradas</p>
            </div>
            <div className="content">
              <p>9.320</p>
            </div>
          </div>
        </div>
        <div className="grafs">
          <div className="index">
            <div className="header">
              <HiOutlineBuildingOffice2 />
              <p>Fornecedores</p>
            </div>
            <div className="display">
              <div className="item">
                <div className="thumb">
                  <IoCubeOutline />
                </div>
                <div className="content">
                  <p className="name">GENERAL MOTORS</p>
                  <p className="cnpj">71444475000115</p>
                </div>
              </div>
            </div>
          </div>
          <div className="graf">
            <div className="header">
              <AiOutlineStock />

              <p>Faltantes por fornecedor</p>
            </div>
            <div className="display">{renderGraph()}</div>
          </div>
        </div>
        <div className="table">
          <div className="header">
            <div className="thumb">
              <IoCubeOutline />
            </div>
            <div className="item desc">
              <p>Descrição</p>
            </div>
            <div className="item">
              <FaCode />
              <p>Total</p>
            </div>
            <div className="item">
              <MdErrorOutline />


              <p>Faltantes</p>
            </div>
            <div className="item">
              <IoMdCheckmark />

              <p>Encontradas</p>
            </div>
          </div>
          <div className="display"></div>
        </div>
      </Side>
    </Container>
  );
}
