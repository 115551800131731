import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  ModalContainer,
  Header,
  Body,
  Footer,
  CloseButton,
  SectionContainer,
  Overlay,
  OkButton,
} from "./styles";
import { CgCloseO } from "react-icons/cg";
import { RxEyeOpen } from "react-icons/rx";
import { GrDocumentPdf } from "react-icons/gr";
import { IoCodeDownload } from "react-icons/io5";
import { useAuth } from "@hooks/auth";
import { saveAs } from "file-saver";
import { jsPDF } from "jspdf";
import { toast } from "react-toastify";
import { useTheme } from "styled-components"; // Importa o useTheme
import PdfViewerModal from "./pdfModal";
import XML from "../../../../../../../modals/xml";

// Funções auxiliares de formatação
const formatMoney = (value) => {
  if (!value && value !== 0) return "NA";
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(value);
};

const formatDate = (dateString) => {
  if (!dateString) return "NA";
  try {
    return new Date(dateString).toLocaleDateString("pt-BR");
  } catch (error) {
    return dateString;
  }
};

const formatDocument = (doc) => {
  if (!doc) return "NA";
  const numbers = doc.replace(/\D/g, "");
  if (numbers.length === 11) {
    return numbers.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  } else if (numbers.length === 14) {
    return numbers.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      "$1.$2.$3/$4-$5"
    );
  }
  return doc;
};

// Função para gerar XML
const generateXML = (nfeInfo) => {
  const xmlContent = `
    <notaFiscal>
      <informacoesDaNota>
        <chave>${nfeInfo.chv || "NA"}</chave>
        <data>${formatDate(nfeInfo.data) || "NA"}</data>
        <status>${nfeInfo.check ? "Processado" : "Pendente"}</status>
        <valor>${
          formatMoney(nfeInfo.valor).replace("R$", "").trim() || "NA"
        }</valor>
        <quantidadeDeItens>${nfeInfo.itens || 0}</quantidadeDeItens>
        <cfop>${nfeInfo.cfop || "NA"}</cfop>
        <naturezaDaOperacao>${nfeInfo.natOp || "NA"}</naturezaDaOperacao>
      </informacoesDaNota>
      <entidadesComerciais>
        <emitente>${nfeInfo.emi?.nome || "NA"}</emitente>
        <cnpjEmitente>${nfeInfo.emi?.cnpj || "NA"}</cnpjEmitente>
        <destinatario>${nfeInfo.dest?.nome || "NA"}</destinatario>
        <cnpjDestinatario>${nfeInfo.dest?.cnpj || "NA"}</cnpjDestinatario>
      </entidadesComerciais>
      <impostos>
        <icms>${
          formatMoney(nfeInfo.impostos?.icms).replace("R$", "").trim() || "NA"
        }</icms>
        <st>${
          formatMoney(nfeInfo.impostos?.st).replace("R$", "").trim() || "NA"
        }</st>
        <ipi>${
          formatMoney(nfeInfo.impostos?.ipi).replace("R$", "").trim() || "NA"
        }</ipi>
        <cofins>${
          formatMoney(nfeInfo.impostos?.cofins).replace("R$", "").trim() || "NA"
        }</cofins>
      </impostos>
    </notaFiscal>
  `;
  return xmlContent.trim();
};

// Componente para mensagem de erro
const ErrorMessage = ({ message, onClose }) => (
  <div className="error-alert" style={{ textAlign: "center" }}>
    <p>{message}</p>
    <OkButton onClick={onClose}>OK</OkButton>
  </div>
);

// Componente de informações da NFe
const NfeInfoSection = ({ nfeInfo }) => (
  <>
    <SectionContainer>
      <h3>INFORMAÇÕES DA NOTA</h3>
      <p>
        <strong>Chave:</strong> {nfeInfo.chave || nfeInfo.chave}
      </p>
      <p>
        <strong>Data de Emissão:</strong> {formatDate(nfeInfo.dhEvento)}
      </p>
      <p>
        <strong>Status:</strong> {nfeInfo.status}
      </p>
      <p>
        <strong>Valor:</strong> {formatMoney(nfeInfo.valor)}
      </p>
      <p>
        <strong>Quantidade de Itens:</strong> {nfeInfo.itens || 0}
      </p>
      <p>
        <strong>CFOP:</strong> {nfeInfo.cfop || "NA"}
      </p>
      <p>
        <strong>Natureza da Operação:</strong> {nfeInfo.natOp || "NA"}
      </p>
      <p>
        <strong>Modelo:</strong> {nfeInfo.modelo || "NA"}
      </p>
    </SectionContainer>

    <SectionContainer>
      <h3>EMITENTE</h3>
      <p>
        <strong>Nome:</strong> {nfeInfo.emiNome || "NA"}
      </p>
      <p>
        <strong>CNPJ:</strong> {formatDocument(nfeInfo.emiDoc)}
      </p>
      <p>
        <strong>Endereço:</strong> {nfeInfo.emi?.endereco || "NA"}
      </p>
      <p>
        <strong>Cidade/UF:</strong> {`${nfeInfo.emiCidade || "NA"}`}
      </p>
    </SectionContainer>

    <SectionContainer>
      <h3>DESTINATÁRIO</h3>
      <p>
        <strong>Nome:</strong> {nfeInfo.destNome || "NA"}
      </p>
      <p>
        <strong>Documento:</strong> {formatDocument(nfeInfo.destDoc)}
      </p>
      <p>
        <strong>Endereço:</strong> {nfeInfo.dest?.endereco || "NA"}
      </p>
      <p>
        <strong>Cidade/UF:</strong> {`${nfeInfo.destCidade || "NA"}`}
      </p>
    </SectionContainer>

    {nfeInfo.impostos && (
      <SectionContainer>
        <h3>IMPOSTOS</h3>
        <p>
          <strong>ICMS:</strong> {formatMoney(nfeInfo.impostos.icms)}
        </p>
        <p>
          <strong>ST:</strong> {formatMoney(nfeInfo.impostos.st)}
        </p>
        <p>
          <strong>IPI:</strong> {formatMoney(nfeInfo.impostos.ipi)}
        </p>
        <p>
          <strong>COFINS:</strong> {formatMoney(nfeInfo.impostos.cofins)}
        </p>
        <p>
          <strong>Frete:</strong> {formatMoney(nfeInfo.impostos.frete)}
        </p>
        <p>
          <strong>Desconto:</strong> {formatMoney(nfeInfo.impostos.desconto)}
        </p>
      </SectionContainer>
    )}
  </>
);

export default function ModalXML({ closeModal, nfeData }) {
  const [nfeInfo, setNfeInfo] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [pdfUrl, setPdfUrl] = useState(null);
  const { token } = useAuth();
  const theme = useTheme(); // Acessa o tema atual
  const themeType = theme.type; // Acesse o tipo do tema para o Toastify
  const oppositeTheme = themeType === "dark" ? "light" : "dark"; // Inverter o tema

  useEffect(() => {
    const fetchNfeData = async () => {
      if (!nfeData?.chave) {
        setError("Chave NFe não fornecida.");
        setLoading(false);
        return;
      }

      try {
        const projetoData = JSON.parse(localStorage.getItem("projeto") || "{}");
        const projetoId = projetoData._id || projetoData;

        const response = await axios.get(
          `${process.env.REACT_APP_API}/report/${nfeData.chave}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              projeto: projetoId,
            },
          }
        );

        if (response.data?.xml) {
          setNfeInfo(response.data.xml);
        } else {
          setError("Dados da nota fiscal não encontrados.");
        }
      } catch (err) {
        console.error("Erro ao buscar a nota fiscal:", err);
        setError(
          err.response?.status === 404
            ? "Nota fiscal não encontrada."
            : "Erro ao carregar os dados da nota fiscal."
        );
      } finally {
        setLoading(false);
      }
    };

    fetchNfeData();
  }, [nfeData, token]);

  // Função para download do PDF
  const handleDownloadPDF = async () => {
    if (!token) {
      toast.error("Usuário não autenticado. Por favor, faça login novamente.");
      return;
    }

    try {
      const projetoData = JSON.parse(localStorage.getItem("projeto") || "{}");
      const projetoId = projetoData._id || projetoData;

      const nfeKey = nfeInfo?.chv || nfeInfo?.chave;
      if (!nfeKey) {
        toast.error("Chave da NFe não encontrada.");
        return;
      }

      const apiUrl = `${process.env.REACT_APP_API}/report/danfe`;

      toast.info("Iniciando download...");

      const response = await axios.post(
        apiUrl,
        { nfe: nfeKey },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            projeto: projetoId,
          },
          responseType: "arraybuffer",
        }
      );

      if (response.status === 200) {
        const pdfBlob = new Blob([response.data], { type: "application/pdf" });
        saveAs(pdfBlob, `${nfeKey}.pdf`); // Usar saveAs para forçar o download
        toast.success("Download realizado com sucesso!");
      } else {
        const errorMessage =
          response.status === 404
            ? "DANFE não encontrada."
            : "Erro ao gerar DANFE.";
        toast.error(errorMessage);
      }
    } catch (error) {
      console.error("Erro ao baixar DANFE:", error);
      toast.error(
        error.response?.status === 404
          ? "DANFE não encontrada."
          : "Erro ao baixar DANFE."
      );
    }
  };

  const handleCloseAlert = () => {
    if (error === "Nota fiscal não encontrada.") {
      closeModal();
    }
    setError("");
  };

  const handleViewPDF = async () => {
    if (!token) {
      toast.error("Usuário não autenticado. Por favor, faça login novamente.");
      return;
    }

    try {
      const projetoData = JSON.parse(localStorage.getItem("projeto") || "{}");
      const projetoId = projetoData._id || projetoData;

      const nfeKey = nfeInfo?.chv || nfeInfo?.chave;
      if (!nfeKey) {
        toast.error("Chave da NFe não encontrada.");
        return;
      }

      const apiUrl = `${process.env.REACT_APP_API}/report/view-danfe`;

      toast.info("Carregando DANFE...");

      const response = await axios.post(
        apiUrl,
        { nfe: nfeKey },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            projeto: projetoId,
          },
          responseType: "arraybuffer",
        }
      );

      if (response.status === 200) {
        const pdfBlob = new Blob([response.data], { type: "application/pdf" });
        const url = URL.createObjectURL(pdfBlob);
        setPdfUrl(url);
        toast.success("DANFE carregada com sucesso!");
      } else {
        const errorMessage =
          response.status === 404
            ? "DANFE não encontrada."
            : "Erro ao gerar DANFE.";
        toast.error(errorMessage);
      }
    } catch (error) {
      console.error("Erro ao gerar DANFE:", error);
      toast.error("Erro ao gerar DANFE.");
    }
  };

  // Função para download do XML
  const handleDownloadXML = async () => {
    try {
      const projetoId = JSON.parse(localStorage.getItem("projeto") || "{}")._id;

      const response = await axios.get(
        `${process.env.REACT_APP_API}/report/download/${nfeData.chave}`,
        {
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${token}`,
            projeto: projetoId,
          },
        }
      );

      const blob = new Blob([response.data], { type: "application/xml" });
      saveAs(blob, `${nfeData.chave}.xml`);
      toast.success("Download realizado com sucesso!");
    } catch (error) {
      console.error("Erro ao baixar o XML:", error);
      toast.error(
        error.response?.status === 404
          ? "Arquivo XML não encontrado."
          : "Erro ao baixar o XML."
      );
    }
  };

  // Função para download do PDF
  const handleClosePdfModal = () => {
    setPdfUrl(null);
    // Limpar o URL do objeto quando fechar o modal
    if (pdfUrl) {
      URL.revokeObjectURL(pdfUrl);
    }
  };

  if (loading) {
    return (
      <>
        <Overlay />
        <ModalContainer>
          <Header>
            <h2>CARREGANDO...</h2>
          </Header>
        </ModalContainer>
      </>
    );
  }

  return (
    <>
      <Overlay />
      <ModalContainer>
        <Header>
          <h2>RESUMO DE DADOS</h2>
          <CloseButton onClick={closeModal}>
            <CgCloseO />
          </CloseButton>
        </Header>
        <Body>
          {error ? (
            <ErrorMessage message={error} onClose={handleCloseAlert} />
          ) : (
            nfeInfo && <XML nfeData={nfeInfo} />
          )}
        </Body>
        {!error && nfeInfo && (
          <Footer>
            <button onClick={handleViewPDF} title="Visualizar DANFE em modal">
              <GrDocumentPdf />
              <span>VISUALIZAR DANFE</span>{" "}
            </button>
            <button onClick={handleDownloadPDF} title="Baixar DANFE em PDF">
              <GrDocumentPdf />
              <span>BAIXAR PDF</span>
            </button>
            <button onClick={handleDownloadXML}>
              <IoCodeDownload />
              <span>BAIXAR XML</span>
            </button>
          </Footer>
        )}
      </ModalContainer>
      {pdfUrl && (
        <PdfViewerModal pdfUrl={pdfUrl} onClose={handleClosePdfModal} />
      )}
    </>
  );
}
