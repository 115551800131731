import styled from "styled-components";

// Barra principal do header
export const Bar = styled.div`
  width: 100vw;
  min-height: 5em;
  display: flex;
  align-items: center;
  background-color: rgba(13, 13, 17);
  position: fixed;
  justify-content: space-between;
  overflow: visible;
  z-index: 9;
  user-select: none;
  color: white;

  img {
    height: 1.5em;
    margin-left: 1em;
    opacity: 0.8;
  }

  .side {
    width: max-content;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .theme {
      width: 6em;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        margin: 10px;
        cursor: pointer;
      }

      .toggle {
        width: 2em;
        height: 0.8em;
        display: flex;
        border-radius: 50px;
        background-color: rgba(0, 0, 0, 0.3);
        border: 1px solid rgba(255, 255, 255, 0.2);
        align-items: center;
        position: relative;
        transition: all 0.1s;

        &.dark {
          justify-content: flex-end;
        }

        .circle {
          width: 0.7em;
          height: 0.7em;
          display: flex;
          border-radius: 100%;
          background-color: rgba(255, 255, 255, 0.2);
          border: 1px solid rgba(0, 0, 0, 0.3);
          cursor: pointer;
        }
      }
    }

    .btn {
      width: 2em;
      height: 2em;
      border: 1px solid rgba(255, 255, 255, 0.05);
      margin-left: 1em;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0.5;
      cursor: pointer;
      transition: all 0.2s;


      &.big {
        border: none;

        &:hover {
          opacity: 1;
        }
        svg {
          font-size: 1.2em;
        }
      }

      &:hover {
        opacity: 1;
      }
    }

    .perfil {
      width: 4em;
      height: 4em;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 1em;
      cursor: pointer;

      .photo {
        width: 2.5em;
        height: 2.5em;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: #4caf50; // Cor de fundo ajustada para o círculo do perfil
        color: white;
        font-size: 1.2em;
        font-weight: bold;
      }
    }

    .infos {
      width: max-content;
      display: flex;
      padding-right: 20px;
      height: 100%;
      align-items: center;

      .info {
        width: max-content;
        border-right: rgba(255, 255, 255, 0.2);
        height: 2em;
        display: flex;
        align-items: center;
        margin-right: 1em;

        svg {
          margin-right: 10px;
          opacity: 0.5;
        }

        p {
          font-size: 0.8em;
          opacity: 0.6;
        }
      }
    }

    .search {
      width: max-content;
      height: 100%;
      display: flex;
      align-items: center;

      .ico {
        width: 2em;
        height: 100%;
        display: flex;
        align-items: center;
        transform: translateX(2.7em);
        font-size: 0.9em;
        opacity: 0.5;
      }

      input {
        background-color: rgba(22, 27, 34);
        border: none;
        outline: none;
        height: 1.7em;
        width: 30em;
        border-radius: 50px;
        border: 1px solid rgba(255, 255, 255, 0.05);
        font-size: 0.9em;
        padding-left: 35px;
      }
    }

    .projeto {
      width: 20em;
      display: flex;
      height: 100%;
      margin-left: 1em;
      position: relative;

      .thumb {
        height: 3em;
        width: 2.5em;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .btn {
        height: 3em;
        width: 2.5em;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: transform 0.2s;

        &:hover {
          opacity: 1;
        }
      }

      &:hover .btn {
        transform: rotate(180deg);
      }

      .text {
        width: calc(100% - 5em);
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;

        h2 {
          font-size: 0.8em;
          opacity: 0.7;
          font-weight: 500;
        }

        p {
          font-size: 0.75em;
          opacity: 0.5;
          margin-top: 5px;
        }
      }
    }
  }
`;

// Dropdown do projeto
export const ProjectDropdown = styled.div`
  position: fixed;
  width: 200px;
  background-color: rgba(22, 27, 34, 0.95);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  overflow: hidden;
  z-index: 1001;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      padding: 10px 15px;
      cursor: pointer;
      transition: background-color 0.2s;
      color: white;

      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }

      &.new-project {
        display: flex;
        align-items: center;
        color: #4caf50;

        svg {
          margin-right: 5px;
        }
      }
    }
  }
`;

// Modal Wrapper - contêiner para o modal
export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
`;

// Modal Content - conteúdo interno do modal
export const ModalContent = styled.div`
  background: #05070a;
  padding: 20px;
  border-radius: 21px;
  border: 0.1px solid #505153;
  width: 350px;
  max-height: 80vh;
  color: white;
`;
// Estilo do contêiner que envolve o ícone e o texto
export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  align-items: center;
  width: 100%;
  .user,
  .mail {
    margin-right: 10px; /* Ajusta a distância entre o ícone e o texto */
  }
`;

// Estilo para o ícone
export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-right: 10px; /* Espaçamento entre o ícone e o texto */
  font-size: 1em; /* Ajuste o tamanho conforme necessário */
  color: #afafaf;
`;

// Estilo para o nome e e-mail
export const InfoUser = styled.div`
  display: flex;

  font-size: 1em;
  font-weight: normal;
  opacity: 0.7;
  color: white;
`;

// Estilo para o botão de Fechar
export const CloseButton = styled.button`
  background: none;
  border: none;
  color: #c21b0c;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    color: #8f150a;
  }
`;

// Estilo para o botão de Logout
export const LogoutButton = styled.button`
  background: none;
  border: none;
  color: #4eb959;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 10px;
  margin-right: 240px;

  &:hover {
    color: #12801d;
  }
`;
