import { IoClose, IoSearch, IoArrowBack } from "react-icons/io5";
import { Card, Container } from "../../styles";
import { AiOutlineCloudDownload } from "react-icons/ai";
import { MdOutlineBugReport } from "react-icons/md";
import { PiMicrosoftExcelLogoLight } from "react-icons/pi";

import { RiToolsFill } from "react-icons/ri";

export default function List({ setStatus, handleClose }) {
  return (
    <Card>
      <div className="header">
        <RiToolsFill />
        <p>Ferramentas</p>
        <div className="btn" onClick={handleClose}>
          <IoClose />
        </div>
      </div>
      <div className="display">
        <div className="item" onClick={() => setStatus("revisor")}>
          <div className="thumb">
            <MdOutlineBugReport />
          </div>
          <div className="content">
            <p>Relatório de Escrituração</p>
            <p>
              O algoritmo lê arquivos XML de Notas Fiscais Eletrônicas de um
              diretório específico, convertendo-os em objetos JSON estruturados
              através de um extrator personalizado. Ele extrai informações
              cruciais como dados do emitente, destinatário, valores e itens da
              nota.
            </p>
          </div>
        </div>
        <div className="item" onClick={() => setStatus("download_xml")}>
          <div className="thumb">
            <AiOutlineCloudDownload />
          </div>
          <div className="content">
            <p>Download XML's</p>
            <p>
              O algoritmo lê arquivos .txt com chaves de XML's, encontra os
              arquivos no banco de dados e os disponibiliza para download.
            </p>
          </div>
        </div>
        <div className="item deaf" onClick={() => setStatus("excel")}>
          <div className="thumb">
            <PiMicrosoftExcelLogoLight />
          </div>
          <div className="content">
            <p>Converter para Excel</p>
            <p>
              O algoritmo lê arquivos .txt ou .zip com xmls e extrai os dados
              dos arquivos para xlsx.
            </p>
          </div>
        </div>
      </div>
    </Card>
  );
}
