import styled from "styled-components";

export const ModalContainer = styled.div`
  position: fixed;
  bottom: 1rem; // Mudado para bottom
  right: 1rem; // Mudado para right
  z-index: 1000000;
  width: 100%;
  max-width: 300px;
  background: ${({ theme }) => theme.custom2};
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  border: 1px solid ${({ theme }) => theme.border5};
  animation: fadeIn 0.2s ease-in-out;

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

export const ModalContent = styled.div`
  padding: 1rem;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.75rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.border5};
`;

export const Title = styled.h3`
  font-size: 0.875rem;
  font-weight: 600;
  color: ${({ theme }) => theme.text || "#fff"};
`;

export const ClearButton = styled.button`
  color: ${({ theme }) => theme.text || "#fff"};
  opacity: 0.7;
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
  border-radius: 4px;

  &:hover {
    opacity: 1;
    background: ${({ theme }) => theme.card};
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

export const FiltersList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const FilterItem = styled.div`
  display: flex;
  align-items: center;
  padding: 0.375rem 0.5rem;
  background: ${({ theme }) => theme.card};
  border-radius: 6px;
  font-size: 0.75rem;
`;

export const FilterLabel = styled.span`
  font-weight: 500;
  color: ${({ theme }) => theme.text || "#fff"};
  opacity: 0.7;
  min-width: 85px;
`;

export const FilterValue = styled.span`
  color: ${({ theme }) => theme.text || "#fff"};
  font-weight: 500;
`;
export const TotalCount = styled.div`
  padding: 0.5rem;
  background: ${({ theme }) => theme.card};
  border-radius: 6px;
  margin-top: 0.75rem;
  border-top: 1px solid ${({ theme }) => theme.border5};
`;

export const TotalLabel = styled.span`
  font-size: 0.75rem;
  font-weight: 500;
  color: ${({ theme }) => theme.text || "#fff"};
  opacity: 0.7;
`;

export const TotalValue = styled.span`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.text || "#fff"};
  font-weight: 600;
  margin-left: 0.5rem;
`;

export const DownloadButton = styled.button`
  background-color: ${({ theme }) => theme.custom};
  opacity: 0.6;
  color: ${({ theme }) => theme.text};
  border: 1px solid ${({ theme }) => theme.border3};
  padding: 9px 24px; /* Espaçamento interno */
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s, border-color 0.3s, box-shadow 0.3s;
  width: 100%;
  margin-top: 0.5rem; /* Espaçamento entre os botões */

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 1;
    border-color: ${({ theme }) =>
      theme.border7}; /* Borda verde suave no hover */
    box-shadow: 0 0 0 3px rgba(118, 199, 118, 0.5); /* Sombra suave ao passar o mouse */
  }

  &:focus {
    outline: none;

    border-color: ${({ theme }) => theme.border7}; /* Borda verde ao clicar */
    opacity: 1;
  }
  &[data-loading="true"] {
    background-color: #dc3545 !important;
    opacity: 1 !important;
    color: white !important;
    border: none !important;

    &:hover {
      opacity: 0.9 !important;
      box-shadow: 0 0 0 3px rgba(220, 53, 69, 0.5) !important;
    }
  }
`;
export const LoadingDots = styled.span`
  display: inline-block;
  margin-left: 4px;
  animation: loadingDots 1.5s infinite;

  @keyframes loadingDots {
    0%,
    20% {
      content: ".";
    }
    40% {
      content: "..";
    }
    60% {
      content: "...";
    }
    80%,
    100% {
      content: "";
    }
  }
`;
