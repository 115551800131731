import styled from "styled-components";

export const Container = styled.div`
  width: 100vw;
  height: calc(100vh - 3em + 1px);
  margin-top: 3em;
  position: fixed;
  border-right: 1px solid ${({ theme }) => theme.load};
  display: flex;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.3);

`;

export const Bar = styled.div`
  width: 15em;
  height: 100vh;
  background-color: ${({ theme }) => theme.header};
  border-right: 1px solid ${({ theme }) => theme.contrast};
  margin-top: 4.1em;
`;

export const Button = styled.div`
  width: 100vw;
  height: 2.5em;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.contrast};
  opacity: 0.6;
  cursor: pointer;
  transition: all 0.2s;
  top: 0;
  left: 0;

  p {
    font-size: 0.85em;
    margin-left: 10px;
  }

  &:hover,
  &.ativ {
    opacity: 1;
  }

  &.ativ {
    border-left: 3px solid rgba(0, 100, 200);
  }

  svg {
    margin-left: 1em;
  }
`;
export const Blur = styled.div`
  width: calc(100vw - 15em);
  height: calc(100vh);
  display: flex;
  z-index: 7;
  margin-left: 15em;
`;
