import {
  IoArrowBack,
  IoTrash,
  IoReload,
  IoCheckmark,
  IoCubeOutline,
  IoCloudDownloadOutline,
} from "react-icons/io5";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { BsCpu } from "react-icons/bs";
import { SiMicrosoftexcel } from "react-icons/si";
import { Card } from "../../../styles";
import { TbCloudDownload } from "react-icons/tb";
import { PiFilesDuotone } from "react-icons/pi";
import { LuDatabase } from "react-icons/lu";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { IoCodeSlashOutline } from "react-icons/io5";
import { useAuth } from "@hooks/auth";

export default function Download({ setStatus }) {
  const { projeto, token } = useAuth();
  const [files, setFiles] = useState([]);
  const [isProcessingTasks, setIsProcessingTasks] = useState(false);
  const [downloadCompleted, setDownloadCompleted] = useState(false);
  const [dadosJsonData, setDadosJsonData] = useState(null);
  const fileInputRef = useRef(null);
  const [wsInstance, setWsInstance] = useState(null);
  const [tasks, setTasks] = useState([
    {
      id: 1,
      name: "Enviando arquivos",
      progress: 0,
      status: "pending",
      icon: <AiOutlineCloudUpload />,
    },
    {
      id: 2,
      name: "Processando XMLs",
      progress: 0,
      status: "pending",
      icon: <BsCpu />,
    },
    {
      id: 3,
      name: "Criando ZIP",
      progress: 0,
      status: "pending",
      icon: <IoCubeOutline />,
    },
    {
      id: 4,
      name: "Gerando relatório",
      progress: 0,
      status: "pending",
      icon: <SiMicrosoftexcel />,
    },
    {
      id: 5,
      name: "Baixando arquivos",
      progress: 0,
      status: "pending",
      icon: <IoCloudDownloadOutline />,
    },
  ]);

  const handleFileSelect = (event) => {
    const selectedFiles = Array.from(event.target.files)
      .filter((file) => file.name.toLowerCase().endsWith(".txt"))
      .map((file) => ({
        file,
        status: "pending",
        progress: 0,
      }));
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const newFiles = Array.from(event.dataTransfer.files)
      .filter((file) => file.name.toLowerCase().endsWith(".txt"))
      .map((file) => ({
        file,
        status: "pending",
        progress: 0,
      }));
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleOpenFileDialog = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleRemoveFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleSend = async () => {
    if (!token) {
      toast.error("Usuário não autenticado. Por favor, faça login novamente.");
      return;
    }

    if (files.length === 0) {
      toast.error("Nenhum arquivo selecionado para enviar.");
      return;
    }

    setIsProcessingTasks(true);

    const formData = new FormData();
    files.forEach((fileData) => {
      formData.append("files", fileData.file);
    });

    try {
      await axios.post(
        `${process.env.REACT_APP_API}/tools/download-xml`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            projeto: projeto._id,
          },
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setTasks((prevTasks) =>
              prevTasks.map((task) =>
                task.id === 1
                  ? { ...task, progress, status: "in-progress" }
                  : task
              )
            );
          },
        }
      );

      setTasks((prevTasks) =>
        prevTasks.map((task) =>
          task.id === 1 ? { ...task, progress: 100, status: "completed" } : task
        )
      );

      const ws = new WebSocket("wss://log.vidal-app.com/ws");
      setWsInstance(ws);

      ws.onopen = () => {
        ws.send(
          JSON.stringify({
            action: "subscribe",
            projectId: projeto._id,
            type: "download_xml",
          })
        );
      };

      ws.onmessage = (event) => {
        const messageData = JSON.parse(event.data);
        if (messageData.success && messageData.message) {
          const msg = messageData.message;
          const { type, projectId, data } = msg;

          if (
            projectId &&
            projectId === projeto._id &&
            type === "download_xml"
          ) {
            if (
              data &&
              data.progress !== undefined &&
              data.stage !== undefined
            ) {
              const { progress, stage } = data;

              setTasks((prevTasks) =>
                prevTasks.map((task) => {
                  if (data.stage.includes("Iniciando processamento de XMLs.")) {
                    if (task.name === "Processando XMLs") {
                      return {
                        ...task,
                        status: "in-progress",
                        progress: 10,
                      };
                    }
                  } else if (
                    data.stage.includes("Processando") &&
                    data.progress <= 50
                  ) {
                    if (task.name === "Processando XMLs") {
                      return {
                        ...task,
                        status: "in-progress",
                        progress: data.progress,
                      };
                    }
                  } else if (
                    data.stage.includes("Verificação de arquivos concluída.")
                  ) {
                    if (task.name === "Processando XMLs") {
                      return {
                        ...task,
                        status: "completed",
                        progress: 100,
                      };
                    }
                  } else if (
                    data.stage.includes("Criando ZIP") ||
                    data.stage.includes("Lista de chaves faltantes")
                  ) {
                    if (task.name === "Criando ZIP") {
                      return {
                        ...task,
                        status: "in-progress",
                        progress: data.progress,
                      };
                    }
                  } else if (data.stage.includes("Processamento concluído")) {
                    if (task.name === "Criando ZIP") {
                      return {
                        ...task,
                        status: "completed",
                        progress: 100,
                      };
                    }
                  } else if (
                    data.stage.includes(
                      "Dados estatísticos agregados e salvos."
                    ) ||
                    data.stage.includes("Arquivo ZIP agregado")
                  ) {
                    if (task.name === "Gerando relatório") {
                      return {
                        ...task,
                        status: "in-progress",
                        progress: data.progress,
                      };
                    }
                  } else if (
                    data.stage.includes(
                      "Processamento de todos os arquivos concluído."
                    )
                  ) {
                    if (task.name === "Gerando relatório") {
                      return {
                        ...task,
                        status: "completed",
                        progress: 100,
                      };
                    }
                    // Iniciar a tarefa de download
                    if (task.name === "Baixando arquivos") {
                      return {
                        ...task,
                        status: "in-progress",
                        progress: 0,
                      };
                    }
                  }
                  return task;
                })
              );

              // Quando o processamento é concluído, iniciar o download
              if (
                data.stage.includes(
                  "Processamento de todos os arquivos concluído."
                )
              ) {
                ws.close();
                setDownloadCompleted(true);
                fetchDadosJson();
                // Iniciar o download do arquivo ZIP
                downloadZip();
              }
            } else if (data && data.message) {
              const errorMessage = data.message;
              console.error(`Erro: ${errorMessage}`);
              toast.error(errorMessage);
            }
          }
        }
      };

      ws.onerror = (event) => {
        console.error("WebSocket error:", event);
        toast.error("Erro no processamento dos arquivos.");
      };

      ws.onclose = () => {
        console.log("WebSocket connection closed");
      };
    } catch (error) {
      console.error("Erro no upload:", error);
      toast.error(
        error.response?.data?.message || "Erro no envio dos arquivos"
      );
      setIsProcessingTasks(false);
    }
  };

  const fetchDadosJson = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/tools/data-json`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            projeto: projeto._id,
          },
        }
      );
      setDadosJsonData(response.data);
    } catch (error) {
      console.error("Erro ao buscar dados.json:", error);
      toast.error(
        "Erro ao buscar dados estatísticos. Tente novamente mais tarde."
      );
    }
  };

  const downloadZip = async () => {
    const fileName = null; // Deixe como null para baixar o arquivo padrão
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/tools/download-zip`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            projeto: projeto._id,
          },
          params: { fileName },
          responseType: "blob",
          onDownloadProgress: (progressEvent) => {
            const totalLength = progressEvent.total;
            if (totalLength !== null) {
              const progress = Math.round(
                (progressEvent.loaded * 100) / totalLength
              );
              setTasks((prevTasks) =>
                prevTasks.map((task) =>
                  task.name === "Baixando arquivos"
                    ? { ...task, progress, status: "in-progress" }
                    : task
                )
              );
            }
          },
        }
      );

      const contentDisposition = response.headers["content-disposition"];
      let fileDownloadName = "download.zip";
      if (contentDisposition) {
        const match = contentDisposition.match(/filename="?(.+)"?/);
        if (match && match[1]) {
          fileDownloadName = match[1];
        }
      }

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileDownloadName);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);

      // Atualizar a tarefa para concluída
      setTasks((prevTasks) =>
        prevTasks.map((task) =>
          task.name === "Baixando arquivos"
            ? { ...task, status: "completed", progress: 100 }
            : task
        )
      );

      toast.success("Download do arquivo ZIP concluído.");
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Erro de autenticação. Por favor, faça login novamente.");
      } else if (error.response && error.response.status === 404) {
        toast.error("Arquivo ZIP não encontrado no servidor.");
      } else {
        toast.error(
          `Erro ao baixar o arquivo ZIP. Tente novamente mais tarde.`
        );
      }
      console.error(`Erro ao baixar o arquivo ZIP:`, error);
    }
  };

  useEffect(() => {
    return () => {
      if (wsInstance) {
        wsInstance.close();
      }
    };
  }, [wsInstance]);

  return (
    <Card>
      <div className="header">
        <div className="btn" onClick={() => setStatus("list")}>
          <IoArrowBack />
        </div>
        <IoCodeSlashOutline />
        <p>Executar script</p>
      </div>

      <div className="display">
        <div className="item ativ">
          <div className="thumb">
            <TbCloudDownload />
          </div>
          <div className="content">
            <p>Download XML's</p>
            <p>
              O algoritmo lê arquivos .txt com chaves de XML's, encontra os
              arquivos no banco de dados e os disponibiliza para download.
            </p>
          </div>
        </div>

        {!isProcessingTasks && !downloadCompleted ? (
          <>
            {files.length > 0 && (
              <div className="tag">
                <div className="info">
                  <PiFilesDuotone />
                  <p>{files.length}</p>
                </div>
                <div className="info">
                  <LuDatabase />
                  <p>
                    {(
                      files.reduce((acc, file) => acc + file.file.size, 0) /
                      (1024 * 1024)
                    ).toFixed(2)}{" "}
                    MB
                  </p>
                </div>
                <div className="btn" onClick={() => setFiles([])}>
                  <IoReload />
                </div>
              </div>
            )}

            <div
              className="drop"
              onClick={handleOpenFileDialog}
              onDrop={handleDrop}
              onDragOver={(e) => e.preventDefault()}
            >
              <div className="drop-area">
                <input
                  ref={fileInputRef}
                  type="file"
                  accept=".txt"
                  multiple
                  style={{ display: "none" }}
                  onChange={handleFileSelect}
                />
                <div className="content">
                  <div className="thumb">
                    <p>+</p>
                  </div>
                  <div className="text">
                    <p>Carregar arquivos</p>
                    <p>
                      Arraste para cá a lista .txt com as chaves dos XMLs que
                      deseja baixar ou clique para selecionar
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {files.length > 0 && (
              <div className="files">
                {files.map((fileData, index) => (
                  <div className="file" key={index}>
                    <div className="thumb">
                      {fileData.status === "uploading" && (
                        <div className="spinner blue" />
                      )}
                      {fileData.status === "completed" && <IoCheckmark />}
                      {fileData.status === "pending" && <IoCubeOutline />}
                    </div>
                    <div className="content">
                      <p>{fileData.file.name}</p>
                      <p className="size">
                        {(fileData.file.size / 1024).toFixed(2)} Kb
                      </p>
                      <IoTrash onClick={() => handleRemoveFile(index)} />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </>
        ) : isProcessingTasks ? (
          <div className="files">
            {tasks.map((task) => (
              <div className="file" key={task.id}>
                <div className="thumb">
                  {task.status === "in-progress" ? (
                    <div className="spinner blue" />
                  ) : task.status === "completed" ? (
                    <IoCheckmark />
                  ) : (
                    task.icon
                  )}
                </div>
                <div className="content">
                  <div className="text">
                    <p>{task.name}</p>
                    <p>{task.progress}%</p>
                  </div>
                  <div className="load">
                    <div
                      className="progress"
                      style={{ width: `${task.progress}%` }}
                    ></div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : downloadCompleted ? (
          <div className="completed">
            <div className="header">
              <IoCheckmark color="#4eb959" size={24} />
              <span
                style={{
                  fontSize: "18px",
                  marginLeft: "8px",
                  color: "#4eb959",
                }}
              >
                Concluído
              </span>
            </div>
            <div className="info">
              <p>Arquivos processados:</p>
              {files.map((fileData, index) => (
                <div key={index}>
                  <span>{fileData.file.name}</span> -{" "}
                  <span>{(fileData.file.size / 1024).toFixed(2)} KB</span>
                </div>
              ))}
              {dadosJsonData && (
                <>
                  <p>
                    Total de notas fiscais enviadas:{" "}
                    {dadosJsonData.nfes_enviadas}
                  </p>
                  <p>
                    Arquivos encontrados: {dadosJsonData.arquivos_encontrados}
                  </p>
                  <p>Arquivos faltantes: {dadosJsonData.arquivos_faltantes}</p>
                </>
              )}
            </div>
            <div className="buttons">
              <div className="btn" onClick={() => setStatus("list")}>
                <p>Fechar</p>
              </div>
            </div>
          </div>
        ) : null}
      </div>

      <div className="bottom">
        {!isProcessingTasks && !downloadCompleted && (
          <div className="btn" onClick={handleSend}>
            <p>EXECUTAR</p>
          </div>
        )}
      </div>
    </Card>
  );
}
