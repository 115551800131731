import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { PiFileTxtDuotone } from "react-icons/pi";
import "react-toastify/dist/ReactToastify.css";
import {
  ModalOverlay,
  ModalContent,
  SendButton,
  DropzoneContainer,
  UploadIcon,
  MessageWrapper,
  Message,
  SubMessage,
  FileList,
  FileItem,
  DeleteButton,
} from "./styles";
import { FiTrash } from "react-icons/fi";
import { MdClose } from "react-icons/md";

const ListCreation = ({ isOpen, onClose, onCreateList }) => {
  // Adicionar `onCreateList`
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [listName, setListName] = useState("");
  const [loading, setLoading] = useState(false);

  const onDrop = (acceptedFiles) => {
    const filesWithPreview = acceptedFiles.map((file) => ({
      file, // Mantenha o arquivo original para acessar o nome
      preview: URL.createObjectURL(file),
      sizeInKB: (file.size / 1024).toFixed(2), // Converta o tamanho para KB
    }));
    setUploadedFiles((prevFiles) => [...prevFiles, ...filesWithPreview]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ".txt",
  });

  const removeFile = (fileName) => {
    setUploadedFiles((prevFiles) =>
      prevFiles.filter((file) => file.file.name !== fileName)
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!listName || uploadedFiles.length === 0) {
      toast.error(
        "Por favor, insira um nome para a lista e adicione os arquivos."
      );
      return;
    }

    const newList = {
      name: listName,
      files: uploadedFiles.map((fileData) => ({
        name: fileData.file.name,
        size: fileData.sizeInKB,
      })),
    };

    // Passar a nova lista para o componente pai através de `onCreateList`
    onCreateList(newList);

    // Limpar o modal
    setUploadedFiles([]);
    setListName("");
    onClose(); // Fechar o modal
  };

  if (!isOpen) return null; // Retorna null se o modal não estiver aberto

  return (
    <ModalOverlay onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            position: "relative",
          }}
        >
          <h2
            style={{ fontSize: "16px", fontWeight: "normal", color: "#505153" }}
          >
            NOVA LISTA
          </h2>
          <MdClose
            onClick={onClose}
            style={{
              cursor: "pointer",
              fontSize: "24px",
              color: "#3F3E3E",
              position: "absolute",
              right: "10px",
            }}
          />
        </div>

        <hr
          style={{
            margin: "10px 0",
            borderColor: "#1D1D1D",
            borderWidth: "1px",
            width: "120%",
            position: "relative",
            transform: "translateX(-8%)",
          }}
        />

        <input
          type="text"
          value={listName}
          onChange={(e) => setListName(e.target.value)}
          placeholder=" Nome da Lista"
          required
          style={{
            backgroundColor: "#050505",
            border: "1px solid #3F3E3E",
            color: "#fff",
            padding: "0px",
            width: "100%",
            marginBottom: "20px",
            marginTop: "13px",
            borderRadius: "9px",
          }}
        />

        <DropzoneContainer {...getRootProps()}>
          <input {...getInputProps()} />
          <UploadIcon>+</UploadIcon>
          <MessageWrapper>
            <Message>Carregar arquivo</Message>
            <SubMessage>Somente arquivos .txt</SubMessage>
          </MessageWrapper>
        </DropzoneContainer>

        {uploadedFiles.length > 0 && (
          <FileList>
            {uploadedFiles.map((fileData) =>
              fileData && fileData.file && fileData.file.name ? (
                <FileItem
                  key={fileData.file.name}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <PiFileTxtDuotone
                    style={{
                      width: "30px",
                      height: "17px",
                      marginRight: "10px",
                    }}
                  />
                  <MessageWrapper style={{ flexGrow: 1 }}>
                    <span>{fileData.file.name}</span>
                    <SubMessage>
                      <span>{fileData.sizeInKB} KB</span>
                    </SubMessage>
                  </MessageWrapper>
                  <DeleteButton onClick={() => removeFile(fileData.file.name)}>
                    <FiTrash
                      style={{
                        width: "30px",
                        height: "17px",
                        marginRight: "7px",
                        fill: "#0D0D11",
                      }}
                    />
                  </DeleteButton>
                </FileItem>
              ) : (
                <FileItem
                  key={Math.random()}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span>Arquivo inválido</span>
                </FileItem>
              )
            )}
          </FileList>
        )}

        <form onSubmit={handleSubmit}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <SendButton
              type="submit"
              disabled={loading}
              style={{
                backgroundColor: "#000",
                border: "1px solid #3F3E3E",
                color: "#fff",
                padding: "5px 45px",
                marginTop: "0px",
                marginBottom: "10px",
                cursor: "pointer",
                transition: "background-color 0.3s ease",
                fontWeight: "normal",
                borderRadius: "15px",
              }}
              onMouseOver={(e) => (e.target.style.backgroundColor = "#333")}
              onMouseOut={(e) => (e.target.style.backgroundColor = "#000")}
            >
              {loading ? "Enviando..." : "CRIAR"}
            </SendButton>
          </div>
        </form>
      </ModalContent>
    </ModalOverlay>
  );
};

export default ListCreation;
