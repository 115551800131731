// Auth.js
import React from "react";
import { Container, Card } from "./styles";
import logo from "@imgs/logo.svg";
import Login from "./components/login";

import Projetos from "./components/projetos";
import useLocalStorageState from "@hooks/useLocalStorage.js";
import { MdOutlineLogout } from "react-icons/md";

export default function Auth() {
  const [status, setStatus] = useLocalStorageState(
    "auth",
    "auth_status",
    "login"
  );
  const [token, setToken] = useLocalStorageState("auth", "auth_token", "");
  const [user, setUser] = useLocalStorageState("auth", "auth_user", "");

  const stages = [
    {
      status: "login",
      comp: (
        <Login
          setUser={setUser}
          user={user}
          status={status}
          setStatus={setStatus}
          token={token}
          setToken={setToken}
        />
      ),
    },
    {
      status: "projetos",
      comp: <Projetos status={status} setStatus={setStatus} user={user} />,
    },
    // Adicione outros estágios aqui se necessário
  ];
  const Stages = () => {
    const stage = stages.find((obj) => obj.status === status);
    return stage ? stage.comp : <></>;
  };

  return (
    <Container>
      <Card>
        <div className="header">
          <img src={logo} alt="Logo" />
          <div className="btn" onClick={() => setStatus("login")}>
            <MdOutlineLogout />
          </div>
        </div>
        <Stages />
      </Card>
    </Container>
  );
}
