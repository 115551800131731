import {
  IoArrowBack,
  IoReload,
  IoCheckmark,
  IoCloudDownloadOutline,
  IoTrash,
  IoCubeOutline,
  IoCodeSlashOutline,
} from "react-icons/io5";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { BsCpu } from "react-icons/bs";
import { SiMicrosoftexcel } from "react-icons/si";
import { TbCloudDownload } from "react-icons/tb";
import { PiFilesDuotone } from "react-icons/pi";
import { LuDatabase } from "react-icons/lu";
import { Card } from "../../../styles";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useAuth } from "@hooks/auth";

export default function Revisor({ setStatus }) {
  const { projeto, token } = useAuth();
  const [files, setFiles] = useState([]);
  const [isProcessingTasks, setIsProcessingTasks] = useState(false);
  const [tasks, setTasks] = useState([
    {
      id: 1,
      name: "Enviando arquivos",
      progress: 0,
      status: "pending",
      icon: <AiOutlineCloudUpload />,
    },
    {
      id: 2,
      name: "Processando arquivos",
      progress: 0,
      status: "pending",
      icon: <BsCpu />,
    },
    {
      id: 3,
      name: "Gerando relatórios",
      progress: 0,
      status: "pending",
      icon: <SiMicrosoftexcel />,
    },
    {
      id: 4,
      name: "Baixando arquivos",
      progress: 0,
      status: "pending",
      icon: <IoCloudDownloadOutline />,
    },
  ]);
  const fileInputRef = useRef(null);

  const handleFileSelect = (event) => {
    const newFiles = Array.from(event.target.files)
      .filter((file) => file.type === "text/plain")
      .map((file) => ({ file, status: "pending", progress: 0 }));
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const newFiles = Array.from(event.dataTransfer.files)
      .filter((file) => file.type === "text/plain")
      .map((file) => ({ file, status: "pending", progress: 0 }));
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleRemoveFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleSend = async () => {
    if (!token) {
      toast.error("Usuário não autenticado. Por favor, faça login novamente.");
      return;
    }

    if (files.length === 0) {
      toast.error("Nenhum arquivo selecionado para enviar.");
      return;
    }

    setIsProcessingTasks(true);
    const formData = new FormData();
    files.forEach((fileData) => formData.append("files", fileData.file));

    try {
      await axios.post(`${process.env.REACT_APP_API}/tools/revisor`, formData, {
        headers: { Authorization: `Bearer ${token}`, projeto: projeto._id },
      });

      setTasks((prevTasks) =>
        prevTasks.map((task) =>
          task.id === 1 ? { ...task, progress: 100, status: "completed" } : task
        )
      );

      const ws = new WebSocket("wss://log.vidal-app.com/ws");
      ws.onopen = () =>
        ws.send(
          JSON.stringify({
            action: "subscribe",
            projectId: projeto._id,
            type: "revisor",
          })
        );

      ws.onmessage = (event) => {
        const messageData = JSON.parse(event.data);
        const { type, projectId, data } = messageData.message || {};

        if (projectId === projeto._id && type === "revisor") {
          updateTaskProgress(data);
        }
      };

      ws.onerror = () => {
        toast.error("Erro no processamento dos arquivos.");
        setIsProcessingTasks(false);
      };
    } catch (error) {
      toast.error(
        error.response?.data?.message || "Erro no envio dos arquivos"
      );
      setIsProcessingTasks(false);
    }
  };

  const updateTaskProgress = (data) => {
    if (data.tasks) {
      setTasks((prevTasks) =>
        prevTasks.map((task) => {
          const updatedTask = data.tasks.find((t) => t.id === task.id);
          return updatedTask
            ? {
                ...task,
                progress: updatedTask.progress,
                status: updatedTask.status,
              }
            : task;
        })
      );
    }
  };

  useEffect(() => {
    const areFirstTasksCompleted = tasks
      .slice(0, 3)
      .every((task) => task.progress === 100);

    if (areFirstTasksCompleted && !tasks[3].progress) {
      downloadZipFile();
    }
  }, [tasks]);

  const downloadZipFile = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/tools/revisor/download`,
        {
          headers: { Authorization: `Bearer ${token}`, projeto: projeto._id },
          responseType: "blob",
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "relatorio_revisor.zip");
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
      resetComponent();
      setTasks((prevTasks) =>
        prevTasks.map((task) =>
          task.id === 4 ? { ...task, progress: 100, status: "completed" } : task
        )
      );

      toast.success("Download do relatório completo.");

    } catch (error) {
      toast.error("Erro ao baixar o arquivo ZIP.");
    }
  };

  const resetComponent = () => {
    setFiles([]);
    setTasks([
      {
        id: 1,
        name: "Enviando arquivos",
        progress: 0,
        status: "pending",
        icon: <AiOutlineCloudUpload />,
      },
      {
        id: 2,
        name: "Processando arquivos",
        progress: 0,
        status: "pending",
        icon: <BsCpu />,
      },
      {
        id: 3,
        name: "Gerando relatórios",
        progress: 0,
        status: "pending",
        icon: <SiMicrosoftexcel />,
      },
      {
        id: 4,
        name: "Baixando arquivos",
        progress: 0,
        status: "pending",
        icon: <IoCloudDownloadOutline />,
      },
    ]);
    setIsProcessingTasks(false);
  };

  return (
    <Card>
      <div className="header">
        <div className="btn" onClick={() => setStatus("list")}>
          <IoArrowBack />
        </div>
        <IoCodeSlashOutline />
        <p>Executar script</p>
      </div>

      <div className="display">
        <div className="item ativ">
          <div className="thumb">
            <TbCloudDownload />
          </div>
          <div className="content">
            <p>Relatório de Escrituração</p>
            <p>Processa arquivos XML e gera relatórios estruturados.</p>
          </div>
        </div>

        {!isProcessingTasks ? (
          <>
            {files.length > 0 && (
              <div className="tag">
                <div className="info">
                  <PiFilesDuotone />
                  <p>{files.length}</p>
                </div>
                <div className="info">
                  <LuDatabase />
                  <p>
                    {(
                      files.reduce((acc, file) => acc + file.file.size, 0) /
                      (1024 * 1024)
                    ).toFixed(2)}{" "}
                    MB
                  </p>
                </div>
                <div className="btn" onClick={() => setFiles([])}>
                  <IoReload />
                </div>
              </div>
            )}
            <div className="drop">
              <div
                className="drop-area"
                onDrop={handleDrop}
                onDragOver={(e) => e.preventDefault()}
                onClick={() => fileInputRef.current.click()}
              >
                <div className="content">
                  <div className="thumb">
                    <p>+</p>
                  </div>
                  <div className="text">
                    <p>Carregar arquivos</p>
                    <p>
                      Arraste para cá a lista .txt com as chaves dos XMLs que
                      deseja processar
                    </p>
                  </div>
                </div>
              </div>
              <input
                type="file"
                multiple
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileSelect}
              />
            </div>
            {files.length > 0 && (
              <div className="files">
                {files.map((fileData, index) => (
                  <div className="file" key={index}>
                    <div className="thumb">
                      {fileData.status === "uploading" && (
                        <div className="spinner" />
                      )}
                      {fileData.status === "completed" && <IoCheckmark />}
                      {fileData.status === "pending" && <IoCubeOutline />}
                    </div>
                    <div className="content">
                      <p>{fileData.file.name}</p>
                      <p className="size">
                        {(fileData.file.size / 1024).toFixed(2)} Kb
                      </p>
                      <IoTrash onClick={() => handleRemoveFile(index)} />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </>
        ) : (
          <div className="files">
            {tasks.map((task) => (
              <div className="file" key={task.id}>
                <div className="thumb">
                  {task.status === "in-progress" ? (
                    <div className="spinner" />
                  ) : (
                    task.icon
                  )}
                </div>
                <div className="content">
                  <div className="text">
                    <p>{task.name}</p>
                    <p>{task.progress}%</p>
                  </div>
                  <div className="load">
                    <div
                      className="progress"
                      style={{ width: `${task.progress}%` }}
                    ></div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="bottom">
        {!isProcessingTasks && (
          <div className="btn" onClick={handleSend}>
            <p>EXECUTAR</p>
          </div>
        )}
      </div>
    </Card>
  );
}
