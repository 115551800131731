import { useEffect, useState } from "react";
import axios from "axios";
import { Card, Container } from "./styles";


export default function PDF() {

 return (
  <Container id="pdf">
    <Card>
      <div></div>
      <div className="display">

      </div>
    </Card>
  </Container>
 )
}
