import React, { useState, useEffect } from "react";
import { Card, Display } from "./styles";
import { CiClock2 } from "react-icons/ci";
import { useAuth } from "@hooks/auth";
import { MdOutlineHistory } from "react-icons/md";
export default function Historico() {
  const [logs, setLogs] = useState([]);
  const { projeto } = useAuth();

  useEffect(() => {
    const ws = new WebSocket("wss://log.vidal-app.com/ws");

    ws.onopen = () => {
      console.log("Conectado ao servidor WebSocket");

      const requestMessage = {
        action: "getMessages",
        type: "process_nfes",
        projectId: projeto._id,
        page: 1,
        limit: 50, // Solicita os últimos 50 logs
      };

      ws.send(JSON.stringify(requestMessage));
      console.log("Requisição para buscar os últimos 50 logs enviada.");
    };

    ws.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        if (data.success && data.messages && data.messages.length > 0) {
          setLogs(data.messages);
        } else {
          console.error("Resposta inesperada do servidor:", data);
        }
      } catch (error) {
        console.error("Erro ao processar a mensagem recebida:", error);
      }
    };

    ws.onerror = (error) => {
      console.error("Erro de conexão:", error);
    };

    ws.onclose = () => {
      console.log("Conexão fechada");
    };

    return () => {
      ws.close();
    };
  }, [projeto._id]);

  if (logs.length === 0) {
    return (
      <Display>
        <div className="header">
          <div className="side">
            <MdOutlineHistory />

            <p>Histórico</p>
          </div>
        </div>
        <Card>
          <p>Nenhum log disponível.</p>
        </Card>
      </Display>
    );
  }

  return (
    <Display>
      <div className="header">
        <div className="side">
          <MdOutlineHistory />

          <p>Histórico</p>
        </div>
      </div>
      <div className="display">
        {logs.map((log, index) => (
          <Card key={index}>
            <div className="head">
              <CiClock2 />
              <p>{new Date(log.data).toLocaleString()}</p>
            </div>
            <div className="infos">
              <div className="items">
                <div className="item">
                  <p>Descrição: {log.desc}</p>
                </div>
                <div className="item">
                  <p>Arquivos: {Number(log.files).toLocaleString()}</p>
                </div>
                <div className="item">
                  <p>Relatórios: {Number(log.reports).toLocaleString()}</p>
                </div>
                <div className="item">
                  <p>Erros: {Number(log.erros).toLocaleString()}</p>
                </div>
              </div>
            </div>
          </Card>
        ))}
      </div>
    </Display>
  );
}
