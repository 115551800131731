import { IoClose } from "react-icons/io5";
import { Card, Container } from "./styles";
import { GoAlert } from "react-icons/go";

export default function Alert() {
  function handleClose() {
    document.getElementById("alert").style.display = "none";
  }

  

  return (
    <Container id="alert">
      <Card>
        <div className="header">
          <div className="btn" onClick={handleClose}>
            <IoClose />
          </div>
          <GoAlert />
          <p>Alerta</p>
        </div>
        <div className="content">
          <p>Tem certeza que deseja deletar este usuário?</p>
        </div>
        <div className="buttons">
          <div className="btn" onClick={handleClose}>
            <p>Cancelar</p>
          </div>
          <div className="btn delete" id="delete">
            <p>DELETAR</p>
          </div>
        </div>
      </Card>
    </Container>
  );
}
