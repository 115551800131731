import styled from "styled-components";

export const Container = styled.div`
  min-width: 100%;
  height: 15em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Box = styled.div`
  min-width: 100%;
  height: 10;
  display: flex;
  flex-direction: column;
  overflow: overlay;
`;

export const Header = styled.div`
  width: 100%;
  min-height: 2em;
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.border5};

  .thumb {
    min-width: 2em;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid ${({ theme }) => theme.border5};

    .circle {
      width: 1.5em;
      height: 1.5em;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid ${({ theme }) => theme.border5};
      border-radius: 100%;

      p {
        font-size: 0.7em;
      }
    }
  }

  .text {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 15px;

    p {
      font-size: 0.55em;
      opacity: 0.6;

      &:first-child {
        opacity: 0.7;
        font-size: 0.65em;
      }
    }
  }
`;

export const Search = styled.div`
  width: calc(100% - 2px);
  min-height: 1.5em;
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.border5};
  align-items: center;

  &.ativ {
    border: 1px solid rgba(200, 0, 0, 0.3);
  }
  input {
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.custom};
    border-radius: none;
    font-size: 0.65em;
    padding-left: 0;
    color: ${({ theme }) => theme.text};
    transform: translateY(-1px);
  }

  svg {
    font-size: 0.75em;
    margin: 0 10px;
    margin-right: 15px;
    opacity: 0.6;
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 8em;
  display: flex;
  flex-direction: column;
`;

export const Projeto = styled.div`
  width: 100%;
  min-height: 3em;
  border-bottom: 1px solid ${({ theme }) => theme.border5};
  cursor: pointer;
  
  .header {
    width: 100%;
    height: 3em;
    display: flex;

    .thumb {
      min-width: 2em;
      height: 100%;
      border-right: 1px solid ${({ theme }) => theme.border5};
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        font-size: 0.8em;
        opacity: 0.5;
        color: #238636;
      }
    }

    .text {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-left: 15px;
      p {
        width: 80%;
        font-size: 0.5em;
        margin-top: 5px;
        opacity: 0.6;

        &:first-child {
          opacity: 0.8;
          font-size: 0.5em;
        }
      }
    }
  }
`;
export const Send = styled.div`
  width: 100%;
  min-height: 4em;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Button = styled.div`
  width: max-content;
  height: 1.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #238636;
  border-radius: 5px;
  cursor: pointer;
  opacity: 0.9;
  transition: all 0.1s;

  &:hover {
    opacity: 1;
  }
  p {
    font-weight: 600;
    font-size: 0.65em;
    padding: 45px;
  }
`;
