import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  CloseButton,
  ExecutionContainer,
  Header,
  HeaderTitle,
  SectionTitle,
  FileInfo,
  FileRow,
  ProgressBarContainer,
  ProgressBar,
  StageInfo,
  CloseButtonAbsolute,
} from "./styles";
import { RiCodeFill } from "react-icons/ri";
import { CgSearch } from "react-icons/cg";
import { IoCloudDownloadOutline } from "react-icons/io5";
import { LuList } from "react-icons/lu";
import { GrDocumentZip } from "react-icons/gr";
import { RxFile } from "react-icons/rx";
import { toast } from "react-toastify";
import useWebSocket from "react-use-websocket";
import { useAuth } from "@hooks/auth";
import CompletedRevisor from "./CompletedRevisor";

const RevisorExecution = ({ files, onClose, uploadStartTime }) => {
  const { projeto } = useAuth();
  const [progress, setProgress] = useState(0);
  const [stage, setStage] = useState("");
  const [processCompleted, setProcessCompleted] = useState(false);
  const [notasNoSPED, setNotasNoSPED] = useState(0);
  const [notasProcessadas, setNotasProcessadas] = useState(0);
  const [resumoProcessamento, setResumoProcessamento] = useState([]);
  const [currentFile, setCurrentFile] = useState("");
  const [shouldClose, setShouldClose] = useState(false);
  const [zipPath, setZipPath] = useState(null); // Novo estado para armazenar o caminho do ZIP

  // useRef para rastrear arquivos já processados
  const processedFilesRef = useRef(new Set());

  const { sendMessage, lastMessage, closeConnection } = useWebSocket(
    "wss://log.vidal-app.com/ws",
    {
      shouldReconnect: () => !shouldClose, // Não reconecta se shouldClose for true
      onOpen: () => {
        console.log("Connected to WebSocket server");
        sendMessage(
          JSON.stringify({
            action: "subscribe",
            projectId: projeto._id,
            type: "revisor",
          })
        );
      },
      onError: (event) => {
        console.error("WebSocket error:", event);
        toast.error("Erro no processamento dos arquivos.");
      },
    }
  );

  // Função para limpar a conexão WebSocket
  const cleanupWebSocket = useCallback(() => {
    setShouldClose(true);
    if (closeConnection) {
      closeConnection();
    }
  }, [closeConnection]);

  const processMessage = useCallback(
    (msg) => {
      if (processCompleted) return; // Não processa novas mensagens se já estiver completo

      const { type, projectId, data } = msg;

      // Verifica timestamp
      const messageTimestamp = new Date(data.timestamp).getTime();
      if (messageTimestamp < uploadStartTime) {
        return;
      }

      if (projectId && projectId === projeto._id && type === "revisor") {
        if (data && data.progress !== undefined && data.stage !== undefined) {
          const { progress, stage } = data;

          if (data.file) {
            setCurrentFile(data.file);
          }

          setProgress(progress);
          setStage(stage);

          if (data.info) {
            const { notasNoSPED, notasEncontradas } = data.info;
            if (notasNoSPED !== undefined) {
              setNotasNoSPED((prev) => prev + notasNoSPED);
            }
            if (notasEncontradas !== undefined) {
              setNotasProcessadas((prev) => prev + notasEncontradas);
            }
          }

          // Quando um arquivo é concluído
          if (stage === "Processamento concluído" && data.file) {
            // Verifica se o arquivo já foi processado
            if (!processedFilesRef.current.has(data.file)) {
              processedFilesRef.current.add(data.file);
              setResumoProcessamento((prev) => [
                ...prev,
                {
                  filename: data.file,
                  resumo: data.info,
                },
              ]);

              // Verifica se todos os arquivos foram processados
              if (processedFilesRef.current.size >= files.length) {
                setProcessCompleted(true);
                toast.success("Processamento concluído com sucesso");
                cleanupWebSocket(); // Limpa a conexão quando concluir
              }
            }
          }

          // Verifica se o estágio é "ZIP criado"
          if (stage === "ZIP criado" && data.zipPath) {
            setZipPath(data.zipPath); // Armazena o caminho do ZIP
          }
        } else if (data && data.error) {
          toast.error(data.error);
        }
      }
    },
    [
      projeto._id,
      uploadStartTime,
      files.length,
      processCompleted,
      cleanupWebSocket,
    ]
  );

  useEffect(() => {
    if (lastMessage !== null) {
      try {
        const messageData = JSON.parse(lastMessage.data);
        if (messageData.success && messageData.message) {
          processMessage(messageData.message);
        }
      } catch (error) {
        console.error("Erro ao processar mensagem:", error);
      }
    }
  }, [lastMessage, processMessage]);

  // Limpa a conexão WebSocket quando o componente for desmontado
  useEffect(() => {
    return () => {
      cleanupWebSocket();
    };
  }, [cleanupWebSocket]);

  return (
    <ExecutionContainer>
      <Header>
        <HeaderTitle>
          <RiCodeFill color="#B4B5B6" />
          <span
            style={{ fontSize: "14px", marginLeft: "8px", color: "#B4B5B6" }}
          >
            Em execução
          </span>
        </HeaderTitle>
      </Header>

      <hr style={{ border: "1px solid #1E1F22", margin: "10px 0" }} />

      <SectionTitle>
        <IoCloudDownloadOutline size={20} color="#515254" />
        <span style={{ marginLeft: "8px", fontSize: "25px", color: "#fff" }}>
          Revisor SPED
        </span>
      </SectionTitle>

      <FileInfo>
        {files.map((fileInfo) => (
          <FileRow key={fileInfo.filename}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <RxFile size={13} color="#515254" />
              <strong
                style={{
                  fontSize: "15px",
                  marginLeft: "8px",
                  color: "#B4B5B6",
                }}
              >
                {fileInfo.filename}
              </strong>
            </div>
            <span style={{ fontSize: "12px", color: "#9C9D9E" }}>
              {currentFile === fileInfo.filename
                ? stage
                : processedFilesRef.current.has(fileInfo.filename)
                ? "Concluído"
                : "Aguardando"}
            </span>
          </FileRow>
        ))}

        <FileRow>
          <div style={{ display: "flex", alignItems: "center" }}>
            <LuList size={13} color="#515254" />
            <strong
              style={{ fontSize: "15px", marginLeft: "8px", color: "#B4B5B6" }}
            >
              Total de Notas no SPED:
            </strong>
          </div>
          <span style={{ fontSize: "12px", color: "#9C9D9E" }}>
            {notasNoSPED}
          </span>
        </FileRow>

        <FileRow>
          <div style={{ display: "flex", alignItems: "center" }}>
            <CgSearch size={13} color="#515254" />
            <strong
              style={{ fontSize: "15px", marginLeft: "8px", color: "#B4B5B6" }}
            >
              Notas Processadas:
            </strong>
          </div>
          <span style={{ fontSize: "12px", color: "#9C9D9E" }}>
            {notasProcessadas}
          </span>
        </FileRow>

        <FileRow>
          <div style={{ display: "flex", alignItems: "center" }}>
            <GrDocumentZip size={13} color="#515254" />
            <strong
              style={{ fontSize: "15px", color: "#B4B5B6", marginLeft: "8px" }}
            >
              Arquivos Processados:
            </strong>
          </div>
          <span style={{ fontSize: "12px", color: "#9C9D9E" }}>
            {resumoProcessamento.length}/{files.length}
          </span>
        </FileRow>
      </FileInfo>

      <ProgressBarContainer>
        <ProgressBar progress={progress} />
      </ProgressBarContainer>

      <StageInfo>
        {stage} ({progress}%)
      </StageInfo>

      {processCompleted ? (
        <CompletedRevisor
          onClose={onClose}
          files={files}
          resumoProcessamento={resumoProcessamento}
          zipPath={zipPath} // Passando o zipPath para o componente
        />
      ) : (
        <CloseButtonAbsolute onClick={onClose} disabled={!processCompleted}>
          Fechar
        </CloseButtonAbsolute>
      )}
    </ExecutionContainer>
  );
};

export default RevisorExecution;
