import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  input {
    background-color: ${({ theme }) => theme.custom};
    color: white;
  }
`;

export const Card = styled.div`
  width: max-content;
  width: 25em;
  height: max-content;
  display: flex;
  flex-direction: column;
  font-size: 1.4em;
  border-radius: 7px;
  z-index: 2;
  background-color: ${({ theme }) => theme.custom2};
  border: 1px solid ${({ theme }) => theme.border5};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  .header {
    width: 100%;
    min-height: 2em;
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${({ theme }) => theme.border3};
    background-color: ${({ theme }) => theme.custom};
    position: relative;
    justify-content: center;

    svg {
      font-size: 0.8em;
      margin-right: 10px;
    }

    p {
      font-size: 0.8em;
      font-weight: 600;
    }
    .btn {
      position: absolute;
      background: none;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      width: 2em;
      height: 100%;
      border-right: 1px solid ${({ theme }) => theme.border3};
      background-color: ${({ theme }) => theme.custom};

      svg {
        margin: 0;
        font-size: 0.8em;
        opacity: 0.7;
      }
    }
  }

  .search {
    width: 100%;
    min-height: 1.5em;
    display: flex;
    align-items: center;
    input {
      height: 100%;
      border-radius: 0;
      width: 100%;
      display: flex;
      font-size: 0.8em;
    }

    svg {
      position: absolute;
      margin-left: 0.5em;
      font-size: 0.8em;
      opacity: 0.6;
    }
  }

  .tag {
    width: 100%;
    height: 1.3em;
    display: flex;
    align-items: center;
    opacity: 0.7;
    border-bottom: 1px solid ${({ theme }) => theme.border2};
    background-color: ${({ theme }) => theme.custom1};

    svg {
      margin: 0 10px;
      margin-right: 5px;
      font-size: 0.6em;
      opacity: 0.8;
    }

    p {
      font-size: 0.5em;
      font-weight: 600;
    }
  }

  .infos {
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;

    .info {
      width: 100%;
      min-height: max-content;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      display: flex;
      align-items: center;
      padding-left: 0.5em;
      background-color: ${({ theme }) => theme.custom2};

      p {
        font-size: 0.57em;
        padding: 5px 0;
      }

      .title {
        font-weight: 600;
        margin-right: 0.5em;
      }
    }
  }

  .bottom {
    width: 100%;
    display: flex;
    justify-content: space-around;
    gap: 3em;

    .action-button {
      background: none;
      border: none;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      transition: transform 0.3s, color 0.3s;
      opacity: 0.9;
      color: ${({ theme }) => theme.text};

      &:hover {
        color: ${({ theme }) => theme.text};
        transform: scale(1.05);
        opacity: 1;
      }

      svg {
        font-size: 1em;
        color: ${({ theme }) => theme.border8};
        transition: color 0.3s;
      }

      p {
        font-size: 0.6em;
        margin-top: 5px;
        color: ${({ theme }) => theme.border8};
        transition: color 0.3s;
      }
    }
  }

 

  .display {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    overflow-y: overlay;

    .display {
      width: 100%;
      height: max-content;
      overflow: hidden;
      overflow-y: overlay;
      display: flex;
      flex-direction: column;

      .card {
        width: 100%;
        display: flex;
        height: 2.5em;
        background-color: ${({ theme }) => theme.custom};
        border-top: 1px solid ${({ theme }) => theme.border3};
        cursor: pointer;
        transition: all 0.2s;

        .thumb {
          min-width: 2.5em;
          height: 100%;
          border-right: 1px solid ${({ theme }) => theme.border3};
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            font-size: 0.8em;
            opacity: 0.7;
          }
        }

        .content {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;

          p {
            margin-left: 1em;
            font-size: 0.75em;
            font-weight: 600;
            line-height: 18px;
          }

          p:last-child {
            font-size: 0.65em;
            opacity: 0.7;
            font-weight: 500;
          }
        }

        .check {
          min-width: 2em;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 0.7;
          transition: all 0.2s;

          &:hover {
            opacity: 1;
          }

          svg {
            font-size: 0.8em;
            opacity: 0.6;
          }
        }
      }
    }

    .picker {
      width: 100%;
      height: 2.5em;
      display: flex;
      align-items: center;
      .pick {
        width: max-content;
        height: 1.5em;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${({ theme }) => theme.custom};
        margin: 0 0.5em;
        border-radius: 5px;
        padding: 0 20px;
        opacity: 0.65;
        cursor: pointer;
        transition: all 0.2s;

        &.ativ {
          opacity: 1;
        }

        svg {
          font-size: 0.8em;
          margin-right: 5px;
        }

        p {
          font-size: 0.7em;
          transform: translateY(1px);
        }

        &.user {
          border: 1px solid rgba(0, 200, 250, 0.6);

          svg {
            color: rgba(0, 200, 250, 0.6);
            fill: rgba(0, 100, 150, 1);
          }
        }

        &.cliente {
          border: 1px solid rgba(0, 250, 100, 0.6);

          svg {
            color: rgba(0, 150, 50, 0.6);
            fill: rgba(0, 200, 150, 1);
          }
        }

        &.adm {
          border: 1px solid rgba(250, 230, 0, 1);

          svg {
            fill: rgba(250, 230, 0, 1);
          }
        }
      }
    }
    .input {
      font-size: 1.1em;
      width: 100%;
      height: 3em;
      display: flex;
      align-items: center;
      background-color: ${({ theme }) => theme.custom3};
      border-bottom: 1px solid ${({ theme }) => theme.border3};
      svg {
        position: absolute;
        margin-left: 1.8em;
        font-size: 0.8em;
        opacity: 0.6;
      }

      input {
        height: 1.8em;
        border-radius: 7px;
        border: 1px solid ${({ theme }) => theme.border5};
        margin-left: 1em;
        width: 84%;
        font-size: 0.8em;
        color: ${({ theme }) => theme.text};
      }
    }

    .item {
      width: 100%;
      height: 4em;
      display: flex;
      border-top: 1px solid ${({ theme }) => theme.border3};
      background-color: ${({ theme }) => theme.custom};
      margin: 1px 0;
      cursor: pointer;
      opacity: 0.9;
      position: relative;
      svg {
        position: absolute;
        right: 0;
        margin-right: 25px;
        font-size: 0.8em;
        opacity: 0.5;
        cursor: pointer;
        color: rgba(200, 0, 0, 1);

        &:hover {
          opacity: 1;
        }
      }

      &:hover,
      &.ativ {
        opacity: 1;
      }

      &.ativ {
        cursor: default;
      }
      .thumb {
        width: 4em;
        height: 4em;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid ${({ theme }) => theme.border3};

        .circle {
          width: 2.5em;
          height: 2.5em;
          display: flex;
          align-items: center;
          border: 1px solid ${({ theme }) => theme.border3};
          border-radius: 50%;
          justify-content: center;
          font-weight: 800;
          color: white;
          background-color: rgba(0, 150, 70, 1);
        }
      }

      .content {
        width: calc(100% - 4em);
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        p {
          margin-left: 1em;
          font-size: 0.8em;
          color: ${({ theme }) => theme.text};
          opacity: 0.9;
          font-weight: 600;
          line-height: 18px;
        }
        p:last-child,
        p.email {
          font-size: 0.7em;
          opacity: 0.7;
          font-weight: 500;
          margin-left: 16px;
        }
      }
    }
  }

  .bottom {
    width: 100%;
    min-height: 4em;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.custom};
    border-top: 1px solid ${({ theme }) => theme.border3};
  }
`;
