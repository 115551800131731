import styled from "styled-components";
export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, .1);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 100; /* Z-index do Container */

  &:before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(
      10px
    ); /* Ajuste o valor do blur conforme necessário */
    z-index: 1; /* Z-index do pseudo-elemento */
  }
`;

export const Card = styled.div`
  width: max-content;
  min-width: 15em;
  height: max-content;
  min-height: 17em;
  display: flex;
  flex-direction: column;
  font-size: 1.5em;
  align-items: center;

  border-radius: 10px;
  z-index: 2; /* Z-index do Card */
  position: relative; /* Certifique-se de que o Card está posicionado relativamente para respeitar o z-index */
  background-color: rgba(5, 7, 10);
  border: 1px solid rgba(255, 255, 255, 0.05);

  .header {
    width: 100%;
    height: 4em;
    display: flex;
    align-items: center;
    justify-content:center;
    background-color: rgba(15, 15, 15);
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);

    .btn {
      position: absolute;
      cursor: pointer;
      opacity: .8;
      left: 0;
      transform: translateY(-1em);
      font-size: .7em;
      margin: 15px;

      &:hover {
        opacity: 1;
      }
    }

    img {
      width: 5.5em;
      transform: translateX(-.3em);
      opacity: .9;
    }
  }

`;


