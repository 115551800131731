import {
  IoArrowBack,
  IoTrash,
  IoReload,
  IoCheckmark,
  IoCubeOutline,
  IoClose,
} from "react-icons/io5";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { BsCpu } from "react-icons/bs";
import { SiMicrosoftexcel } from "react-icons/si";
import { Card } from "../../styles";
import { TbCloudDownload } from "react-icons/tb";
import { PiFilesDuotone, PiFileTxtDuotone } from "react-icons/pi";
import { LuDatabase } from "react-icons/lu";
import { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { IoCodeSlashOutline } from "react-icons/io5";
import { useAuth } from "@hooks/auth";
import useWebSocket from "react-use-websocket";

export default function Download({ setStatus }) {
  const { projeto, token } = useAuth();
  const [files, setFiles] = useState([]);
  const [isProcessingTasks, setIsProcessingTasks] = useState(false);
  const [progress, setProgress] = useState(0);
  const [stage, setStage] = useState("");
  const [downloadCompleted, setDownloadCompleted] = useState(false);
  const [numNotasFiscais, setNumNotasFiscais] = useState(0);
  const [compressedFiles, setCompressedFiles] = useState(0);
  const [zipFilePaths, setZipFilePaths] = useState([]);
  const [dadosJsonData, setDadosJsonData] = useState(null);
  const fileInputRef = useRef(null);
  const [wsInstance, setWsInstance] = useState(null);

  const handleFileSelect = (event) => {
    const selectedFiles = Array.from(event.target.files)
      .filter((file) => file.name.toLowerCase().endsWith(".txt"))
      .map((file) => ({
        file,
        status: "pending",
        progress: 0,
        sizeInKB: (file.size / 1024).toFixed(2),
      }));
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const newFiles = Array.from(event.dataTransfer.files)
      .filter((file) => file.name.toLowerCase().endsWith(".txt"))
      .map((file) => ({
        file,
        status: "pending",
        progress: 0,
        sizeInKB: (file.size / 1024).toFixed(2),
      }));
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleOpenFileDialog = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Open file dialog
    }
  };

  const handleRemoveFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleSend = async () => {
    if (!token) {
      toast.error("Usuário não autenticado. Por favor, faça login novamente.");
      return;
    }

    if (files.length === 0) {
      toast.error("Nenhum arquivo selecionado para enviar.");
      return;
    }

    setIsProcessingTasks(true);

    // Send files
    const formData = new FormData();
    files.forEach((fileData) => {
      formData.append("files", fileData.file);
    });

    try {
      await axios.post(
        `${process.env.REACT_APP_API}/tools/download-xml`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            projeto: projeto._id,
          },
        }
      );
      toast.success("Arquivos enviados com sucesso.");

      // Initialize WebSocket connection
      const ws = new WebSocket("wss://log.vidal-app.com/ws");
      setWsInstance(ws);

      ws.onopen = () => {
        ws.send(
          JSON.stringify({
            action: "subscribe",
            projectId: projeto._id,
            type: "download_xml",
          })
        );
      };

      ws.onmessage = (event) => {
        const messageData = JSON.parse(event.data);
        if (messageData.success && messageData.message) {
          const msg = messageData.message;
          const { type, projectId, data } = msg;

          if (
            projectId &&
            projectId === projeto._id &&
            type === "download_xml"
          ) {
            if (
              data &&
              data.progress !== undefined &&
              data.stage !== undefined
            ) {
              const {
                progress,
                stage,
                totalChaves,
                arquivosEncontrados,
                dadosJsonPath,
                zipPath,
                aggregatedFaltantesZipPath,
              } = data;

              setProgress(progress);
              setStage(stage);

              if (totalChaves !== undefined) {
                setNumNotasFiscais(totalChaves);
              }

              if (arquivosEncontrados !== undefined) {
                setCompressedFiles(arquivosEncontrados);
              }

              if (dadosJsonPath) {
                fetchDadosJson();
              }

              if (zipPath) {
                setZipFilePaths((prev) => {
                  if (!prev.includes(zipPath)) {
                    return [...prev, zipPath];
                  }
                  return prev;
                });
              }

              if (progress === 100) {
                setDownloadCompleted(true);
                ws.close();
                toast.success("Processamento concluído com sucesso.");
              }
            } else if (data && data.message) {
              const errorMessage = data.message;
              console.error(`Erro: ${errorMessage}`);
              toast.error(errorMessage);
            }
          }
        }
      };

      ws.onerror = (event) => {
        console.error("WebSocket error:", event);
        toast.error("Erro no processamento dos arquivos.");
      };

      ws.onclose = () => {
        console.log("WebSocket connection closed");
      };
    } catch (error) {
      console.error("Erro no upload:", error);
      toast.error(
        error.response?.data?.message || "Erro no envio dos arquivos"
      );
      setIsProcessingTasks(false);
    }
  };

  const fetchDadosJson = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/tools/data-json`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            projeto: projeto._id,
          },
        }
      );
      setDadosJsonData(response.data);
    } catch (error) {
      console.error("Erro ao buscar dados.json:", error);
      toast.error(
        "Erro ao buscar dados estatísticos. Tente novamente mais tarde."
      );
    }
  };

  const downloadZip = async (zipPath) => {
    if (!zipPath) {
      toast.error("Caminho do arquivo ZIP inválido.");
      return;
    }

    const fileName = zipPath.split("/").pop() || zipPath.split("\\").pop();
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/tools/download-zip`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            projeto: projeto._id,
          },
          params: { fileName },
          responseType: "blob",
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
      toast.success(
        `Download do arquivo ZIP ${fileName} iniciado com sucesso.`
      );
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Erro de autenticação. Por favor, faça login novamente.");
      } else if (error.response && error.response.status === 404) {
        toast.error("Arquivo ZIP não encontrado no servidor.");
      } else {
        toast.error(
          `Erro ao baixar o arquivo ZIP ${fileName}. Tente novamente mais tarde.`
        );
      }
      console.error(`Erro ao baixar o arquivo ZIP ${fileName}:`, error);
    }
  };

  const downloadAllZips = async () => {
    if (!zipFilePaths || zipFilePaths.length === 0) {
      toast.error("Nenhum arquivo ZIP disponível para download.");
      return;
    }

    try {
      for (const zipPath of zipFilePaths) {
        await downloadZip(zipPath);
        await new Promise((resolve) => setTimeout(resolve, 500));
      }
      toast.success("Todos os downloads de arquivos ZIP foram iniciados.");
    } catch (error) {
      console.error("Erro ao baixar todos os arquivos ZIP:", error);
      toast.error(
        "Erro ao baixar todos os arquivos ZIP. Tente novamente mais tarde."
      );
    }
  };

  // Clean up WebSocket on component unmount
  useEffect(() => {
    return () => {
      if (wsInstance) {
        wsInstance.close();
      }
    };
  }, [wsInstance]);

  return (
    <Card>
      <div className="header">
        <div className="btn" onClick={() => setStatus("list")}>
          <IoArrowBack />
        </div>
        <IoCodeSlashOutline />
        <p>Executar script</p>
      </div>

      <div className="display">
        <div className="item ativ">
          <div className="thumb">
            <TbCloudDownload />
          </div>
          <div className="content">
            <p>Download XML's</p>
            <p>
              O algoritmo lê arquivos .txt com chaves de XML's, encontra os
              arquivos no banco de dados e os disponibiliza para download.
            </p>
          </div>
        </div>

        {!isProcessingTasks ? (
          <>
            {files.length > 0 && (
              <div className="tag">
                <div className="info">
                  <PiFilesDuotone />
                  <p>{files.length}</p>
                </div>
                <div className="info">
                  <LuDatabase />
                  <p>
                    {(
                      files.reduce((acc, file) => acc + file.file.size, 0) /
                      (1024 * 1024)
                    ).toFixed(2)}{" "}
                    MB
                  </p>
                </div>
                <div className="btn" onClick={() => setFiles([])}>
                  <IoReload />
                </div>
              </div>
            )}

            <div
              className="drop"
              onClick={handleOpenFileDialog}
              onDrop={handleDrop}
              onDragOver={(e) => e.preventDefault()}
            >
              <div className="drop-area">
                <input
                  ref={fileInputRef}
                  type="file"
                  accept=".txt"
                  multiple
                  style={{ display: "none" }}
                  onChange={handleFileSelect}
                />
                <div className="content">
                  <div className="thumb">
                    <p>+</p>
                  </div>
                  <div className="text">
                    <p>Carregar arquivos</p>
                    <p>
                      Arraste para cá a lista .txt com as chaves dos XMLs que
                      deseja baixar ou clique para selecionar
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {files.length > 0 && (
              <div className="files">
                {files.map((fileData, index) => (
                  <div className="file" key={index}>
                    <div className="thumb">
                      {fileData.status === "uploading" && (
                        <div className="spinner" />
                      )}
                      {fileData.status === "completed" && <IoCheckmark />}
                      {fileData.status === "pending" && <IoCubeOutline />}
                    </div>
                    <div className="content">
                      <p>{fileData.file.name}</p>
                      <p className="size">{fileData.sizeInKB} Kb</p>
                      <IoTrash onClick={() => handleRemoveFile(index)} />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </>
        ) : !downloadCompleted ? (
          // Display progress and stage information
          <div className="processing">
            <div className="progress-bar">
              <div className="progress" style={{ width: `${progress}%` }}></div>
            </div>
            <p>{stage}</p>
            <p>{progress}%</p>
          </div>
        ) : (
          // Display completion information and download options
          <div className="completed">
            <div className="header">
              <IoCheckmark color="#4eb959" size={24} />
              <span
                style={{
                  fontSize: "18px",
                  marginLeft: "8px",
                  color: "#4eb959",
                }}
              >
                Concluído
              </span>
            </div>
            <div className="info">
              <p>Arquivos processados:</p>
              {files.map((fileData, index) => (
                <div key={index}>
                  <span>{fileData.file.name}</span> -{" "}
                  <span>{fileData.sizeInKB} KB</span>
                </div>
              ))}
              {dadosJsonData && (
                <>
                  <p>
                    Total de notas fiscais enviadas:{" "}
                    {dadosJsonData.nfes_enviadas}
                  </p>
                  <p>
                    Arquivos encontrados: {dadosJsonData.arquivos_encontrados}
                  </p>
                  <p>Arquivos faltantes: {dadosJsonData.arquivos_faltantes}</p>
                </>
              )}
            </div>
            <div className="buttons">
              <div className="btn" onClick={downloadAllZips}>
                <p>Baixar Todos os Arquivos ZIP</p>
              </div>
              <div className="btn" onClick={() => setStatus("list")}>
                <p>Fechar</p>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="bottom">
        {!isProcessingTasks && (
          <div className="btn" onClick={handleSend}>
            <p>EXECUTAR</p>
          </div>
        )}
      </div>
    </Card>
  );
}
