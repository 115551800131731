import React, { useState, useMemo } from "react";
import { Card, ExecuteButton } from "../../../../styles";
import { MdAssignmentAdd } from "react-icons/md";
import ModalRevisor from "./ModalRevisor"; // Importar o novo modal personalizado
import { useDropzone } from "react-dropzone";

const RevisorSped = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
  };

  const focusedStyle = {
    borderColor: "#2196f3",
  };

  const acceptStyle = {
    borderColor: "#00e676",
  };

  const rejectStyle = {
    borderColor: "#ff1744",
  };

  function StyledDropzone(props) {
    const {
      getRootProps,
      getInputProps,
      isFocused,
      isDragAccept,
      isDragReject,
    } = useDropzone({ accept: { "image/*": [] } });

    const style = useMemo(
      () => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {}),
      }),
      [isFocused, isDragAccept, isDragReject]
    );

    return (
      <div
        className="container
      "
      >
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <p>Arraste os arquivos SPED em .txt aqui</p>
        </div>
      </div>
    );
  }

  <StyledDropzone />;

  // Função que abre o modal ao clicar no botão "Executar"
  const handleExecuteClick = () => {
    setIsModalOpen(true);
  };

  // Função que fecha o modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Card>
        <div className="head">
          <MdAssignmentAdd />
          <p>Revisor SPED</p>
        </div>
        <div className="desc">
          <div className="text">
            <p>
              Funcionalidade de revisão e validação de documentos fiscais, 
              permitindo que os usuários examinem e confirmem informações 
              relacionadas ao SPED ICMS.
            </p>
          </div>
        </div>
        <ExecuteButton onClick={handleExecuteClick}>Executar</ExecuteButton>
      </Card>

      {/* ModalDownload personalizado para exibir o processo de download */}
      <ModalRevisor
        isOpen={isModalOpen}
        onClose={handleCloseModal}
      ></ModalRevisor>
    </>
  );
};

export default RevisorSped;
