import React, { useEffect, useState } from "react";
import axios from "axios"; // Certifique-se de importar axios
import {
  Container,
  Box,
  Projeto,
  ModalOverlay,
  CreateButton,
  Header,
} from "./styles";

import { IoCubeOutline } from "react-icons/io5";
import { useAuth } from "@hooks/auth";
import { MdEdit } from "react-icons/md";
import { IoIosClose } from "react-icons/io";

import Projetos from "../../../../../auth/components/projetos";
import EditProject from "../editProjetos";
import Modal from "../../../modal";
import { useProjects } from "../../../../../../hooks/projects";

const SelectProject = ({ isOpen, onClose }) => {
  const { projeto, setProjeto, getProjeto, user, token } = useAuth();
  const { setEditProjectId, setEditInfos, editInfos, userProjects } =
    useProjects();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [projectName, setProjectName] = useState(null);
  const [projectId, setProjectId] = useState(null);
  const projects = userProjects;
  const handleChooseProject = (project) => {
    setProjeto(project);
  };

  useEffect(() => {
    if (user._id && !projeto._id) {
      getProjeto();
    }
    console.log("projeto", projects);
  }, [user, projeto, getProjeto]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsEditModalOpen(false);
  };

  const handleCreateProject = () => {
    setIsModalOpen(true);
  };

  const handleEditProject = async (e, project) => {
    e.stopPropagation();
    setProjectName(project.nome);
    setProjectId(project._id);
    setIsEditModalOpen(true);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/projetos/infos`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            projeto: project._id,
          },
        }
      );

      // Ensure that the response data is valid
      if (response.data) {
        setEditInfos(response.data);
      } else {
        console.error("Dados do projeto não encontrados.");
      }
    } catch (error) {
      console.error("Erro ao buscar informações do projeto:", error);
    }
  };

  if (!isOpen) return null;

  return (
    <>
      <ModalOverlay onClick={onClose}>
        <Container onClick={(e) => e.stopPropagation()}>
          <Header>
            <p>Edição de Projetos</p>
            <div className="close" onClick={onClose}>
              <IoIosClose />
            </div>
          </Header>
          <Box>
            {Array.isArray(projects) && projects.length > 0 ? (
              projects.map((project) => (
                <Projeto
                  key={project._id}
                  onClick={() => handleChooseProject(project)}
                >
                  <div className="header">
                    <div className="thumb">
                      <IoCubeOutline style={{ color: `${projeto.color}` }} />
                    </div>
                    <div className="text">
                      <p>{project.nome || "Nome do Projeto"}</p>
                      <p>{project.cnpj || "CNPJ não disponível"}</p>
                    </div>
                    <div className="edit-icon">
                      <div
                        className="icon"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleEditProject(e, project);
                        }}
                      >
                        <MdEdit />
                      </div>
                    </div>
                  </div>
                </Projeto>
              ))
            ) : (
              <div className="not-found">
                <p>Nenhum Projeto Encontrado</p>
              </div>
            )}
          </Box>
          <CreateButton>
            <div className="button" onClick={handleCreateProject}>
              <p>Criar Novo</p>
            </div>
          </CreateButton>
        </Container>
      </ModalOverlay>

      {isEditModalOpen && (
        <Modal isOpen={isEditModalOpen} onClose={handleCloseModal}>
          <EditProject
            setIsEditModalOpen={setIsEditModalOpen}
            projectName={projectName}
            setProjectName={setProjectName}
            projectId={projectId}
          />
        </Modal>
      )}

      <Modal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        title="Criar Novo Projeto"
      >
        <Projetos onClose={handleCloseModal} />
      </Modal>
    </>
  );
};

export default SelectProject;
