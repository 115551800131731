// styles.js
import styled from "styled-components";

// Container principal
export const Display = styled.div`
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;

  .header {
    width: 100%;
    min-height: 2em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${({ theme }) => theme.border5};
    background-color: ${({ theme }) => theme.custom};
    padding-left: 1em;

    .side {
      width: max-content;
      height: 100%;
      align-items: center;
      display: flex;
    }

    p {
      font-size: 0.8em;
      opacity: 0.7;
    }

    svg {
      opacity: 0.8;
      font-size: 0.9em;
      margin-right: 1em;
    }
  }

  .display {
    width: 100%;
    height: 100%;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    overflow: auto;
    align-items: center;
  }
`;

// Estilo do Card
export const Card = styled.div`
  min-height: 11em;
  width: 93%;
  margin-top: 10px;
  height: max-content;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.custom};
  border: 1px solid ${({ theme }) => theme.border3};
  overflow: hidden;

  .head {
    width: 100%;
    height: 2em;
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${({ theme }) => theme.border5};
    justify-content: flex-start;

    svg {
      font-size: 1em;
      opacity: 0.7;
      margin-left: 10px;
      margin-right: 5px;
    }

    p {
      font-size: 0.8em;
      opacity: 0.7;
    }
  }

  .infos {
    width: 100%;
    padding: 10px 15px;

    .items {
      display: flex;
      flex-direction: column;

      .item {
        display: flex;
        align-items: center;
        padding: 5px 0;

        p {
          font-size: 0.8em;
          opacity: 0.7;
          font-weight: 300;
        }

        &:not(:last-child) {
          border-bottom: 1px solid ${({ theme }) => theme.border5};
        }
      }
    }
  }
`;
