import NFes from "./components/reports";
import SPEDs from "./components/speds";
import Upload from "./components/processamento";
import { Container, Menu } from "./styles";

import useLocalStorageState from "@hooks/useLocalStorage.js";

export default function Reports() {
  const [status, setStatus] = useLocalStorageState(
    "report",
    "report_status",
    "nfes"
  );

  const stages = [
    { status: "nfes", comp: <NFes /> },
    { status: "reports", comp: <SPEDs /> },
    { status: "upload", comp: <Upload /> },
  ];

  function checkStatus(str) {
    if (str === status) {
      return "ativ";
    }

    return "";
  }

  function Stages() {
    return stages.find((obj) => obj.status === status) !== undefined ? (
      stages.find((obj) => obj.status === status).comp
    ) : (
      <></>
    );
  }
  return (
    <Container>
      <Menu>
     
        <div
          className={`btn ${checkStatus("nfes")}`}
          onClick={() => setStatus("nfes")}
        >
          <svg
            aria-hidden="true"
            height="16"
            viewBox="0 0 16 16"
            version="1.1"
            width="16"
            data-view-component="true"
            class="octicon octicon-table UnderlineNav-octicon d-none d-sm-inline"
          >
            <path d="M0 1.75C0 .784.784 0 1.75 0h12.5C15.216 0 16 .784 16 1.75v12.5A1.75 1.75 0 0 1 14.25 16H1.75A1.75 1.75 0 0 1 0 14.25ZM6.5 6.5v8h7.75a.25.25 0 0 0 .25-.25V6.5Zm8-1.5V1.75a.25.25 0 0 0-.25-.25H6.5V5Zm-13 1.5v7.75c0 .138.112.25.25.25H5v-8ZM5 5V1.5H1.75a.25.25 0 0 0-.25.25V5Z"></path>
          </svg>
          <p>Movimentação Diária</p>
        </div>
      </Menu>
      <Stages />
    </Container>
  );
}
