// PdfViewerModal.js
import React from "react";
import styled from "styled-components";
import { CgCloseO } from "react-icons/cg";
import { ModalContainer, Header, CloseButton, Overlay } from "./styles";

// Estilos específicos para o modal do PDF
const PDFModalContainer = styled(ModalContainer)`
  width: 80vw;
  height: 85vh;
  padding: 10px;
  z-index: 1001; // Maior que o z-index do modal XML
`;

const PDFContent = styled.div`
  width: 100%;
  height: calc(100% - 50px); // Altura total menos o header
  margin-top: 10px;

  iframe {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 5px;
  }
`;

const PdfViewerModal = ({ pdfUrl, onClose }) => {
  return (
    <>
      <Overlay style={{ zIndex: 1001 }} /> {/* Overlay com z-index maior */}
      <PDFModalContainer>
        <Header>
          <h2>VISUALIZAÇÃO DA DANFE</h2>
          <CloseButton
            onClick={() => {
              onClose();
              URL.revokeObjectURL(pdfUrl);
            }}
          >
            <CgCloseO />
          </CloseButton>
        </Header>
        <PDFContent>
          <iframe src={pdfUrl} title="PDF Viewer" allowFullScreen />
        </PDFContent>
      </PDFModalContainer>
    </>
  );
};

export default PdfViewerModal;
