import React, { useState, useEffect } from "react";
import { ModalOverlay, Container, Content, Header, Footer } from "./styles";

import { IoCubeOutline } from "react-icons/io5";
import { FaAngleLeft } from "react-icons/fa6";

import { useProjects } from "@hooks/projects";
import { useAuth } from "@hooks/auth";
import { useDropdown } from "@hooks/dropDown";
import axios from "axios";

// Função para formatar o tamanho do projeto em MB ou GB
const formatProjectSize = (sizeMB) => {
  if (sizeMB != null) {
    if (sizeMB >= 1024) {
      const sizeGB = sizeMB / 1024;
      return `${sizeGB.toFixed(2)} GB`; // Se maior que 1024MB, exibe em GB
    }
    return `${sizeMB.toFixed(2)} MB`; // Se menor que 1024MB, exibe em MB
  }
  return "Tamanho indisponível";
};

export default function EditProject({
  setIsEditModalOpen,
  projectName,
  setProjectName,
  projectId,
}) {
  const { token } = useAuth();
  const { editInfos, setEditInfos } = useProjects();
  const { setIsOpen } = useDropdown();

  const handleCloseModal = () => {
    setIsOpen(false);
    setIsEditModalOpen(false);
  };
  // Atualizar o estado do nome do projeto ao editar
  const handleNameChange = (e) => {
    setProjectName(e.target.value);
  };

  // Função para salvar o projeto
  const saveProject = async () => {
    console.log("id do projeto a ser editado:", projectId, projectName);
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API}/projetos`,
        {
          nome: projectName, // Novo nome do projeto a ser salvo
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            projeto: projectId,
          },
        }
      );
      window.location.reload();
    } catch (error) {
      console.error("Erro ao atualizar informações do projeto:", error);
    }
  };

  // Função para deletar o projeto
  const deleteProject = async () => {
    if (!editInfos?.nome?._id) {
      console.error("ID do projeto não encontrado.");
      return;
    }

    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API}/projetos/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        window.location.reload()
      );
      console.log("Projeto deletado com sucesso:", response.data);
    } catch (error) {
      console.error("Erro ao deletar o projeto:", error);
    }
  };

  return (
    <ModalOverlay onClick={handleCloseModal}>
      <Container onClick={(e) => e.stopPropagation()}>
        <Header>
          <div className="thumb">
            <IoCubeOutline />
          </div>
          <input
            value={projectName}
            onChange={handleNameChange}
            placeholder={projectName || "Nome do Projeto"}
          />
          <div className="return" onClick={handleCloseModal}>
            <FaAngleLeft />
          </div>
        </Header>
        <Content>
          <div className="item">
            <div className="title">
              <p>SPED's</p>
            </div>
            <div>
              {editInfos !== undefined && editInfos !== null
                ? editInfos.spedFilesCount
                : "0"}
            </div>
          </div>
          <div className="item">
            <div className="title">
              <p>NFe's</p>
            </div>
            <div>
              {editInfos !== undefined && editInfos !== null
                ? editInfos.nfeCount
                : "0"}
            </div>
          </div>
          <div className="item">
            <div className="title">
              <p>CTe's</p>
            </div>
            <div>
              {editInfos !== undefined && editInfos !== null
                ? editInfos.cteCount
                : "0"}
            </div>
          </div>
          <div className="item">
            <div className="title">
              <p>Tamanho</p>
            </div>
            <div>
              {editInfos !== undefined && editInfos !== null
                ? formatProjectSize(editInfos.projectSizeMB)
                : "0"}
            </div>
          </div>
        </Content>
        <Footer>
          <div className="save-button" onClick={saveProject}>
            <p>Salvar</p>
          </div>
          <div className="delete-button" onClick={deleteProject}>
            <p>Deletar</p>
          </div>
        </Footer>
      </Container>
    </ModalOverlay>
  );
}
