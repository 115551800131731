import { IoClose, IoSearch } from "react-icons/io5";
import { Card, Container } from "../../styles";
import { useEffect, useState } from "react";
import { LuUsers } from "react-icons/lu";
import { useUsers } from "../../../../../hooks/users";
import { useAuth } from "../../../../../hooks/auth";

export default function List({
  status,
  setStatus,
  handleClose,
  setSelectedUser,
}) {
  const { users } = useUsers();
  const { user } = useAuth();

  useEffect(() => {
    console.log("users", users);
  }, []);

  const selectUser = (user) => {
    setStatus("edit_user");
    setSelectedUser(user);
  };

  useEffect(() => {
    console.log("cor", user);
  }, []);

  return (
    <Card>
      <div className="header">
        <div className="btn" onClick={handleClose}>
          <IoClose />
        </div>
        <LuUsers />
        <p>Usuários</p>
      </div>
      <div className="search">
        <IoSearch />
        <input placeholder="Procurar usuário" />
      </div>
      <div className="display">
        {users
          .filter((user) => user.cargo !== "god")
          .map((user) => (
            <div className="item" onClick={() => selectUser(user)}>
              <div className="thumb">
                <div className="circle" style={{ backgroundColor: user.color }}>
                  <p>{user.name.charAt(0).toUpperCase()}</p>
                </div>
              </div>
              <div className="content">
                <p>{user.name}</p>
                <p>{user.email}</p>
              </div>
            </div>
          ))}
      </div>
      <div className="bottom">
        {["god", "admin"].includes(user.cargo) && (
          <div className="btn" onClick={() => setStatus("new_user")}>
            <p>ADICIONAR</p>
          </div>
        )}
      </div>
    </Card>
  );
}
