import React, { createContext, useContext } from "react";
import useLocalStorageState from "@hooks/useLocalStorage";

const TemaContext = createContext();

export default function TemaProvider({ children }) {
const [theme, setTheme] = useLocalStorageState("theme", "dark") || "dark";

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
  };

  return (
    <TemaContext.Provider value={{ theme, setTheme, toggleTheme }}>
      {children}
    </TemaContext.Provider>
  );
}

export function useTema() {
  const context = useContext(TemaContext);
  if (!context) {
    throw new Error("useTema must be used within a TemaProvider");
  }
  return context;
}
