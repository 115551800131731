import React, {
  createContext,
  useContext,
  useMemo,
  useState,
  useEffect,
} from "react";

import useLocalStorageState from "@hooks/useLocalStorage.js";

const DashContext = createContext();

export default function DashProvider({ children }) {
  const [status, setStatus] = useLocalStorageState({
    dash_stages: { report_status: "upload" },
  });
  const [dash, setDash] = useState("upload");


  const [infos, setInfos] = useLocalStorageState("infos", {
    periodStart: "",
    periodEnd: "",
    uniqueCNPJs: 0,
  });

 

  const exports = useMemo(
    () => ({
      status,
      setStatus,
      infos,
      setInfos,
      dash,
      setDash,
    }),
    [status, infos]
  );

  return (
    <DashContext.Provider value={exports}>{children}</DashContext.Provider>
  );
}

export function useDash() {
  const context = useContext(DashContext);
  if (!context) {
    throw new Error("useDash must be used within a DashProvider");
  }
  return context;
}
