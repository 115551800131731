import { useEffect, useState } from "react";
import { Container } from "./styles";
import Menu from "../menu";
import Reports from "./components/main";
import useLocalStorageState from "@hooks/useLocalStorage.js";

export default function Dash() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [state, setState] = useLocalStorageState("dash_stages", "xmls");

  const toggleMenu = () => {
    setMenuOpen((prevState) => !prevState);
  };

  function SPED() {
    return <div>SPED Component</div>;
  }

  const stages = [
    { state: "xmls", comp: <Reports /> },
    { state: "speds", comp: <SPED /> },
  ];

  useEffect(() => {
    if (!stages.some((obj) => obj.state === state)) {
      setState("xmls");
    }
  }, [state, setState, stages]);

  function Stages() {
    const stage = stages.find((obj) => obj.state === state);
    return stage ? stage.comp : null;
  }

  return (
    <Container>
      <Menu isOpen={menuOpen} toggleMenu={toggleMenu} />
      <Stages />
    </Container>
  );
}
