import styled from "styled-components";

export const Container = styled.div`
  width: 60vw;
  height: 100vh;
  overflow: hidden;
  flex-direction: column;
`;

export const Menu = styled.div`
  width: 100%;
  height: max-content;
  color: white;
  display: flex;
  background-color: rgba(13, 13, 17);
  padding-bottom: 2px;

  .btn {
    width: max-content;
    height: 100%;
    height: 2em;
    border-bottom: 4px solid rgb(130, 185, 42, 0.5);
    display: flex;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.03);
    opacity: 0.6;
    border-right: 1px solid ${({ theme }) => theme.contrast};
    padding-right: 15px;
    margin-left: 15px;
    margin-right: 15px;
    padding-left: 15px;
    padding-bottom: 3px;
    padding-top: 3px;
    cursor: pointer;

    &:hover,
    &.ativ {
      opacity: 0.9;
    }
    p {
      font-size: 0.75em;
    }

    svg {
      width: 2em;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.4em;
      padding-right: 10px;
    }
  }
`;

export const Head = styled.div`
  width: 100%;
  height: 3em;
  background-color: ${({ theme }) => theme.card4};
  display: flex;
  justify-content: space-between;
  .calendar {
    width: max-content;
    height: 100%;
    display: flex;
    align-items: center;
    border: 1px solid ${({ theme }) => theme.contrast};

    .btn {
      width: 2em;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid ${({ theme }) => theme.contrast};
      cursor: pointer;
      svg {
        font-size: 1.2em;
      }
    }

    .date {
      width: max-content;
      height: 100%;
      display: flex;
      align-items: center;
      margin: 0 1em;

      p {
        font-size: 0.9em;
        opacity: 0.8;
      }
    }
  }

  .infos {
    width: max-content;
    height: 100%;
    display: flex;
    .info {
      width: max-content;
      display: flex;
      align-items: center;
      margin-right: 1em;

      .tag {
        width: max-content;
        height: 50%;
        display: flex;
        align-items: center;
        opacity: 0.7;
      }

      .content {
        height: 50%;
        display: flex;
        align-items: center;
      }
      p {
        margin-right: 5px;
        opacity: 0.8;
        font-size: 0.85em;
      }
    }
  }
`;

export const Filter = styled.div`
  width: 100%;
  display: flex;
  height: 1.5em;
  background-color: ${({ theme }) => theme.load3};

  .item {
    width: calc(100% / 4);
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 10px;
    border-right: 1px solid ${({ theme }) => theme.border};
    border-bottom: 1px solid ${({ theme }) => theme.border};
    opacity: 0.6;
    p {
      font-size: 0.8em;
      opacity: 0.7;
      margin-left: 5px;
    }
  }
`;

export const Display = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(79%);
  background-color: ${({ theme }) => theme.load};
  overflow: overlay;


  .items {
    width: 100%;
    min-height: 2.3em;
    display: flex;
    align-items: center;
    background-color: ${({ theme }) => theme.card};
    margin: 1px 0;
    border-radius: 5px;
    opacity: 0.7;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
    .item {
      height: 100%;
      width: calc(100% / 4);
      display: flex;
      align-items: center;

      p {
        font-size: 0.9em;
        margin-left: 8px;
        opacity: 0.9;

      }
    }
  }
`;
