import styled from "styled-components";

export const Card = styled.div`
  min-height: 11em;
  width: 93%;
  margin-top: 10px;
  height: max-content;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.custom};
  border: 1px solid ${({ theme }) => theme.border3};
  margin-bottom: 1em;
  overflow: hidden;
  .head {
    width: 100%;
    height: 2em;
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${({ theme }) => theme.border5};
    justify-content: space-between;
    .side {
      width: max-content;
      height: 100%;
      align-items: center;
      display: flex;
    }
    &.top {
      border-left: 3px solid rgba(0, 150, 50);
    }
    p,
    svg {
      font-size: 0.8em;
      opacity: 0.7;
      margin-left: 5px;
      margin-right: 15px;
    }
    svg {
      margin-left: 10px;
    }
  }
  .custom-loader {
    width: 15px;
    height: 15px;
    border: 2px solid #3498db;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .task {
    padding: 20px;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .side {
      display: flex;
      align-items: center;
      height: max-content;
    }
    .thumb {
      width: 2em;
      height: 2em;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid ${({ theme }) => theme.border5};
      border-radius: 50%;
      margin-right: 10px;
    }
    p {
      font-size: 0.9em;
      font-weight: 300;
      opacity: 0.8;
    }
  }
  .load {
    width: 100%;
    height: 0.4em;
    display: flex;
    border: 1px solid ${({ theme }) => theme.border5};
    &.process {
      margin-bottom: 20px;
    }
    .progress {
      width: 30%;
      height: 100%;
      background-color: rgba(0, 150, 50);
    }
  }
  .infos {
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    .items {
      display: flex;
      flex-direction: column;
      .item {
        display: flex;
        align-items: center;
        padding: 10px 15px;
        border-bottom: 1px solid ${({ theme }) => theme.border5};

        &.last {
          border-bottom: unset;
        }
        p,
        svg {
          font-size: 0.8em;
          opacity: 0.7;
          font-weight: 300;
          margin-left: 5px;
        }
        p {
          margin-left: 10px;
        }
      }
    }
  }
`;

export const Display = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .header {
    width: 100%;
    min-height: 2em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${({ theme }) => theme.border5};
    background-color: ${({ theme }) => theme.custom};
    padding-left: 1em;

    .side {
      width: max-content;
      height: 100%;
      align-items: center;
      display: flex;
    }

    .add-btn {
      cursor: pointer;
      margin-right: 0.5em;
      height: 2em;
      width: 2em;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        font-size: 0.8em;
        color: ${({ theme }) => theme.primary};
      }

      &:hover {
        opacity: 0.8;
      }
    }

    p {
      font-size: 0.8em;
      opacity: 0.7;
    }

    svg {
      opacity: 0.8;
      font-size: 0.9em;
      margin-right: 1em;
    }
  }

  .display {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: overlay;
    align-items: center;

    .list {
      width: 98%;
      height: 5em;
      display: flex;
      background-color: ${({ theme }) => theme.custom};
      border: 1px solid ${({ theme }) => theme.border3};
      border-radius: 5px;
      margin-top: 5px;
      cursor: pointer;
      transition: all 0.3s ease;
      position: relative;

      &.dragging {
        opacity: 0.1;
        transform: scale(0.95);
      }

      .thumb {
        width: 4em;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid ${({ theme }) => theme.border5};
        margin-right: 10px;
      }

      .info {
        width: calc(100% - 5em);
        height: 100%;
        display: flex;
        flex-direction: column;
        max-width: 200px;
        justify-content: center;

        p {
          font-size: 0.75em;
          opacity: 0.8;
        }

        q {
          font-weight: bold;
          font-size: 16px;
          opacity: 1.4;
          quotes: none;
        }
      }
    }
  }
`;

export const Header = styled.div`
  width: 100%;
  min-height: 2em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.border5};
  background-color: ${({ theme }) => theme.custom};
  padding-left: 1em;

  .side {
    display: flex;
    align-items: center;

    p {
      font-size: 0.8em;
      opacity: 0.7;
      margin-left: 0.5em;
    }
  }

  svg {
    opacity: 0.8;
    font-size: 0.9em;
    margin-right: 1em;
  }
`;

export const AddButton = styled.div`
  cursor: pointer;
  margin-right: 0.5em;
  height: 2em;
  width: 2em;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    font-size: 0.8em;
    color: ${({ theme }) => theme.primary};
  }

  &:hover {
    opacity: 0.8;
  }
`;

export const DeleteButton = styled.button`
  background: transparent;
  border: transparent;
  color: #c21b0c;
  font-size: 1.2rem;
  cursor: pointer;
  margin-left: auto;
  display: flex;
  align-self: center;
  margin-top: auto;
  margin-bottom: auto;

  &:hover {
    color: #8f150a;
  }
`;
