import React from "react";
import { Container, Side, Card } from "./styles";

import DownloadCard from "./components/scripts/components/download";

import ScriptsCard from "./components/scripts";

import TaskCard from "./components/historico";
import WorkerCard from "./components/worker";
import NewNFeCard from "./components/movimentacao";
import UserCard from "./components/usuarios";
import DropBox from "./components/worker/components/dropbox";
import RevisorSped from "./components/scripts/components/revisor";

export default function Upload() {
  return (
    <Container>
      <Side>
        <ScriptsCard />
        <DownloadCard />
        <RevisorSped />
      </Side>
      <Side>
        <UserCard />
      </Side>
      <Side>
        <WorkerCard />
      </Side>
      <Side>
        <TaskCard />
      </Side>{" "}
      <Side>
        <NewNFeCard />
      </Side>
    </Container>
  );
}
