import {
  IoArrowBack,
  IoTrash,
  IoCheckmark,
  IoCubeOutline,
  IoCloudDownloadOutline,
  IoCodeSlashOutline,
} from "react-icons/io5";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { BsCpu } from "react-icons/bs";
import { SiMicrosoftexcel } from "react-icons/si";
import { PiFilesDuotone } from "react-icons/pi";
import { LuDatabase } from "react-icons/lu";
import { useState, useRef, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useAuth } from "@hooks/auth";
import { Card } from "../../../styles";

const INITIAL_TASKS = [
  { id: 1, name: "Enviando arquivos", icon: <AiOutlineCloudUpload /> },
  { id: 2, name: "Processando XMLs", icon: <BsCpu /> },
  { id: 3, name: "Criando Excel e ZIP", icon: <SiMicrosoftexcel /> },
  { id: 4, name: "Baixando arquivos", icon: <IoCloudDownloadOutline /> },
];

export default function ExtractXML({ setStatus }) {
  const { projeto, token } = useAuth();
  const [files, setFiles] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [tasks, setTasks] = useState(
    INITIAL_TASKS.map((task) => ({
      ...task,
      progress: 0,
      status: "pending",
    }))
  );
  const fileInputRef = useRef(null);
  const [ws, setWs] = useState(null);

  const updateTaskProgress = (taskId, progress, status) => {
    setTasks((prev) =>
      prev.map((task) =>
        task.id === taskId ? { ...task, progress, status } : task
      )
    );
  };

  const handleFiles = (newFiles) => {
    const validFiles = Array.from(newFiles)
      .filter((file) => /\.(txt|xml|zip)$/i.test(file.name))
      .map((file) => ({
        file,
        status: "pending",
        progress: 0,
      }));
    setFiles((prev) => [...prev, ...validFiles]);
  };

  const processFiles = async () => {
    if (!files.length) return toast.error("Selecione arquivos para processar");
    setIsProcessing(true);

    const formData = new FormData();
    files.forEach((f) => formData.append("files", f.file));

    try {
      // Upload files to the back-end
      await axios.post(
        `${process.env.REACT_APP_API}/tools/xml-excel`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            projeto: projeto._id,
          },
          onUploadProgress: (e) => {
            const progress = Math.round((e.loaded * 100) / e.total);
            updateTaskProgress(1, progress, "in-progress");
          },
        }
      );
      updateTaskProgress(1, 100, "completed");

      // Connect to WebSocket for progress updates
      const wsConnection = new WebSocket("wss://log.vidal-app.com/ws");
      setWs(wsConnection);

      wsConnection.onopen = () => {
        wsConnection.send(
          JSON.stringify({
            action: "subscribe",
            projectId: projeto._id,
            type: "extract_xml",
          })
        );
      };

      wsConnection.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (!data.success || !data.message?.data) return;

        const { progress, stage, zipFileName } = data.message.data;

        // Update task progress based on the stage
        if (stage.includes("Processando arquivos XML")) {
          updateTaskProgress(2, progress, "in-progress");
        } else if (
          stage.includes("Gerando relatório Excel") ||
          stage.includes("Criando ZIP")
        ) {
          updateTaskProgress(2, 100, "completed");
          updateTaskProgress(3, progress, "in-progress");
        }

        if (progress === 100 && zipFileName) {
          updateTaskProgress(3, 100, "completed");
          downloadZip(zipFileName);
          wsConnection.close();
        }
      };

      wsConnection.onerror = (error) => {
        console.error("WebSocket error:", error);
        toast.error("Erro no processamento dos arquivos.");
      };
    } catch (error) {
      console.error("Error during file processing:", error);
      toast.error(
        error.response?.data?.message || "Erro ao processar arquivos"
      );
      setIsProcessing(false);
    }
  };

  const downloadZip = async (fileName) => {
    try {
      updateTaskProgress(4, 0, "in-progress");

      const response = await axios.get(
        `${process.env.REACT_APP_API}/tools/xml-excel/download`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            projeto: projeto._id,
          },
          responseType: "blob",
          onDownloadProgress: (e) => {
            const progress = Math.round((e.loaded * 100) / (e.total || 1));
            updateTaskProgress(4, progress, "in-progress");
          },
        }
      );

      // Download the file
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName || "dados_extraidos.zip";
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);

      updateTaskProgress(4, 100, "completed");
      setCompleted(true);
      toast.success("Processamento concluído com sucesso!");
    } catch (error) {
      console.error("Error during file download:", error);
      toast.error("Erro ao baixar arquivo ZIP");
      setIsProcessing(false);
    }
  };

  useEffect(() => {
    return () => ws?.close();
  }, [ws]);

  return (
    <Card>
      <div className="header">
        <div className="btn" onClick={() => setStatus("list")}>
          <IoArrowBack />
        </div>
        <IoCodeSlashOutline />
        <p>Extrair XML's</p>
      </div>

      <div className="display">
        <div className="item ativ">
          <div className="thumb">
            <IoCloudDownloadOutline />
          </div>
          <div className="content">
            <p>Extrair XML's</p>
            <p>
              O algoritmo processa arquivos XML para extrair dados e gerar um
              relatório em Excel.
            </p>
          </div>
        </div>

        {!isProcessing && !completed ? (
          <>
            {files.length > 0 && (
              <div className="tag">
                <div className="info">
                  <PiFilesDuotone />
                  <p>{files.length}</p>
                </div>
                <div className="info">
                  <LuDatabase />
                  <p>
                    {(
                      files.reduce((acc, f) => acc + f.file.size, 0) /
                      (1024 * 1024)
                    ).toFixed(2)}{" "}
                    MB
                  </p>
                </div>
              </div>
            )}

            <div
              className="drop"
              onClick={() => fileInputRef.current?.click()}
              onDrop={(e) => {
                e.preventDefault();
                handleFiles(e.dataTransfer.files);
              }}
              onDragOver={(e) => e.preventDefault()}
            >
              <input
                ref={fileInputRef}
                type="file"
                accept=".txt,.xml,.zip"
                multiple
                hidden
                onChange={(e) => handleFiles(e.target.files)}
              />
              <div className="drop-area">
                <div className="content">
                  <div className="thumb">
                    <p>+</p>
                  </div>
                  <div className="text">
                    <p>Carregar arquivos</p>
                    <p>
                      Arraste arquivos .txt, .xml ou .zip para cá ou clique para
                      selecionar
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {files.length > 0 && (
              <div className="files">
                {files.map((fileData, index) => (
                  <div className="file" key={index}>
                    <div className="thumb">
                      <IoCubeOutline />
                    </div>
                    <div className="content">
                      <p>{fileData.file.name}</p>
                      <p className="size">
                        {(fileData.file.size / 1024).toFixed(2)} Kb
                      </p>
                      <IoTrash
                        onClick={() =>
                          setFiles((f) => f.filter((_, i) => i !== index))
                        }
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </>
        ) : isProcessing ? (
          <div className="files">
            {tasks.map((task) => (
              <div className="file" key={task.id}>
                <div className="thumb">
                  {task.status === "in-progress" ? (
                    <div className="spinner blue" />
                  ) : task.status === "completed" ? (
                    <IoCheckmark />
                  ) : (
                    task.icon
                  )}
                </div>
                <div className="content">
                  <div className="text">
                    <p>{task.name}</p>
                    <p>{task.progress}%</p>
                  </div>
                  <div className="load">
                    <div
                      className="progress"
                      style={{ width: `${task.progress}%` }}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : completed ? (
          <div className="completed">
            <div className="header">
              <IoCheckmark color="#4eb959" size={24} />
              <span
                style={{
                  fontSize: "18px",
                  marginLeft: "8px",
                  color: "#4eb959",
                }}
              >
                Concluído
              </span>
            </div>
            <div className="info">
              <p>Arquivos processados:</p>
              {files.map((fileData, index) => (
                <div key={index}>
                  <span>{fileData.file.name}</span> -{" "}
                  <span>{(fileData.file.size / 1024).toFixed(2)} KB</span>
                </div>
              ))}
            </div>
            <div className="buttons">
              <div className="btn" onClick={() => setStatus("list")}>
                <p>Fechar</p>
              </div>
            </div>
          </div>
        ) : null}
      </div>

      <div className="bottom">
        {!isProcessing && !completed && (
          <div className="btn" onClick={processFiles}>
            <p>EXECUTAR</p>
          </div>
        )}
      </div>
    </Card>
  );
}
