// src/App.js
import React, { useEffect, useState } from "react";
import { Container } from "./styles";
import { ThemeProvider } from "styled-components";
import { lightTheme, darkTheme } from "./themes";
import { useTema } from "./hooks/theme";
import Dash from "./components/dash";
import Auth from "./components/auth";
import Header from "./components/header";
import { useAuth } from "./hooks/auth";
import axios from "axios";
import Users from "./components/modals/users";
import Tools from "./components/modals/tools";
import Projetos from "./components/modals/projetos";
import XML from "./components/modals/xml";
import PDF from "./components/modals/pdf";
import NewProject from "./components/modals/projetos/components/new_project";

function App() {
  const { theme } = useTema();
  const { token, user, projeto } = useAuth();
  const [isChatOpen, setIsChatOpen] = useState(false);

  useEffect(() => {
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    } else {
      delete axios.defaults.headers.common["Authorization"];
    }

    const requestInterceptor = axios.interceptors.request.use((config) => {
      if (user && user._id) {
        config.headers.user = user._id;
      }
      if (projeto && projeto._id) {
        if (!config.headers.hasOwnProperty("projeto")) {
          config.headers.projeto = projeto._id;
        }
      }
      return config;
    });

    return () => {
      axios.interceptors.request.eject(requestInterceptor);
    };
  }, [token, user, projeto]);

  const toggleChat = () => {
    setIsChatOpen((prev) => !prev);
  };

  return (
    <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
      <Container>
        <PDF />
        <XML />
        <Projetos />
        <NewProject />
        <Tools />
        <Users />
        <Header />
        {projeto._id === undefined && <Auth />}
        <Dash />
      </Container>
    </ThemeProvider>
  );
}

export default App;
