import styled from "styled-components";

export const ModalOverlay = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;

`;

export const Container = styled.div`
  width: max-content;
  width: 20em;
  height: 10em;
  display: flex;
  flex-direction: column;
  font-size: 1.2em;
  border-radius: 10px;
  z-index: 2;
  background-color: ${({ theme }) => theme.custom};
  border: 1px solid ${({ theme }) => theme.border5};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

export const Header = styled.div`
  .user {
    width: 100%;
    height: calc(4em - 2px);
    border-bottom: 1px solid ${({ theme }) => theme.border3} !important;
    display: flex;

    .thumb {
      width: 4em;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: 1px solid ${({ theme }) => theme.border3};

      .circle {
        width: 60%;
        height: 60%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        border: 1px solid ${({ theme }) => theme.border3};
      }
    }

    .info {
      width: calc(100% - 4em);
      height: 100%;
      display: flex;

      .info-content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        p {
          height: 25%;
          margin-left: 15px;
          color: white;
          font-size: 0.9em;
          display: flex;
          align-items: center;
          opacity: 0.6;

          &:last-child {
            font-size: 0.7em;
          }
        }
      }
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  height: calc(100% - 4em);
  display: flex;
  flex-direction: column;

  div {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2em;

    p {
      font-size: 0.7em;
      opacity: 0.8;
    }

    div {
      width: 5em;
      height: 1.5em;
      background-color: #a81212a1;
      border-radius: 5px;
      opacity: 0.8;
      cursor: pointer;
      transition: all 0.2s;

      p {
        font-size: 0.6em;
        color: white;
      }

      &:hover {
        opacity: 1;
      }

      &:first-child {
        background-color: #212121;
      }
    }
  }
`;
