import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
`;

export const Card = styled.div`
  width: max-content;
  width: 20em;
  height: 10em;
  display: flex;
  flex-direction: column;
  font-size: 1.2em;
  border-radius: 7px;
  z-index: 2;
  background-color: ${({ theme }) => theme.custom4};
  border: 1px solid ${({ theme }) => theme.border5};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding-bottom: 1em;
  background-color: ${({ theme }) => theme.custom2};
  
  .header {
    width: 100%;
    height: 1.5em;
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${({ theme }) => theme.border3};
    background-color: ${({ theme }) => theme.custom};
    position: relative;
    justify-content: center;

    svg {
      font-size: 0.8em;
      margin-right: 10px;
    }

    p {
      font-size: 0.8em;
      font-weight: 600;
    }
    .close-btn {
      width: 1.5em;
      height: 100%;
      position: absolute;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border-right: 1px solid ${({ theme }) => theme.border3};
      background-color: ${({ theme }) => theme.custom};

      svg {
        margin: 0;
        font-size: 0.7em;
        opacity: 0.7;
      }
    }
  }

  .content {
    width: 100%;
    min-height: 6em;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      width: 80%;
      padding: 2em;
      font-size: 0.8em;
      font-weight: 600;
    }
  }

  .buttons {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .btn {
      width: max-content;
      min-height: 1.5em;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      margin-right: 1em;
      opacity: 0.7;
      border: 2px solid ${({ theme }) => theme.border5};
      cursor: pointer;
      transition: all 0.1s;

      &:hover {
        opacity: 1;
      }
      &.delete {
        background-color: rgba(200, 0, 0, 1);
        color: white;
        border: none;
      }
      p {
        padding: 0 20px;
        font-weight: 600;
        font-size: 0.8em;
      }
    }
  }
`;
