import styled from "styled-components";

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: none;
  align-items: center;
  justify-content: center;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
  transform: translateY(-9em);
`;

export const Card = styled.div`
  width: 25em;
  height: 30em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${({ theme }) => theme.custom};
  border: 1px solid ${({ theme }) => theme.border3};
  border-radius: 5px;
`;
