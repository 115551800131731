// styles.js

import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 6em);
  display: flex;
  transform: translateY(6em);
`;

export const Side = styled.div`
  min-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  &.left {
    min-width: 28em;
    height: 100%;
    border-right: 1px solid ${({ theme }) => theme.border3};

    .header {
      width: 100%;
      height: 3em;
      display: flex;
      align-items: center;
      border-bottom: 1px solid ${({ theme }) => theme.border3};

      .search {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        margin: 0.5em;
        margin-right: 1.2em;
        input {
          height: 2em;
          width: 88%;
          font-size: 0.85em;
        }

        svg {
          font-size: 0.8em;
          transform: translateX(2em);
        }
      }
    }

    .display {
      width: 100%;
      height: calc(100% - 3em);
      display: flex;
      flex-direction: column;
      overflow: overlay;
      align-items: center;

      .card {
        margin-top: 1em;
        width: 90%;
        height: max-content;
        padding-bottom: 0.5em;
        background-color: ${({ theme }) => theme.custom};
        border-radius: 5px;
        border: 1px solid ${({ theme }) => theme.border3};
        cursor: pointer;
        opacity: 0.9;
        transition: opacity 0.3s ease;

        &:hover {
          opacity: 1;
        }

        .header {
          width: 100%;
          height: 3.7em;
          display: flex;
          align-items: center;

          .thumb {
            width: 3.7em;
            height: 3.7em;
            display: flex;
            align-items: center;
            justify-content: center;
            border-right: 1px solid ${({ theme }) => theme.border3};
            position: relative;

            .cube,
            .download {
              position: absolute;
              font-size: 1em;
              transition: opacity 0.3s ease, transform 0.3s ease;
            }

            .cube.hidden {
              opacity: 0;
              transform: scale(0.8);
            }

            .download.visible {
              opacity: 1;
              transform: scale(1);
            }

            .cube {
              opacity: 1;
            }

            .download {
              opacity: 0;
              transform: scale(0.8);
            }
          }

          .content {
            width: calc(100% - 3.7em);
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;

            p {
              font-size: 0.85em;
              font-weight: 500;
              line-height: 17px;
              margin-left: 10px;

              &.name {
              }

              &.cnpj {
                font-size: 0.8em;
                font-weight: 400;
                opacity: 0.8;
              }
            }
          }
        }

        .infos {
          width: 100%;
          height: max-content;
          display: flex;
          flex-direction: column;

          .item {
            width: 100%;
            height: 2em; /* Define a altura máxima para cada item */
            display: flex;
            align-items: center;
            padding: .5em 10px;
            border-top: 1px solid ${({ theme }) => theme.border3};

            &:first-child {
              border-top: none;
            }

            .tag {
              width: 7em;
              display: flex;
              height: 100%;
              align-items: center;

              svg {
                margin-left: 5px;
                margin-right: 10px;
                font-size: 0.95em;
              }

              p {
                font-size: 0.8em;
                font-weight: 600;
                transform: translateY(1px);
              }
            }
            .value {
              min-width: 3em;
              padding-top: 0.5em;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 1em;
              opacity: 0.8;

              &:hover {
                opacity: 1;
              }

              svg {
                font-size: 0.8em;
              }

              p {
                padding-left: 10px;
                font-weight: 500;
                font-size: 0.9em;
                transform: translateY(1px);
              }
            }
          }
        }
      }
    }
  }

  .cards {
    width: 100%;
    display: flex;
    min-height: 6em;
    border-bottom: 1px solid ${({ theme }) => theme.border3};
    align-items: center;

    .card {
      margin-left: 1em;
      width: 15em;
      height: 4em;
      background-color: ${({ theme }) => theme.custom};
      border-radius: 5px;
      border: 1px solid ${({ theme }) => theme.border3};
      padding: 5px 2px;
      .header {
        width: 100%;
        height: 1.5em;
        display: flex;
        border-bottom: 1px solid ${({ theme }) => theme.border3};
        align-items: center;

        svg {
          fill: black;
          color: white;
          margin-left: 10px;
          opacity: 0.5;
          font-size: 0.85em;
        }

        p {
          font-size: 0.85em;
          margin-left: 10px;
        }
      }

      .content {
        width: 100%;
        height: calc(100% - 1.5em);
        display: flex;
        align-items: center;

        p {
          font-size: 1.2em;
          margin-left: 10px;
        }
      }
    }
  }

  .grafs {
    width: 100%;
    min-height: 23em;
    display: flex;
    border-bottom: 1px solid ${({ theme }) => theme.border3};
    align-items: center;

    .header {
      width: 100%;
      height: 2.5em;
      display: flex;
      align-items: center;
      background-color: ${({ theme }) => theme.custom};
      border-bottom: 1px solid ${({ theme }) => theme.border3};
      p {
        font-size: 0.85em;
        margin-left: 10px;
        transform: translateY(1px);
      }

      svg {
        margin-left: 15px;
        font-size: 0.9em;
        opacity: 0.5;
      }
    }
    .graf {
      width: max-content;
      height: 90%;
      display: flex;
      background-color: white;
      flex-direction: column;
      margin-left: 1em;

      .display {
        width: max-content;
        height: calc(100% - 2.5em);
        display: flex;
        align-items: flex-end;
      }
    }
    .index {
      width: 18em;
      margin-left: 1em;
      height: 90%;
      border-radius: 5px;
      border: 1px solid ${({ theme }) => theme.border3};
      background-color: ${({ theme }) => theme.custom3};
      display: flex;
      flex-direction: column;

      .display {
        width: 100%;
        height: calc(100% - 2.5em);
        display: flex;
        flex-direction: column;

        .item {
          width: 100%;
          height: 3em;
          display: flex;
          align-items: center;
          background-color: ${({ theme }) => theme.custom};
          opacity: 0.9;
          border-bottom: 1px solid ${({ theme }) => theme.border3};
          &:hover {
            opacity: 1;
          }

          .thumb {
            width: 3em;
            height: 3em;
            display: flex;
            align-items: center;
            justify-content: center;
            border-right: 1px solid ${({ theme }) => theme.border3};
          }

          .content {
            width: calc(100% - 2.2em);
            height: 100%;
            padding: 0 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            p {
              font-size: 0.8em;
              line-height: 15px;

              &.name {
                font-weight: 500;
              }

              &.cnpj {
                font-size: 0.7em;
                opacity: 0.7;
              }
            }
          }
        }
      }
    }
  }

  .table {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .thumb {
      width: 1.7em;
      height: 100%;
      display: flex;
      align-items: center;
      border-right: 1px solid ${({ theme }) => theme.border3};
      justify-content: center;
      svg {
        font-size: 0.8em;
      }
    }

    .header {
      width: 100%;
      height: 1.7em;
      display: flex;
      align-items: center;
      background-color: ${({ theme }) => theme.custom};
      border-bottom: 1px solid ${({ theme }) => theme.border3};

      .thumb svg {
        opacity: 0.5;
      }

      .item {
        width: 12em;
        height: 100%;
        display: flex;
        align-items: center;
        border-right: 1px solid ${({ theme }) => theme.border3};
        svg {
          font-size: 0.7em;
          margin-left: 10px;
          opacity: 0.5;
        }
        p {
          font-size: 0.8em;
          font-weight: 500;
          opacity: 0.85;
          margin-left: 10px;
        }

        &.desc {
          width: 20em;
        }
      }
    }

    .display {
      width: 100%;
      height: calc(100% - 1.7em);
      display: flex;
      flex-direction: column;
      background-color: ${({ theme }) => theme.custom4};
    }
  }
`;
