import { useEffect, useState } from "react";
import axios from "axios";
import { IoArrowBack, IoTrash, IoCubeOutline } from "react-icons/io5";
import { Card } from "../../styles";
import { useAuth } from "../../../../../hooks/auth";
import { useUsers } from "../../../../../hooks/users";
import { useProjects } from "../../../../../hooks/projects";

import { AiOutlineUser } from "react-icons/ai";
import { MdAlternateEmail } from "react-icons/md";
import { CiLock } from "react-icons/ci";
import { PiBlueprintLight } from "react-icons/pi";
import { PiIdentificationBadge } from "react-icons/pi";
import { MdOutlineAdminPanelSettings } from "react-icons/md";
import { GoShieldCheck } from "react-icons/go";
import { FiUser } from "react-icons/fi";
import { FaCrown, FaCheck } from "react-icons/fa";
import { TbEditCircle } from "react-icons/tb";

export default function EditUser({ setStatus, selectedUser }) {
  const [name, setName] = useState(selectedUser.name);
  const [email, setEmail] = useState(selectedUser.email);
  const [password, setPassword] = useState();
  const [cargo, setCargo] = useState(selectedUser.cargo);
  const [sortedProjects, setSortedProjects] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const { token } = useAuth();
  const { fetchUsers } = useUsers();
  const {
    projectsByUser,
    userProjects,
    fetchProjectsByUser,
    fetchUserProjects,
  } = useProjects();

  // Fetch projects and user projects on mount
  useEffect(() => {
    fetchProjectsByUser(selectedUser);
    fetchUserProjects();
  }, []);

  // Combina os projetos e remove duplicatas (se necessário), então organiza
  useEffect(() => {
    const initializeProjects = () => {
      const combinedProjects = [...projectsByUser, ...userProjects];
      const uniqueProjects = combinedProjects.reduce((acc, project) => {
        if (!acc.find((p) => p._id === project._id)) {
          acc.push(project);
        }
        return acc;
      }, []);

      const sorted = uniqueProjects.sort((a, b) =>
        a.nome.localeCompare(b.nome)
      );
      setSortedProjects(sorted);
      setSelectedProjects(sorted.map((project) => project._id)); // Armazena os _id diretamente
    };

    initializeProjects();
  }, [projectsByUser, userProjects]);

  function handleAlert() {
    document.getElementById("alert").style.display = "flex";
    document.getElementById("delete").addEventListener("click", handleDelete);
  }

  const handleDelete = async () => {
    try {
      setStatus("list");
      fetchUsers();
      document.getElementById("alert").style.display = "none";
    } catch (error) {
      console.error("Erro ao deletar o usuário:", error);
    }
  };

  const submit = async () => {
    try {
      const data = { userId: selectedUser._id, userData: {} };

      if (name !== selectedUser.name) {
        data.userData.name = name;
      }

      if (email !== selectedUser.email) {
        data.userData.email = email;
      }

      if (password) {
        data.userData.senha = password;
      }

      if (cargo !== selectedUser.cargo) {
        data.userData.cargo = cargo;
      }

      const initialProjectIds = projectsByUser
        .map((project) => project._id)
        .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));

      setSelectedProjects(
        selectedProjects.sort((a, b) =>
          a.toLowerCase().localeCompare(b.toLowerCase())
        )
      );

      const isProjectsChanged =
        JSON.stringify(selectedProjects) !== JSON.stringify(initialProjectIds);

      if (isProjectsChanged) {
        data.projetosIds = selectedProjects;
      }

      if (Object.keys(data).length > 1) {
        await axios.put(`https://dev.vidal-app.com/users/edit-user`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      }

      await fetchUsers();
      await fetchUserProjects();

      setStatus("list");
    } catch (error) {
      console.error("Erro ao atualizar usuário:", error);
    }
  };

  useEffect(() => {
    setName(selectedUser.name);
    setEmail(selectedUser.email);
    return () => {
      const deleteButton = document.getElementById("delete");
      if (deleteButton) deleteButton.removeEventListener("click", handleDelete);
    };
  }, [selectedUser]);

  const handleSelectProject = (projectId) => {
    setSelectedProjects((prevSelectedProjects) => {
      if (prevSelectedProjects.includes(projectId)) {
        return prevSelectedProjects.filter((id) => id !== projectId);
      } else {
        return [...prevSelectedProjects, projectId];
      }
    });
  };

  const handleSelectCargo = (selectedCargo) => {
    setCargo(selectedCargo);
  };

  return (
    <Card>
      <div className="header">
        <div className="btn" onClick={() => setStatus("list")}>
          <IoArrowBack />
        </div>
        <TbEditCircle />
        <p>Editando usuário</p>
      </div>
      <div className="display">
        <div className="item ativ">
          <div className="thumb">
            <div
              className="circle"
              style={{ backgroundColor: selectedUser.color }}
            >
              <p>{selectedUser.name.charAt(0).toUpperCase()}</p>
            </div>
          </div>
          <div className="content">
            <p>{selectedUser.name}</p>
            <p className="email">{selectedUser.email}</p>
            <IoTrash onClick={handleAlert} />
          </div>
        </div>
        <div className="tag">
          <PiIdentificationBadge />
          <p>Identificação</p>
        </div>
        <div className="input">
          <AiOutlineUser />
          <input value={name} onChange={(e) => setName(e.target.value)} />
        </div>
        <div className="input">
          <MdAlternateEmail />
          <input value={email} onChange={(e) => setEmail(e.target.value)} />
        </div>
        <div className="input">
          <CiLock />
          <input
            placeholder="Senha"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="tag">
          <MdOutlineAdminPanelSettings />
          <p>Permissões</p>
        </div>
        <div className="picker">
          <div
            className={`pick user ${cargo === "user" ? "ativ" : ""}`}
            onClick={() => handleSelectCargo("user")}
          >
            <GoShieldCheck />
            <p>Usuário</p>
          </div>
          <div
            className={`pick cliente ${cargo === "cliente" ? "ativ" : ""}`}
            onClick={() => handleSelectCargo("cliente")}
          >
            <FiUser />
            <p>Cliente</p>
          </div>
          <div
            className={`pick adm ${cargo === "admin" ? "ativ" : ""}`}
            onClick={() => handleSelectCargo("admin")}
          >
            <FaCrown />
            <p>Administrador</p>
          </div>
        </div>

        <div className="tag">
          <PiBlueprintLight />
          <p>Projetos</p>
        </div>

        <div className="display">
          {sortedProjects.map((project) => {
            const isSelected = selectedProjects.includes(project._id);
            return (
              <div
                className="card"
                key={project._id}
                onClick={() => handleSelectProject(project._id)}
                style={{
                  opacity: isSelected ? 1 : 0.4,
                }}
              >
                <div className="thumb">
                  <IoCubeOutline color={project.color} />
                </div>
                <div className="content">
                  <p>{project.nome}</p>
                  <p>{project.cnpj}</p>
                </div>
                <div
                  className="check"
                  style={{
                    color: isSelected ? "green" : "gray",
                  }}
                >
                  <FaCheck />
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="bottom">
        <div
          className="btn"
          onClick={() => {
            submit();
          }}
        >
          <p>SALVAR</p>
        </div>
        <div id="alert" style={{ display: "none" }}>
          <p>Tem certeza que deseja excluir?</p>
          <button id="delete">Confirmar Exclusão</button>
          <button
            onClick={() =>
              (document.getElementById("alert").style.display = "none")
            }
          >
            Cancelar
          </button>
        </div>
      </div>
    </Card>
  );
}
