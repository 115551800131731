import React, { useState, useEffect } from "react";
import { Container, Display, Filter, Head, Menu } from "./styles";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
} from "react-icons/md";
import { CiCalendar } from "react-icons/ci";
import axios from "axios";
import { useReports } from "@hooks/report";



export default function Mensal() {
  const {
    mensal: data,
    ano,
    setAno,
    mes,
    setMes,
    fetchData,
    handleMonthChange,
  } = useReports();


  return (
    <Container>
      <Menu>
        <div className="btn ativ">
          <svg
            aria-hidden="true"
            height="16"
            viewBox="0 0 16 16"
            version="1.1"
            width="16"
            data-view-component="true"
            className="octicon octicon-table UnderlineNav-octicon d-none d-sm-inline"
          >
            <path
              fill="white"
              d="M0 1.75C0 .784.784 0 1.75 0h12.5C15.216 0 16 .784 16 1.75v12.5A1.75 1.75 0 0 1 14.25 16H1.75A1.75 1.75 0 0 1 0 14.25ZM6.5 6.5v8h7.75a.25.25 0 0 0 .25-.25V6.5Zm8-1.5V1.75a.25.25 0 0 0-.25-.25H6.5V5Zm-13 1.5v7.75c0 .138.112.25.25.25H5v-8ZM5 5V1.5H1.75a.25.25 0 0 0-.25.25V5Z"
            ></path>
          </svg>
          <p>Emissão Mensal</p>
        </div>
      </Menu>
      <Head>
        <div className="calendar">
          <div className="btn" onClick={() => handleMonthChange("down")}>
            <MdOutlineKeyboardArrowDown />
          </div>
          <div className="btn" onClick={() => handleMonthChange("up")}>
            <MdOutlineKeyboardArrowUp />
          </div>
          <div className="date">
            <p>
              {new Date(ano, mes)
                .toLocaleString("default", { month: "long", year: "numeric" })
                .toUpperCase()}
            </p>
          </div>
        </div>
        {data && (
          <div className="infos">
            <div className="info">
              <div className="tag">
                <p>Total</p>
              </div>
              <div className="content">
                <p>{data.monthlyTotal}</p>
              </div>
            </div>
            <div className="info">
              <div className="tag">
                <p>Baixadas</p>
              </div>
              <div className="content">
                <p>{data.monthlyBaixadas}</p>
              </div>
            </div>
            <div className="info">
              <div className="tag">
                <p>Faltantes</p>
              </div>
              <div className="content">
                <p>{data.monthlyFaltantes}</p>
              </div>
            </div>
            <div className="info">
              <div className="tag">
                <p>Canceladas</p>
              </div>
              <div className="content">
                <p>{data.monthlyCanceladas}</p>
              </div>
            </div>
          </div>
        )}
      </Head>
 
      <Filter>
        <div className="item data">
          <CiCalendar />
          <p>Data</p>
        </div>
        <div className="item data">
          <CiCalendar />
          <p>Total</p>
        </div>
        <div className="item data">
          <CiCalendar />
          <p>Baixadas</p>
        </div>
        <div className="item data">
          <CiCalendar />
          <p>Faltantes</p>
        </div>
        <div className="item data">
          <CiCalendar />
          <p>Canceladas</p>
        </div>
      </Filter>

      <Display>
        {data &&
          Object.keys(data.reportsByDay).map((day) => (
            <React.Fragment key={day}>
              <div className="items">
                <div className="item ">
                  <p>{`${String(day).padStart(2, "0")}/${String(
                    mes + 1
                  ).padStart(2, "0")}/${ano}`}</p>
                </div>
                <div className="item">
                  <p>{data.reportsByDay[day].total}</p>
                </div>
                <div className="item">
                  <p>{data.reportsByDay[day].baixadas}</p>
                </div>
                <div className="item">
                  <p>{data.reportsByDay[day].faltantes}</p>
                </div>
                <div className="item">
                  <p>{data.reportsByDay[day].canceladas}</p>
                </div>
              </div>
            </React.Fragment>
          ))}
      </Display>
    </Container>
  );
}
