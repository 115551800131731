import { useEffect, useState } from "react";
import axios from "axios";
import { Card, Container } from "../../styles";

import { IoArrowBack, IoSearch, IoCubeOutline } from "react-icons/io5";
import { FiUserPlus } from "react-icons/fi";
import { PiIdentificationBadge, PiBlueprintLight } from "react-icons/pi";
import { FaPlus, FaCheck } from "react-icons/fa";
import { AiOutlineUser } from "react-icons/ai";
import { LuFolderSearch } from "react-icons/lu";

import { useAuth } from "../../../../../hooks/auth";
import { useUsers } from "../../../../../hooks/users";

export default function NewProject({ setStatus }) {
  const [name, setName] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchedUser, setSearchedUser] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const { token, projeto, user } = useAuth();
  const { fetchUsers } = useUsers();

  const handleClose = () => {
    document.getElementById("create-projetos").style.display = "none";
  };

  const handleUsersFromProject = async (email) => {
    try {
      const response = await axios.post(
        `https://dev.vidal-app.com/users/email`,
        { email },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            projeto: projeto?._id,
            user: user._id,
          },
        }
      );

      if (response.data && response.data.name) {
        setSearchedUser(response.data);
        console.log(`Usuário encontrado: ${response.data.name}`);
      } else {
        console.error("Usuário não encontrado.");
      }
    } catch (error) {
      console.error("Erro ao buscar usuário:", error);
    }
  };

  useEffect(() => {
    if (searchTerm) {
      const delayDebounceFn = setTimeout(() => {
        handleUsersFromProject(searchTerm);
      }, 500);
      return () => clearTimeout(delayDebounceFn);
    }
    setSearchedUser(null);
  }, [searchTerm]);

  const handleSelectUser = (user) => {
    if (!selectedUsers.includes(user)) {
      setSelectedUsers([...selectedUsers, user]);
      setSearchedUser(null);
    }
  };

  const handleRemoveUser = (user) => {
    const updatedUsers = selectedUsers.filter((u) => u !== user);
    setSelectedUsers(updatedUsers);
  };

  const submit = async () => {
    try {
      const response = await axios.post(
        "https://dev.vidal-app.com/projetos",
        {
          nome: name,
          cnpj: cnpj,
          users: selectedUsers.map((user) => user._id),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Response:", response.data);
    } catch (err) {
      console.error("Error occurred during request:", err);
    }
  };

  return (
    <Container id="create-projetos">
      <Card>
        <div className="header">
          <div className="btn" onClick={handleClose}>
            <IoArrowBack />
          </div>
          <FiUserPlus />
          <p>Criando Projeto </p>
        </div>

        <div className="display">
          <div className="item ativ">
            <div className="thumb">
              <div className="circle">
                <p>{name.charAt(0).toUpperCase()}</p>
                <FaPlus />
              </div>
            </div>
            <div className="content">
              <p>{name}</p>
              <p className="cnpj">{cnpj}</p>
            </div>
          </div>

          <div className="tag">
            <PiIdentificationBadge />
            <p>Identificação</p>
          </div>

          <div className="input">
            <AiOutlineUser />
            <input
              placeholder="Nome do usuário"
              value={name}
              onChange={(e) => setName(e.target.value)}
              autocomplete="off"
            />
          </div>

          <div className="input">
            <LuFolderSearch />

            <input
              placeholder="CNPJ"
              value={cnpj}
              onChange={(e) => setCnpj(e.target.value)}
              autocomplete="off"
            />
          </div>

          <div className="tag">
            <PiBlueprintLight />
            <p>Usuários</p>
          </div>
          <div className="search">
            <IoSearch />
            <input
              placeholder="Digite o nome do usuário"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleUsersFromProject(e.target.value);
                }
              }}
            />
          </div>
          <div className="display">
            {selectedUsers.map((user) => (
              <div
                key={user._id}
                className="card"
                onClick={() => handleRemoveUser(user)}
                style={{ opacity: 1 }}
              >
                <div className="thumb">
                  <div
                    className="circle"
                    style={{ backgroundColor: user.color }}
                  >
                    <p>{user.name[0].toUpperCase()}</p>
                  </div>
                </div>
                <div className="content">
                  <p>{user.name}</p>
                  <p>{user.email}</p>
                </div>
                <div className="check">
                  <FaCheck style={{ color: "green" }} />
                </div>
              </div>
            ))}
            {searchedUser && (
              <div
                className="card"
                onClick={() => handleSelectUser(searchedUser)}
                style={{
                  opacity: selectedUsers.includes(searchedUser._id) ? 1 : 0.4,
                }}
              >
                <div className="thumb">
                  <div
                    className="circle"
                    style={{ backgroundColor: searchedUser.color }}
                  >
                    <p>{searchedUser.name[0].toUpperCase()}</p>
                  </div>
                </div>
                <div className="content">
                  <p>{searchedUser.name}</p>
                  <p>{searchedUser.email}</p>
                </div>
                <div className="check">
                  <FaCheck style={{ color: "gray" }} />
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="bottom">
          <div className="btn" onClick={submit}>
            <p>CRIAR</p>
          </div>
        </div>
      </Card>
    </Container>
  );
}

//criar array vazio inicial para o projeto; pesquisar pelo email; armazenar no array de usuários do projeto; criar função in/out para marcar o check dos usuários do array; enviar para rota
