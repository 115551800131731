import React, { useState, useEffect, useCallback } from "react";
import { Container, Card, TabHeaders, TabContent, Footer } from "./styles";
import { IoMdClose } from "react-icons/io";
import { LuFilter } from "react-icons/lu";
import { BsCalendar4Event } from "react-icons/bs";
import { GoSearch } from "react-icons/go";
import { IoCreateOutline } from "react-icons/io5";
import { IoCubeOutline } from "react-icons/io5";
import { VscSend } from "react-icons/vsc";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { IoBugOutline } from "react-icons/io5";
import axios from "axios";
import debounce from "lodash/debounce";
import { useAuth } from "@hooks/auth";
import { toast } from "react-toastify";

export default function Filtro({ onFilterApply, onClose }) {
  const [abortController, setAbortController] = useState(null);
  const [isApplyingFilter, setIsApplyingFilter] = useState(false);
  // Estados principais
  const [activeTab, setActiveTab] = useState("periodo");

  // Função para carregar filtros salvos
  const loadSavedFilters = () => {
    const savedFilters =
      JSON.parse(localStorage.getItem("selectedFilters")) || {};
    return {
      startDate: savedFilters.startDate || {
        year: null,
        month: null,
        day: null,
      },
      endDate: savedFilters.endDate || { year: null, month: null, day: null },
      emiDoc: savedFilters.emiDoc || "",
      destDoc: savedFilters.destDoc || "",
      cfop: savedFilters.cfop || "",
      type: savedFilters.type || "",
      status: savedFilters.status || "",
    };
  };

  // Inicialize os estados com filtros salvos ou valores padrão
  const [startDate, setStartDate] = useState(loadSavedFilters().startDate);
  const [endDate, setEndDate] = useState(loadSavedFilters().endDate);
  const [emiDoc, setEmiDoc] = useState(loadSavedFilters().emiDoc);
  const [destDoc, setDestDoc] = useState(loadSavedFilters().destDoc);
  const [cfop, setCfop] = useState(loadSavedFilters().cfop);
  const [type, setType] = useState(loadSavedFilters().type);
  const [status, setStatus] = useState(loadSavedFilters().status);

  // Estados para sugestões e loading
  const [emiSuggestions, setEmiSuggestions] = useState([]);
  const [destSuggestions, setDestSuggestions] = useState([]);
  const [cfopSuggestions, setCfopSuggestions] = useState([]);
  const [isLoadingEmi, setIsLoadingEmi] = useState(false);
  const [isLoadingDest, setIsLoadingDest] = useState(false);
  const [isLoadingCfop, setIsLoadingCfop] = useState(false);
  const { token, projeto } = useAuth();

  const isValidDate = (dateObj) => {
    return (
      dateObj &&
      typeof dateObj.year === "number" &&
      typeof dateObj.month === "number" &&
      typeof dateObj.day === "number" &&
      dateObj.year !== null &&
      dateObj.month !== null &&
      dateObj.day !== null
    );
  };

  // Função para fechar o modal
  function closeModal() {
    document.getElementById("filter").style.display = "none";
  }

  // Funções de busca
  const searchEmitentes = async (query) => {
    if (!query || query.length < 2) {
      setEmiSuggestions([]);
      return;
    }

    setIsLoadingEmi(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/report/xml/suggestions`,
        {
          headers: { projeto: projeto._id },
          params: {
            query,
            type: "emitente",
          },
        }
      );

      if (response.data.success) {
        setEmiSuggestions(response.data.data.suggestions || []);
      }
    } catch (error) {
      console.error("Erro ao buscar emitentes:", error);
    }
    setIsLoadingEmi(false);
  };

  // Função para buscar destinatários
  const searchDestinatarios = async (query) => {
    if (!query || query.length < 2) {
      setDestSuggestions([]);
      return;
    }

    setIsLoadingDest(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/report/xml/suggestions`,
        {
          headers: { projeto: projeto._id },

          params: {
            query,
            type: "destinatario",
          },
        }
      );

      if (response.data.success) {
        setDestSuggestions(response.data.data.suggestions || []);
      }
    } catch (error) {
      console.error("Erro ao buscar destinatários:", error);
    }
    setIsLoadingDest(false);
  };

  // Função para buscar CFOPs
  const searchCfops = async (query) => {
    if (!query) {
      setCfopSuggestions([]);
      return;
    }

    setIsLoadingCfop(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/report/xml/suggestions`,
        {
          headers: { projeto: projeto._id },

          params: {
            query,
            type: "cfop",
          },
        }
      );

      if (response.data.success) {
        setCfopSuggestions(response.data.data.suggestions || []);
      }
    } catch (error) {
      console.error("Erro ao buscar CFOPs:", error);
    }
    setIsLoadingCfop(false);
  };

  // Funções com debounce
  const debouncedSearchEmitentes = useCallback(
    debounce(searchEmitentes, 300),
    []
  );

  const debouncedSearchDestinatarios = useCallback(
    debounce(searchDestinatarios, 300),
    []
  );

  const debouncedSearchCfops = useCallback(debounce(searchCfops, 300), []);

  // Handlers de seleção
  const handleSelectEmitente = (emitente) => {
    setEmiDoc(emitente.cnpj);
    setEmiSuggestions([]);
  };

  const handleSelectDestinatario = (destinatario) => {
    setDestDoc(destinatario.cnpj);
    setDestSuggestions([]);
  };

  const handleSelectCfop = (cfop) => {
    setCfop(cfop.codigo);
    setCfopSuggestions([]);
  };

  const handleTypeSelect = (selectedType) => {
    setType(type === selectedType ? "" : selectedType);
  };

  const handleStatusSelect = (selectedStatus) => {
    setStatus(status === selectedStatus ? "" : selectedStatus);
  };

  // Função para formatar data
  const formatDateForAPI = (dateObj) => {
    if (!isValidDate(dateObj)) return null;

    // Ajusta o mês para o formato correto (mês + 1 pois no objeto é 0-based)
    const month = (dateObj.month + 1).toString().padStart(2, "0");
    const day = dateObj.day.toString().padStart(2, "0");
    const year = dateObj.year;

    // Retorna no formato ISO com timezone
    return `${year}-${month}-${day}T00:00:00.000Z`;
  };

  // Função para aplicar filtros
  const applyFilters = async () => {
    const currentFilters = {
      startDate,
      endDate,
      emiDoc,
      destDoc,
      cfop,
      type,
      status,
    };

    try {
      // Cria um novo AbortController
      const controller = new AbortController();
      setAbortController(controller);

      // Notifica o início do loading e fecha o modal
      onFilterApply({
        isLoading: true,
        abortController: controller,
      });
      onClose();

      const params = new URLSearchParams();

      // Formata e adiciona as datas
      if (isValidDate(startDate)) {
        const formattedStartDate = formatDateForAPI(startDate);
        if (formattedStartDate) {
          params.append("startDate", formattedStartDate);
        }
      }

      if (isValidDate(endDate)) {
        const formattedEndDate = formatDateForAPI(endDate);
        if (formattedEndDate) {
          params.append("endDate", formattedEndDate);
        }
      }

      // Adiciona os outros filtros apenas se tiverem valor
      if (emiDoc?.trim()) {
        params.append("emiDoc", emiDoc.trim());
      }

      if (destDoc?.trim()) {
        params.append("destDoc", destDoc.trim());
      }

      if (cfop?.trim()) {
        params.append("cfop", cfop.trim());
      }

      if (type) {
        params.append("type", type);
      }

      if (status) {
        params.append("status", status);
      }

      // Adiciona paginação
      params.append("page", "1");
      params.append("pageSize", "100");

      console.log(
        "Enviando requisição com parâmetros:",
        Object.fromEntries(params)
      );

      const response = await axios.get(
        `${process.env.REACT_APP_API}/report/xml/search-filter`,
        {
          params,
          headers: { projeto: projeto._id },
          signal: controller.signal,
        }
      );

      if (response.data.success) {
        onFilterApply({
          hits: response.data.data.hits,
          total: response.data.data.total,
          hasMore: response.data.data.hasMore,
          activeFilters: currentFilters,
          totalDocs: response.data.data.totalDocs,
          fileId: response.data.data.fileId,
        });

        // Salva os filtros no localStorage
        localStorage.setItem("selectedFilters", JSON.stringify(currentFilters));
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled");
      } else {
        console.error("Erro ao fazer a requisição:", error);
        toast.error("Erro ao aplicar filtros");
      }
      onFilterApply({ isLoading: false, abortController: null });
    }
  };
  // Definição das abas
  const tabs = [
    { id: "periodo", label: "Período", icon: BsCalendar4Event },
    { id: "participantes", label: "Participantes", icon: IoCreateOutline },
    { id: "outros", label: "Outros", icon: IoIosInformationCircleOutline },
  ];

  return (
    <Container id="filter">
      <Card>
        <div className="header">
          <div className="btn">
            <IoMdClose onClick={onClose} /> {/* Usar onClose aqui também */}
          </div>
          <LuFilter />
          <p>Filtro</p>
        </div>
        <TabHeaders>
          {tabs.map((tab) => {
            const Icon = tab.icon;
            return (
              <div
                key={tab.id}
                className={`tab-header ${activeTab === tab.id ? "active" : ""}`}
                onClick={() => setActiveTab(tab.id)}
              >
                <Icon />
                <p>{tab.label}</p>
              </div>
            );
          })}
        </TabHeaders>
        <TabContent>
          {activeTab === "periodo" && (
            <div className="dates">
              <DatePicker
                label="Data Inicial"
                date={startDate}
                setDate={setStartDate}
              />
              <DatePicker
                label="Data Final"
                date={endDate}
                setDate={setEndDate}
              />
            </div>
          )}
          {activeTab === "participantes" && (
            <div className="parts">
              <div className="part">
                <div className="header">
                  <IoCreateOutline />
                  <p>Emitente</p>
                </div>
                <div className="search">
                  <GoSearch />
                  <input
                    placeholder="Procurar empresa"
                    value={emiDoc}
                    onChange={(e) => {
                      setEmiDoc(e.target.value);
                      debouncedSearchEmitentes(e.target.value);
                    }}
                  />
                </div>
                <div className="items">
                  {isLoadingEmi ? (
                    <div className="loading">Buscando...</div>
                  ) : (
                    emiSuggestions.map((emitente, index) => (
                      <div
                        key={index}
                        className="item"
                        onClick={() => handleSelectEmitente(emitente)}
                      >
                        <div className="thumb">
                          <IoCubeOutline />
                        </div>
                        <div className="content">
                          <p>{emitente.nome}</p>
                          <p>{emitente.cnpj}</p>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
              <div className="part">
                <div className="header">
                  <VscSend />
                  <p>Destinatário</p>
                </div>
                <div className="search">
                  <GoSearch />
                  <input
                    placeholder="Procurar empresa"
                    value={destDoc}
                    onChange={(e) => {
                      setDestDoc(e.target.value);
                      debouncedSearchDestinatarios(e.target.value);
                    }}
                  />
                </div>
                <div className="items">
                  {isLoadingDest ? (
                    <div className="loading">Buscando...</div>
                  ) : (
                    destSuggestions.map((destinatario, index) => (
                      <div
                        key={index}
                        className="item"
                        onClick={() => handleSelectDestinatario(destinatario)}
                      >
                        <div className="thumb">
                          <IoCubeOutline />
                        </div>
                        <div className="content">
                          <p>{destinatario.nome}</p>
                          <p>{destinatario.cnpj}</p>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          )}
          {activeTab === "outros" && (
            <div className="parts">
              <div className="part">
                <div className="header">
                  <IoBugOutline />
                  <p>CFOP</p>
                </div>
                <div className="search">
                  <GoSearch />
                  <input
                    placeholder="Procurar CFOP"
                    value={cfop}
                    onChange={(e) => {
                      setCfop(e.target.value);
                      debouncedSearchCfops(e.target.value);
                    }}
                  />
                </div>
                <div className="items">
                  {isLoadingCfop ? (
                    <div className="loading">Buscando...</div>
                  ) : (
                    cfopSuggestions.map((cfopItem, index) => (
                      <div
                        key={index}
                        className="item"
                        onClick={() => handleSelectCfop(cfopItem)}
                      >
                        <div className="thumb">
                          <IoCubeOutline />
                        </div>
                        <div className="content">
                          <p>{cfopItem.codigo}</p>
                          <p>{cfopItem.descricao}</p>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
              <div className="part">
                <div className="header">
                  <IoIosInformationCircleOutline />
                  <p>Tipo</p>
                </div>
                <div className="types">
                  <div
                    className={`type ${type === "55" ? "active" : ""}`}
                    onClick={() => handleTypeSelect("55")}
                  >
                    <p>NFe</p>
                  </div>
                  <div
                    className={`type ${type === "57" ? "active" : ""}`}
                    onClick={() => handleTypeSelect("57")}
                  >
                    <p>CTe</p>
                  </div>
                </div>
                <div className="header">
                  <IoIosInformationCircleOutline />
                  <p>Status</p>
                </div>
                <div className="types">
                  <div
                    className={`type ${
                      status === "Autorizada" ? "active" : ""
                    }`}
                    onClick={() => handleStatusSelect("Autorizada")}
                  >
                    <p>Autorizada</p>
                  </div>
                  <div
                    className={`type ${status === "Cancelada" ? "active" : ""}`}
                    onClick={() => handleStatusSelect("Cancelada")}
                  >
                    <p>Cancelada</p>
                  </div>
                  <div
                    className={`type ${
                      status === "Desconhecido" ? "active" : ""
                    }`}
                    onClick={() => handleStatusSelect("Desconhecido")}
                  >
                    <p>Desconhecido</p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </TabContent>
        <Footer>
          <button className="send" onClick={applyFilters}>
            Aplicar Filtros
          </button>
        </Footer>
      </Card>
    </Container>
  );
}

function DatePicker({ label, date, setDate }) {
  const [years, setYears] = useState([]);
  const [months] = useState([
    { name: "JAN", value: 0 },
    { name: "FEB", value: 1 },
    { name: "MAR", value: 2 },
    { name: "APR", value: 3 },
    { name: "MAY", value: 4 },
    { name: "JUN", value: 5 },
    { name: "JUL", value: 6 },
    { name: "AUG", value: 7 },
    { name: "SEP", value: 8 },
    { name: "OCT", value: 9 },
    { name: "NOV", value: 10 },
    { name: "DEC", value: 11 },
  ]);
  const [days, setDays] = useState([]);

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const startYear = currentYear - 12;
    const yearsArray = [];
    for (let y = startYear; y <= currentYear; y++) {
      yearsArray.push(y);
    }
    setYears(yearsArray);
  }, []);

  useEffect(() => {
    if (date.year !== null && date.month !== null) {
      const daysInMonth = new Date(date.year, date.month + 1, 0).getDate();
      const daysArray = [];
      for (let d = 1; d <= daysInMonth; d++) {
        daysArray.push(d);
      }
      setDays(daysArray);
    } else {
      setDays([]);
    }
  }, [date.year, date.month]);

  function handleYearClick(year) {
    setDate({ ...date, year, month: null, day: null });
  }

  function handleMonthClick(month) {
    setDate({ ...date, month, day: null });
  }

  function handleDayClick(day) {
    setDate({ ...date, day });
  }

  function formatDate() {
    const day = date.day !== null ? date.day.toString().padStart(2, "0") : "--";
    const month =
      date.month !== null ? (date.month + 1).toString().padStart(2, "0") : "--";
    const year = date.year !== null ? date.year.toString() : "----";
    return `${day}/${month}/${year}`;
  }

  return (
    <div className="date">
      <div className="current">
        <BsCalendar4Event />
        <p>{formatDate()}</p>
      </div>
      <div className="years">
        {years.map((year) => (
          <div
            className={`year ${date.year === year ? "ativ" : ""}`}
            key={year}
            onClick={() => handleYearClick(year)}
          >
            <p>{year}</p>
          </div>
        ))}
      </div>
      <div className="months">
        {months.map((month) => (
          <div
            className={`month ${date.month === month.value ? "ativ" : ""}`}
            key={month.value}
            onClick={() => handleMonthClick(month.value)}
          >
            <p>{month.name}</p>
          </div>
        ))}
      </div>
      <div className="days">
        {days.map((day) => (
          <div
            className={`day ${date.day === day ? "ativ" : ""}`}
            key={day}
            onClick={() => handleDayClick(day)}
          >
            <p>{day}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
