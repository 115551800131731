import { ModalOverlay, ModalContent } from "./styles";
import { MdClose } from "react-icons/md";

const Modal = ({ isOpen, onClose, title, children }) => {
  if (!isOpen) return null;

  return (
    <ModalOverlay onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <div className="header">
          <h2>{title}</h2>
          <MdClose className="close-btn" onClick={onClose} />
        </div>
        <div className="content">{children}</div>
      </ModalContent>
    </ModalOverlay>
  );
};

export default Modal;
