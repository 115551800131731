import { IoClose } from "react-icons/io5";
import { Card, Container } from "./styles";
import { GoAlert } from "react-icons/go";

export default function DeleteAlert({ show, handleClose, handleDelete }) {
  if (!show) return null; 

  return (
    <Container>
      <Card>
        <div className="header">
          <div className="close-btn" onClick={handleClose}>
            <IoClose />
          </div>
          <GoAlert />
          <p>Alerta</p>
        </div>
        <div className="content">
          <p>Tem certeza que deseja excluir?</p>
        </div>
        <div className="buttons">
          <div className="btn" onClick={handleClose}>
            <p>Cancelar</p>
          </div>
          <div className="btn delete" onClick={handleDelete}>
            <p>DELETAR</p>
          </div>
        </div>
      </Card>
    </Container>
  );
}
