import styled from "styled-components";

export const Container = styled.div`
  min-width: 100%;
  height: 15em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Box = styled.div`
  min-width: 83%;
  min-height: 90%;
  display: flex;
  flex-direction: column;
`;

export const Input = styled.div`
  width: 100%;
  height: 4em;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: white;

  p {
    font-size: 0.7em;
    opacity: 0.5;
  }

  svg {
    font-size: 0.7em;
    transform: translateY(2.4em) translateX(0.6em);
    opacity: 0.5;
  }

  input {
    background-color: rgba(15, 15, 15);
    border: 1px solid rgba(255, 255, 255, 0.05);
    margin-top: 10px;
    height: 2em;
    border-radius: 5px;
    padding: 5px 0;
    padding-left: 2em;
    color: white;
    font-size: 0.7em;


  }
`;

export const Send = styled.div`
  width: 100%;
  height: 4em;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
`;

export const Button = styled.div`
  width: max-content;
  height: 1.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #238636;
  border-radius: 5px;
  cursor: pointer;
  opacity: 0.9;
  transition: all 0.1s;

  &:hover {
    opacity: 1;
  }
  p {
    font-weight: 600;
    font-size: 0.65em;
    padding: 45px;
  }
`;
