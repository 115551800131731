export const lightTheme = {
  type: "dark",
  bar: "rgba(250, 250, 250)",
  primary: "#bb86fc",
  secondary: "#03dac6",
  tertiary: "#6200ea",
  header: "#121212",
  body: "#ECEFF4",
  body2: "#ECEFF4",
  text: "rgba(0, 0, 0, .7)",
  border: "rgba(0, 0, 0, 0.1)",
  border2: "rgba(0, 0, 0, 0.1)",
  border3: "rgba(0, 0, 0, 0.1)",
  border3: "rgba(0, 0, 0, 0.05)",
  border4: "rgba(0, 0, 0, 0.05)",
  border5: "rgba(0, 0, 0, 0.1)",
  border6: "rgba(0, 0, 0, 0.05)",
  border7: "#90ee9080", //Nova (verde)
  border8: "#949494", //Nova (cinza)
  border9: "#76c77680", //Nova (verde shadow)
  card: "rgba(255, 255, 255, .9)",
  card3: "rgba(255, 255, 255, .9)",
  card2: "rgba(255, 255, 255, 0.3)",
  card4: "rgba(255, 255, 255, .9)",
  contrast: "rgba(255, 255, 255, 0.02)",
  contrast2: "rgba(255, 255, 255, 0.8)",
  contrast3: "#a2a2a3", //Nova (cinza)
  header: "rgba(255, 255, 255, 0.8)",
  load: "#f3f3f3",
  load2: "#ecebeb",
  load3: "rgba(255, 255, 255, .5)",
  custom: "rgba(255, 255, 255, 1)",
  custom2: "rgba(255, 255, 255, .85)",
  custom3: "rgba(255, 255, 255, .75)",
  custom4: "rgba(255, 255, 255, .7)",
  custom5: "rgba(0, 0, 0, 0.03)",
  custom6: "rgba(255, 255, 255, .9)",
  custom7: "rgba(255, 255, 255, .9)",
  custom8: "#e6e6e6", //Nova (cinza)
};

export const darkTheme = {
  type: "light",
  bar: "rgba(7, 11, 7, .1)",
  primary: "#bb86fc",
  secondary: "#03dac6",
  tertiary: "#6200ea",
  header: "rgba(10, 14, 19)",
  body: "rgba(5, 7, 10, .975)",
  body2: "rgba(5, 7, 10, .4)",
  text: "#e0e0e0",
  border: "rgba(255, 255, 255, 0.2)",
  border2: "rgba(255, 255, 255, 0.3)",
  border3: "rgba(255, 255, 255, 0.05)",
  border4: "rgba(255, 255, 255, 0.15)",
  border5: "rgba(255, 255, 255, 0.05)",
  border6: "rgba(255, 255, 255, 0.1)",
  border7: "#90ee9080", //Nova (verde)
  border8: "#3F3E3E", //Nova (cinza)
  border9: "#76c77680", //Nova (verde shadow)
  card: "rgba(14, 14, 17, 0.76)",
  card3: "rgba(0, 0, 0, .3)",
  card2: "rgba(22, 27, 34)",
  card4: "rgba(0, 0, 0, .3)",
  contrast: "rgba(255, 255, 255, 0.02)",
  contrast2: "rgba(255, 255, 255, 0.05)",
  contrast3: "#333", //Nova (cinza)
  load: "rgba(0, 0, 0, .3)",
  load2: "rgba(0, 0, 0, .5)",
  load3: "rgba(0, 0, 0, .5)",
  custom: "rgba(5, 7, 10)",
  custom2: "rgba(5, 7, 10)",
  custom3: "rgba(5, 7, 10)",
  custom4: "rgba(5, 7, 10)",
  custom4: "rgba(5, 7, 10)",
  custom5: "rgba(0, 0, 0, 0.8)",
  custom6: "rgba(5, 7, 10, .5)",
  custom7: "rgba(255, 255, 255, .9)",
  custom8: "#05070a", //Nova (preto)
};
