import { IoClose, IoSearch } from "react-icons/io5";
import { Card, Container } from "../../styles";
import { useEffect, useState } from "react";
import { LuUsers } from "react-icons/lu";
import { useUsers } from "@hooks/users";
import { useAuth } from "@hooks/auth";
import { GoProjectRoadmap } from "react-icons/go";
import { CiEdit } from "react-icons/ci";

import { useProjects } from "@hooks/projects";

export default function List({ setStatus, handleClose, projeto, setProjeto }) {
  const { users } = useUsers();
  const { user } = useAuth();
  const { userProjects, fetchUserProjects } = useProjects();


  function handleChoose(projeto) {
    setStatus("edit_project");
    setProjeto(projeto);
  }

  return (
    <Card>
      <div className="header">
        <div className="btn" onClick={handleClose}>
          <IoClose />
        </div>
        <GoProjectRoadmap />

        <p>Projetos</p>
      </div>
      <div className="search">
        <IoSearch />
        <input placeholder="Procurar projeto" />
      </div>
      <div className="display">
        {userProjects.length > 0 &&
          userProjects.map((projeto) => (
            <div
              className="item"
              key={projeto.id}
              onClick={() => handleChoose(projeto)}
            >
              <div className="thumb">
                <div
                  className="circle"
                  style={{ backgroundColor: projeto.color }}
                >
                  <p>{projeto.nome.slice(0, 1)}</p>
                </div>
              </div>
              <div className="content">
                <p>{projeto.nome}</p>
                <p>{projeto.cnpj}</p>
              </div>
              <div className="edit">
                <CiEdit />
              </div>
            </div>
          ))}
      </div>
      <div className="bottom">
        {["god", "admin"].includes(user.cargo) && (
          <div className="btn" onClick={() => setStatus("new_user")}>
            <p>ADICIONAR</p>
          </div>
        )}
      </div>
    </Card>
  );
}
