import styled from "styled-components";

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const ModalContent = styled.div`
  width: max-content;
  min-width: 20em;
  height: max-content;
  min-height: 20em;
  display: flex;
  flex-direction: column;
  font-size: 1.2em;
  align-items: center;

  border-radius: 10px;
  z-index: 2;
  position: relative;
  background-color: ${({ theme }) => theme.custom};
  border: 1px solid ${({ theme }) => theme.border5};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  .header {
    width: 100%;
    height: 3em;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.custom6};
    border-bottom: 1px solid ${({ theme }) => theme.border5};
    position: relative;

    h2 {
      margin: 0;
      font-size: 1.2em;
      color: ${({ theme }) => theme.text};
    }

    .close-btn {
      position: absolute;
      cursor: pointer;
      opacity: 0.8;
      right: 10px;
      font-size: 1.2em;
      color: ${({ theme }) => theme.text};

      &:hover {
        opacity: 1;
      }
    }
  }

  .content {
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
    overflow-y: auto;
    p {
      margin: 0;
      font-size: 0.7em;
      color: ${({ theme }) => theme.text};
    }
  }
`;
