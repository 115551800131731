import React, { useState, useEffect } from "react";
import { Card, Display, DeleteButton } from "./styles";
import { FaPlus, FaRobot } from "react-icons/fa6";
import { MdOutlineBugReport } from "react-icons/md";
import { useAuth } from "@hooks/auth";
import { FaList } from "react-icons/fa6";
import { IoCubeOutline } from "react-icons/io5";
import { FiTrash } from "react-icons/fi";
import DropBox from "./components/dropbox";
import ListCreation from "./components/newlist/ListCreation";

const WorkerCard = ({ workerId }) => {
  const [workerData, setWorkerData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { projeto } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [lists, setLists] = useState([]);
  const [draggedItem, setDraggedItem] = useState(null);
  const [dragOverIndex, setDragOverIndex] = useState(null);

  // Adicionar uma nova lista
  const handleCreateList = (newList) => {
    setLists((prevLists) => [...prevLists, newList]);
  };

  // Remover uma lista pelo nome
  const handleRemoveList = (listName) => {
    setLists((prevLists) => prevLists.filter((list) => list.name !== listName));
  };

  // Iniciar o arraste de um item
  const handleDragStart = (e, index) => {
    setDraggedItem(lists[index]);
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.setData("text/plain", index);
  };

  // Quando um item é arrastado sobre outro
  const handleDragOver = (e, index) => {
    e.preventDefault();

    const itemBounds = e.target.getBoundingClientRect();
    const mouseY = e.clientY;
    const middleY = (itemBounds.top + itemBounds.bottom) / 2;
    const buffer = 15;

    // Atualiza o índice de arraste
    if (mouseY > middleY + buffer) {
      setDragOverIndex(index); // Para baixo
    } else if (mouseY < middleY - buffer) {
      setDragOverIndex(index - 1); // Para cima
    } else {
      setDragOverIndex(index); // Se estiver no meio, mantém o índice atual
    }
  };

  const handleDrop = (e, dropIndex) => {
    e.preventDefault();

    const draggedIndex = lists.indexOf(draggedItem);

    if (draggedIndex !== -1 && draggedItem) {
      const newLists = [...lists];
      newLists.splice(draggedIndex, 1); // Remove o item da posição original

      if (dropIndex === 0) {
        newLists.unshift(draggedItem);
      } else {
        newLists.splice(dropIndex, 0, draggedItem);
      }

      setLists(newLists);
    }

    setDraggedItem(null);
    setDragOverIndex(null);
  };

  const handleDragEnd = () => {
    setDraggedItem(null);
    setDragOverIndex(null);
  };

  function parseISODuration(duration) {
    if (!duration) {
      return "Duração desconhecida";
    }
    const regex = /PT(?:(\d+)H)?(?:(\d+)M)?(?:(\d+(?:\.\d+)?)S)?/;
    const matches = duration.match(regex);
    if (!matches) {
      return "Formato de duração inválido";
    }
    const hours = parseInt(matches[1] || 0, 10);
    const minutes = parseInt(matches[2] || 0, 10);
    return `${hours} horas e ${minutes} minutos`;
  }

  useEffect(() => {
    const ws = new WebSocket("wss://log.vidal-app.com/ws");

    ws.onopen = () => {
      console.log("Conectado ao servidor WebSocket");
      const requestMessage = {
        action: "getMessages",
        type: "worker",
        projectId: projeto._id,
        page: 1,
        limit: 1,
      };
      ws.send(JSON.stringify(requestMessage));
    };

    ws.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        if (data.success && data.messages && data.messages.length > 0) {
          setWorkerData(data.messages[0]);
        } else if (data.success && data.message) {
          setWorkerData(data.message);
        }
      } catch (error) {
        console.error("Erro ao processar a mensagem recebida:", error);
      } finally {
        setLoading(false);
      }
    };

    ws.onerror = (error) => {
      console.error("Erro de conexão:", error);
      setLoading(false);
    };

    return () => {
      ws.close();
    };
  }, [workerId, projeto._id]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  if (loading || !workerData) {
    return null;
  }

  return (
    <Display>
      <DropBox />
      <div className="header worker">
        <div className="side">
          <FaRobot />
          <p>WORKER {workerId}</p>
        </div>
      </div>
      <Card>
        <div className="task">
          <div className="side">
            <p style={{ marginLeft: "10px" }}>
              {workerData.status === 0 ? "Baixando NFe's" : "Parado"}
            </p>
          </div>
          <p>
            {Number(workerData.progress.current).toLocaleString()} /{" "}
            {Number(workerData.progress.total).toLocaleString()}
          </p>
        </div>
        <div className="load">
          <div
            className="progress"
            style={{
              width: `${
                (workerData.progress.current / workerData.progress.total) * 100
              }%`,
            }}
          ></div>
        </div>
        <div className="infos">
          <div className="header">
            <div className="side">
              <MdOutlineBugReport />
              <p>Informações</p>
            </div>
          </div>
          <div className="items">
            <div className="item">
              <p>
                Arquivos Totais:{" "}
                {Number(workerData.details.totalFiles).toLocaleString()}
              </p>
            </div>
            <div className="item last">
              <p>
                Baixados:{" "}
                {Number(workerData.details.downloaded).toLocaleString()}
              </p>
            </div>
            <div className="item">
              <p>
                Taxa de Download:{" "}
                {Number(workerData.details.downloadRate * 10)
                  .toFixed(0)
                  .toLocaleString()}{" "}
                / h
              </p>
            </div>
            <div className="item">
              <p>
                Previsão de Conclusão:{" "}
                {parseISODuration(workerData.details.estimatedCompletion)}
              </p>
            </div>
          </div>
        </div>
      </Card>
      <div className="header worker">
        <div className="side">
          <FaList />
          <p>Listas</p>
        </div>
        <div
          className="add-btn"
          onClick={handleOpenModal}
          style={{ cursor: "pointer" }}
        >
          <FaPlus />
        </div>
      </div>

      <div className="display">
        {lists.map((list, index) => (
          <div
            key={list.name}
            className={`list ${draggedItem === list ? "dragging" : ""}`}
            draggable={true}
            onDragStart={(e) => handleDragStart(e, index)}
            onDragOver={(e) => handleDragOver(e, index)}
            onDragEnd={handleDragEnd}
            onDrop={(e) => handleDrop(e, index)}
            style={{
              transition: "transform 0.3s",
              backgroundColor:
                draggedItem === list || dragOverIndex === index
                  ? "rgba(224, 224, 224, 0.5)"
                  : "transparent",
              transform: draggedItem === list ? "scale(1.05)" : "scale(1)",
            }}
          >
            <div
              style={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              <div className="thumb">
                <IoCubeOutline />
              </div>
              <div className="info" style={{ flexGrow: 1 }}>
                <q>{list.name}</q>
                <p>
                  Arquivos: {list.files.map((file) => file.name).join(", ")}
                </p>
              </div>
              <DeleteButton
                onClick={() => handleRemoveList(list.name)}
                style={{
                  marginLeft: "10px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FiTrash
                  style={{
                    width: "30px",
                    height: "17px",
                    fill: "none",
                  }}
                />
              </DeleteButton>
            </div>
          </div>
        ))}
      </div>

      {isModalOpen && (
        <ListCreation
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          onCreateList={handleCreateList}
        />
      )}
    </Display>
  );
};

export default WorkerCard;
