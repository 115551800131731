import React from "react";
import { IoMdClose, IoMdDownload } from "react-icons/io";
import { FaFilePdf } from "react-icons/fa"; // Add this import
import { toast } from "react-toastify";
import axios from "axios";
import {
  ModalContainer,
  ModalContent,
  Header,
  Title,
  ClearButton,
  FiltersList,
  FilterItem,
  FilterLabel,
  FilterValue,
  TotalCount,
  TotalLabel,
  TotalValue,
  DownloadButton,
  ButtonsContainer, // New styled component needed
  LoadingDots,
} from "./styles";
import { useAuth } from "@hooks/auth";

const ActiveFiltersModal = ({
  filters,
  onClearFilters,
  totalDocuments,
  isLoading,
  abortController,
}) => {
  const { projeto } = useAuth();

  const handleCancelSearch = () => {
    if (abortController) {
      // Cancela a requisição em andamento
      abortController.abort();
    }
    // Chama o onClearFilters para limpar os filtros e voltar aos documentos padrão
    onClearFilters();
  };

  const isValidDate = (dateObj) => {
    return (
      dateObj &&
      typeof dateObj.year === "number" &&
      typeof dateObj.month === "number" &&
      typeof dateObj.day === "number" &&
      dateObj.year !== null &&
      dateObj.month !== null &&
      dateObj.day !== null
    );
  };

  const formatDate = (dateObj) => {
    if (!isValidDate(dateObj)) return null;
    const months = [
      "Jan",
      "Fev",
      "Mar",
      "Abr",
      "Mai",
      "Jun",
      "Jul",
      "Ago",
      "Set",
      "Out",
      "Nov",
      "Dez",
    ];
    return `${String(dateObj.day).padStart(2, "0")} ${months[dateObj.month]} ${
      dateObj.year
    }`;
  };

  const getTypeLabel = (type) => {
    switch (type) {
      case "55":
        return "NFe";
      case "57":
        return "CTe";
      default:
        return type;
    }
  };

  const handleDownloadPDF = async () => {
    try {
      if (!filters.fileId) {
        toast.error("ID do arquivo não disponível");
        return;
      }

      const response = await axios.get(
        `${process.env.REACT_APP_API}/report/xml/download-pdf/${filters.fileId}`,
        {
          responseType: "blob",
          headers: {
            projeto: projeto._id,
          },
        }
      );

      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const timestamp = new Date().toISOString().replace(/[:.]/g, "-");
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `relatorio_nfe_${timestamp}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      toast.success("Relatório PDF baixado com sucesso!");
    } catch (error) {
      console.error("Erro ao baixar PDF:", error);
      toast.error("Erro ao baixar relatório PDF");
    }
  };

  const handleDownloadKeys = async () => {
    try {
      console.log("Iniciando download, filters:", filters); // Log dos filtros
      console.log("Projeto ID:", projeto._id); // Log do ID do projeto

      if (!filters.fileId) {
        toast.error("ID do arquivo não disponível");
        console.log("FileId não encontrado:", filters);
        return;
      }

      const url = `${process.env.REACT_APP_API}/report/xml/download-keys/${filters.fileId}`;
      console.log("URL da requisição:", url); // Log da URL

      const response = await axios.get(url, {
        responseType: "blob",
        headers: {
          projeto: projeto._id,
        },
      });

      console.log("Resposta recebida:", response); // Log da resposta

      // Criar o nome do arquivo com timestamp
      const timestamp = new Date().toISOString().replace(/[:.]/g, "-");
      const fileName = `chaves_${timestamp}.txt`;

      // Criar blob e fazer download
      const blob = new Blob([response.data], { type: "text/plain" });
      const url_download = window.URL.createObjectURL(blob);

      console.log("Blob criado, iniciando download"); // Log antes do download

      const link = document.createElement("a");
      link.href = url_download;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url_download);

      toast.success("Arquivo de chaves baixado com sucesso!");
    } catch (error) {
      console.error("Erro detalhado:", error); // Log detalhado do erro
      console.error("Response error:", error.response); // Log da resposta de erro
      const errorMessage =
        error.response?.data?.message || "Erro ao baixar arquivo de chaves";
      toast.error(errorMessage);
    }
  };

  if (isLoading) {
    return (
      <ModalContainer>
        <ModalContent>
          <Header>
            <Title>
              Buscando documentos
              <LoadingDots>...</LoadingDots>
            </Title>
          </Header>

          <FiltersList>
            <FilterItem>
              <FilterLabel
                style={{
                  width: "100%",
                  textAlign: "center",
                  minWidth: "unset",
                }}
              >
                Processando sua busca
              </FilterLabel>
            </FilterItem>
          </FiltersList>

          <DownloadButton
            onClick={handleCancelSearch}
            style={{
              backgroundColor: "#dc3545",
              opacity: "1",
              color: "white",
              border: "none",
            }}
          >
            <IoMdClose style={{ marginRight: "8px" }} />
            Cancelar busca
          </DownloadButton>
        </ModalContent>
      </ModalContainer>
    );
  }

  return (
    <ModalContainer>
      <ModalContent>
        <Header>
          <Title>
            {isLoading ? "Buscando documentos..." : "Filtros Ativos"}
          </Title>
          <ClearButton onClick={onClearFilters} disabled={isLoading}>
            <IoMdClose />
          </ClearButton>
        </Header>

        <FiltersList>
          {isValidDate(filters.startDate) && (
            <FilterItem>
              <FilterLabel>De:</FilterLabel>
              <FilterValue>{formatDate(filters.startDate)}</FilterValue>
            </FilterItem>
          )}

          {isValidDate(filters.endDate) && (
            <FilterItem>
              <FilterLabel>Até:</FilterLabel>
              <FilterValue>{formatDate(filters.endDate)}</FilterValue>
            </FilterItem>
          )}

          {filters.emiDoc && (
            <FilterItem>
              <FilterLabel>Emitente:</FilterLabel>
              <FilterValue>{filters.emiDoc}</FilterValue>
            </FilterItem>
          )}

          {filters.destDoc && (
            <FilterItem>
              <FilterLabel>Destinatário:</FilterLabel>
              <FilterValue>{filters.destDoc}</FilterValue>
            </FilterItem>
          )}

          {filters.cfop && (
            <FilterItem>
              <FilterLabel>CFOP:</FilterLabel>
              <FilterValue>{filters.cfop}</FilterValue>
            </FilterItem>
          )}

          {filters.type && (
            <FilterItem>
              <FilterLabel>Tipo:</FilterLabel>
              <FilterValue>{getTypeLabel(filters.type)}</FilterValue>
            </FilterItem>
          )}

          {filters.status && (
            <FilterItem>
              <FilterLabel>Status:</FilterLabel>
              <FilterValue>{filters.status}</FilterValue>
            </FilterItem>
          )}
        </FiltersList>

        <TotalCount>
          <TotalLabel>
            {isLoading ? "Contando documentos..." : "Total de documentos:"}
          </TotalLabel>
          <TotalValue>
            {isLoading ? (
              <span className="animate-pulse">...</span>
            ) : (
              totalDocuments?.toLocaleString() || 0
            )}
          </TotalValue>
        </TotalCount>
        <ButtonsContainer>
          <DownloadButton
            onClick={handleDownloadKeys}
            disabled={isLoading || !filters.fileId}
          >
            <IoMdDownload style={{ marginRight: "8px" }} />
            {isLoading ? "Aguarde..." : "Download Chaves"}
          </DownloadButton>

          <DownloadButton
            onClick={handleDownloadPDF}
            disabled={isLoading || !filters.fileId}
            className="pdf-button" // Add this for specific styling if needed
          >
            <FaFilePdf style={{ marginRight: "8px" }} />
            {isLoading ? "Aguarde..." : "Download PDF"}
          </DownloadButton>
        </ButtonsContainer>
      </ModalContent>
    </ModalContainer>
  );
};

export default ActiveFiltersModal;
