import styled from "styled-components";

export const Display = styled.div`
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;

  .header {
    width: 100%;
    min-height: 2em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${({ theme }) => theme.border5};
    background-color: ${({ theme }) => theme.custom};
    padding-left: 1em;

    .side {
      width: max-content;
      height: 100%;
      align-items: center;
      display: flex;
    }

    .add-btn {
      cursor: pointer;
      margin-right: 0.5em;
      height: 2em;
      width: 2em;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        font-size: 0.8em;
        color: ${({ theme }) => theme.primary};
      }

      &:hover {
        opacity: 0.8;
      }
    }

    p {
      font-size: 0.8em;
      opacity: 0.7;
    }

    svg {
      opacity: 0.8;
      font-size: 0.9em;
      margin-right: 1em;
    }
  }
`;

// New styled component for UserCard
export const UserCardContainer = styled.div`
  width: 100%;
  max-height: max-content;
  align-items: center; /* Adjust as needed */
  display: flex;
  flex-direction: column;

  .user {
    width: 96%;
    height: 5.8em;
    display: flex;
    background-color: ${({ theme }) => theme.custom};
    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme.border4};
    margin-left: 5px;
    margin-top: 1em;
    transform: translateY(-0.5em);
    padding: 10px 0;
    padding-top: 1em;
    padding-left: 0.2em;

    .thumb {
      width: 4em;
      height: 4em;
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: 1px solid ${({ theme }) => theme.border3};

      .circle {
        width: 60%;
        height: 60%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border: 1px solid ${({ theme }) => theme.border3};

        p {
          color: white;
        }
      }
    }

    .info {
      width: calc(100% - 4em);
      height: 100%;
      display: flex;

      .info-content {
        width: calc(100% - 3em);
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        p {
          margin-left: 15px;
          color: ${({ theme }) => theme.text};
          font-size: 0.9em;
          opacity: 0.9;
          line-height: 18px;
          font-weight: 600;

          
          &.email {
            margin-top: 0.2em;
            font-size: 0.85em;
            font-weight: 300;
          }

          &:last-child {
            font-size: 0.8em;
            opacity: 0.4;
          }
        }
        .role {
          width: max-content;
          height: 1em;
          display: flex;
          margin: 7px;
          margin-left: 10px;
          align-items: center;
          justify-content: flex-start;
          border: 1px solid rgba(0, 100, 250, 0.5);
          padding: 3px;
          padding-right: 15px;
          border-radius: 5px;
          font-size: 0.85em;
          opacity: 0.8;
          p {
            margin: 0 !important;
            color: ${({ theme }) => theme.text};
            opacity: 1 !important;
            transform: translateY(0.5px);
            font-weight: 600 !important;
            opacity: 0.6;
          }
          svg {
            margin: 0 10px;
            margin-right: 7px;
            font-size: 0.8em;
            fill: rgba(0, 100, 250, 0.5);
          }
        }
      }

      .delete {
        width: 3em;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .icon {
          cursor: pointer;

          svg {
            font-size: 0.7em;
            opacity: 0.6;
            color: ${({ theme }) => theme.text};
          }
        }
      }
    }
  }
`;
