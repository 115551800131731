// XML.js
import { useEffect, useState } from "react";
import axios from "axios";
import { Card, Container } from "./styles";
import { toast } from "react-toastify";

import { useProjects } from "@hooks/projects";
import { useAuth } from "@hooks/auth";

import PdfViewerModal from "./pdfModal";

import { IoArrowBack, IoCodeDownload } from "react-icons/io5";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { MdOutlineSendToMobile } from "react-icons/md";
import { BsMailbox } from "react-icons/bs";
import { GrMoney, GrDocumentPdf } from "react-icons/gr";

export default function XML() {
  const [pdfUrl, setPdfUrl] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { xmlData } = useProjects();
  const { token, projeto } = useAuth();

  const [isLoading, setIsLoading] = useState(true);
  const [timeoutReached, setTimeoutReached] = useState(false);

  const nfeInfo = xmlData || {};

  const emitente = nfeInfo.emit || {};
  const destinatario = nfeInfo.dest || {};
  const impostos = nfeInfo.total || {};

  // Verificar se deve renderizar as seções
  const shouldRenderEmitente = Object.keys(emitente).length > 0;
  const shouldRenderDestinatario = Object.keys(destinatario).length > 0;
  const shouldRenderImpostos = Object.keys(impostos).length > 0;

  useEffect(() => {
    console.log("Projeto:", projeto);
    console.log("XML Data:", xmlData);
  }, [projeto, xmlData]);

  const handleClose = () => {
    document.getElementById("xml").style.display = "none";
  };

  useEffect(() => {
    console.log("Verificando dados do projeto (useEffect):", projeto);

    if (projeto && projeto._id) {
      setIsLoading(false); // Projeto carregado com sucesso
      console.log("Projeto carregado:", projeto);
    } else {
      console.warn("Projeto ainda não disponível:", projeto);
    }
  }, [projeto]);

  const handleViewPDF = async () => {
    console.log("Iniciando handleViewPDF...");

    if (!token) {
      toast.error("Usuário não autenticado. Por favor, faça login novamente.");
      return;
    } else {
      console.log("Token encontrado:", token);
    }

    // Verificar se o projeto está carregado corretamente
    if (isLoading) {
      console.warn("Os dados do projeto ainda estão carregando...");
      toast.info("Aguardando carregamento do projeto...");
      return;
    }

    if (timeoutReached) {
      toast.error("Tempo limite atingido. Projeto não carregado.");
      return;
    }

    if (!projeto || !projeto._id) {
      console.error("Projeto inválido ou não encontrado:", projeto);
      toast.error(
        "Projeto não encontrado. Por favor, selecione um projeto válido."
      );
      return;
    } else {
      console.log("Projeto encontrado:", projeto);
    }

    try {
      const nfeKey = xmlData?.chave;
      console.log("Chave da NFe:", nfeKey);
      if (!nfeKey) {
        toast.error("Chave da NFe não encontrada.");
        return;
      }

      const apiUrl = `${process.env.REACT_APP_API}/report/view-danfe`;
      console.log("URL da API:", apiUrl);
      toast.info("Carregando DANFE...");

      const response = await axios.post(
        apiUrl,
        { nfe: nfeKey },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            projeto: projeto._id,
          },
          responseType: "blob",
        }
      );

      console.log("Resposta da API:", response);

      if (response.status === 200) {
        const pdfBlob = new Blob([response.data], { type: "application/pdf" });
        const pdfUrl = window.URL.createObjectURL(pdfBlob);
        setPdfUrl(pdfUrl);
        setIsModalOpen(true);
        toast.success("DANFE carregada com sucesso!");
      } else {
        console.error("Erro ao carregar DANFE, status:", response.status);
        toast.error("Erro ao carregar DANFE.");
      }
    } catch (error) {
      console.error("Erro ao carregar DANFE:", error);
      console.error("Detalhes do erro:", error.response || error.message);
      toast.error("Erro ao carregar DANFE.");
    }
  };

  const handleDownloadPDF = async () => {
    if (!token) {
      toast.error("Usuário não autenticado. Por favor, faça login novamente.");
      return;
    }

    try {
      const nfeKey = xmlData?.chave;
      if (!nfeKey) {
        toast.error("Chave da NFe não encontrada.");
        return;
      }

      const apiUrl = `${process.env.REACT_APP_API}/report/danfe`;
      toast.info("Iniciando download...");

      const response = await axios.post(
        apiUrl,
        { nfe: nfeKey },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            projeto: projeto._id,
          },
          responseType: "blob",
        }
      );

      if (response.status === 200) {
        const pdfBlob = new Blob([response.data], { type: "application/pdf" });
        const pdfUrl = window.URL.createObjectURL(pdfBlob);
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.setAttribute("download", `${nfeKey}.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(pdfUrl);
        toast.success("Download realizado com sucesso!");
      } else {
        toast.error("Erro ao gerar DANFE.");
      }
    } catch (error) {
      console.error("Erro ao baixar DANFE:", error);
      toast.error("Erro ao baixar DANFE.");
    }
  };

  const handleDownloadXML = async () => {
    if (!token) {
      toast.error("Usuário não autenticado. Por favor, faça login novamente.");
      return;
    }

    try {
      const nfeKey = xmlData?.chave;
      if (!nfeKey) {
        toast.error("Chave da NFe não encontrada.");
        return;
      }

      const apiUrl = `${process.env.REACT_APP_API}/report/download/${nfeKey}`;
      toast.info("Iniciando download do XML...");

      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
          projeto: projeto._id,
        },
        responseType: "blob",
      });

      if (response.status === 200) {
        const xmlBlob = new Blob([response.data], { type: "application/xml" });
        const xmlUrl = window.URL.createObjectURL(xmlBlob);
        const link = document.createElement("a");
        link.href = xmlUrl;
        link.setAttribute("download", `${nfeKey}.xml`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(xmlUrl);
        toast.success("Download do XML realizado com sucesso!");
      } else {
        toast.error("Erro ao baixar XML.");
      }
    } catch (error) {
      console.error("Erro ao baixar XML:", error);
      toast.error("Erro ao baixar XML.");
    }
  };

  return (
    <>
      <Container id="xml">
        <Card>
          <div className="header">
            <p>Resumo de Dados</p>
            <div className="btn" onClick={handleClose}>
              <IoArrowBack />
            </div>
          </div>

          <div className="display">
            {/* Informações da NFe */}
            <div className="tag">
              <IoMdInformationCircleOutline />
              <p>Informações da NFe</p>
            </div>
            <div className="infos">
              <div className="info">
                <p className="title">Chave:</p>
                <p>{nfeInfo.chave || "N/A"}</p>
              </div>
              <div className="info">
                <p className="title">Número:</p>
                <p>{nfeInfo.num || "N/A"}</p>
              </div>
              <div className="info">
                <p className="title">Modelo:</p>
                <p>{nfeInfo.modelo || "N/A"}</p>
              </div>
              <div className="info">
                <p className="title">Status:</p>
                <p>{nfeInfo.status || "N/A"}</p>
              </div>
              <div className="info">
                <p className="title">Data/Hora Evento:</p>
                <p>{nfeInfo.dhEvento || "N/A"}</p>
              </div>
              <div className="info">
                <p className="title">Carga:</p>
                <p>{nfeInfo.carga || "N/A"}</p>
              </div>
              <div className="info">
                <p className="title">Natureza da Operação:</p>
                <p>{nfeInfo.natOp || "N/A"}</p>
              </div>
            </div>

            {/* Emitente */}
            {shouldRenderEmitente && (
              <>
                <div className="tag">
                  <MdOutlineSendToMobile />
                  <p>Emitente</p>
                </div>
                <div className="infos">
                  <div className="info">
                    <p className="title">CNPJ:</p>
                    <p>{emitente.doc || "N/A"}</p>
                  </div>
                  <div className="info">
                    <p className="title">Nome:</p>
                    <p>{emitente.nome || "N/A"}</p>
                  </div>
                  <div className="info">
                    <p className="title">Endereço:</p>
                    <p>{emitente.endereco || "N/A"}</p>
                  </div>
                  <div className="info">
                    <p className="title">Cidade:</p>
                    <p>{emitente.cidade || "N/A"}</p>
                  </div>
                  <div className="info">
                    <p className="title">UF:</p>
                    <p>{emitente.uf || "N/A"}</p>
                  </div>
                </div>
              </>
            )}

            {/* Destinatário */}
            {shouldRenderDestinatario && (
              <>
                <div className="tag">
                  <BsMailbox />
                  <p>Destinatário</p>
                </div>
                <div className="infos">
                  <div className="info">
                    <p className="title">CNPJ:</p>
                    <p>{destinatario.doc || "N/A"}</p>
                  </div>
                  <div className="info">
                    <p className="title">Nome:</p>
                    <p>{destinatario.nome || "N/A"}</p>
                  </div>
                  <div className="info">
                    <p className="title">Endereço:</p>
                    <p>{destinatario.endereco || "N/A"}</p>
                  </div>
                  <div className="info">
                    <p className="title">Cidade:</p>
                    <p>{destinatario.cidade || "N/A"}</p>
                  </div>
                  <div className="info">
                    <p className="title">UF:</p>
                    <p>{destinatario.uf || "N/A"}</p>
                  </div>
                </div>
              </>
            )}

            {/* Impostos */}
            {shouldRenderImpostos && (
              <>
                <div className="tag">
                  <GrMoney />
                  <p>Impostos</p>
                </div>
                <div className="infos">
                  <div className="info">
                    <p className="title">ICMS:</p>
                    <p>{impostos.vICMS || "N/A"}</p>
                  </div>
                  <div className="info">
                    <p className="title">ST:</p>
                    <p>{impostos.vST || "N/A"}</p>
                  </div>
                  <div className="info">
                    <p className="title">IPI:</p>
                    <p>{impostos.vIPI || "N/A"}</p>
                  </div>
                  <div className="info">
                    <p className="title">COFINS:</p>
                    <p>{impostos.vCOFINS || "N/A"}</p>
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="bottom">
            <div
              className="action-button"
              onClick={handleViewPDF}
              title="Visualizar DANFE em modal"
            >
              <GrDocumentPdf />
              <p>VISUALIZAR DANFE</p>
            </div>
            <div
              className="action-button"
              onClick={handleDownloadPDF}
              title="Baixar DANFE em PDF"
            >
              <GrDocumentPdf />
              <p>BAIXAR PDF</p>
            </div>
            <div className="action-button" onClick={handleDownloadXML}>
              <IoCodeDownload />
              <p>BAIXAR XML</p>
            </div>
          </div>
        </Card>
      </Container>
      {isModalOpen && (
        <PdfViewerModal
          pdfUrl={pdfUrl}
          onClose={() => {
            setIsModalOpen(false);
            window.URL.revokeObjectURL(pdfUrl);
          }}
        />
      )}
    </>
  );
}
