import { IoClose, IoSearch } from "react-icons/io5";
import { Card, Container } from "./styles";
import { useState } from "react";
import List from "./components/list";
import NewProject from "./components/new_project";
import EditProject from "./components/edit_project";
import { FaUber } from "react-icons/fa6";
import Alert from "../alert";
import { useAuth } from "../../../hooks/auth";

export default function Projetos() {
  const [status, setStatus] = useState("list");
  const [Selectedproject, setSelectedproject] = useState(null);
  const { projetos } = useAuth();
  const [projeto, setProjeto] = useState(null);

  function handleClose() {
    document.getElementById("projetos").style.display = "none";
  }

  const stages = [
    {
      status: "list",
      component: (
        <List
          status={status}
          setStatus={setStatus}
          handleClose={handleClose}
          projeto={projeto}
          setProjeto={setProjeto}
          setSelectedproject={setSelectedproject}
        />
      ),
    },
    {
      status: "new_project",
      component: (
        <NewProject
          status={status}
          setStatus={setStatus}
          handleClose={handleClose}
          projeto={projeto}
          setProjeto={setProjeto}
        />
      ),
    },
    {
      status: "edit_project",
      component: (
        <EditProject
          status={status}
          setStatus={setStatus}
          handleClose={handleClose}
          projeto={projeto}
          setProjeto={setProjeto}
          selectedproject={Selectedproject}
        />
      ),
    },
  ];

  return (
    <Container id="projetos">
      <Alert />
      {stages.find((stage) => stage.status === status)?.component}
    </Container>
  );
}
