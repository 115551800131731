import React, { useState } from "react";
import axios from "axios";
import { useAuth } from "@hooks/auth";
import { ModalOverlay, Container, Header, Content, Footer } from "./styles";
import { BiUserPlus } from "react-icons/bi";

import { IoMdClose } from "react-icons/io";

export default function CreateUser({ setOpenModal }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);

  const { token, projeto } = useAuth();

  const handleCreate = async () => {
    try {
      const cargo = isAdmin ? "admin" : "user";
      const response = await axios.post(
        "https://dev.vidal-app.com/users/add-user",
        {
          name: name,
          email: email,
          senha: password,
          cargo: cargo,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setOpenModal(false);
      window.location.reload();
    } catch (error) {
      console.error("Erro ao criar o usuário:", error);
    }
  };

  return (
    <ModalOverlay onClick={() => setOpenModal(false)}>
      <Container onClick={(e) => e.stopPropagation()}>
        <Header>
          <div className="top-header">
            <div className="close" onClick={() => setOpenModal(false)}>
              <IoMdClose />
            </div>
            <BiUserPlus />

            <p>Novo Usuário</p>
          </div>
          <div className="project-header">
            <div className="project">
              <div className="thumb">
                <div
                  className="circle"
                  style={{ backgroundColor: projeto.color }}
                >
                  <p>{projeto.nome.slice(0, 1).toUpperCase()}</p>
                </div>
              </div>
              <div className="info">
                <div className="info-content">
                  <p>{projeto.nome}</p>
                  <p>{projeto.cnpj}</p>
                </div>
              </div>
            </div>
          </div>
        </Header>
        <Content>
          <div className="item">
            <input
              autoComplete="off"
              placeholder="Nome"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="item">
            <input
              placeholder="E-mail"
              autoComplete="off"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="item">
            <input
              placeholder="Senha"
              type="password"
              autoComplete="new-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="item check">
            <div className="option">
              <input
                id="admin-checkbox"
                className="checkbox"
                type="checkbox"
                checked={isAdmin}
                onChange={(e) => setIsAdmin(e.target.checked)}
              />
              <label htmlFor="admin-checkbox">Admin</label>
            </div>
          </div>
        </Content>

        <Footer>
          <div className="create-button" onClick={handleCreate}>
            <p>Criar</p>
          </div>
        </Footer>
      </Container>
    </ModalOverlay>
  );
}
