import { IoClose, IoSearch } from "react-icons/io5";
import { Card, Container } from "./styles";
import { useState } from "react";
import { FaUber } from "react-icons/fa6";
import { RiToolsFill } from "react-icons/ri";
import List from "./components/list";
import Download from "./components/scripts/download_xml";
import Revisor from "./components/scripts/revisor";
import Excel from "./components/scripts/excel";

export default function Tools() {
  const [status, setStatus] = useState("list");

  function handleClose() {
    document.getElementById("tools").style.display = "none";  
  } 
  const stages = [
    {
      status: "download_xml",
      component: (
        <Download
          status={status}
          setStatus={setStatus}
          handleClose={handleClose}
        />
      ),
    },
    {
      status: "list",
      component: (
        <List status={status} setStatus={setStatus} handleClose={handleClose} />
      ),
    },
    {
      status: "revisor",
      component: (
        <Revisor
          status={status}
          setStatus={setStatus}
          handleClose={handleClose}
        />
      ),
    },
    {
      status: "excel",
      component: (
        <Excel
          status={status}
          setStatus={setStatus}
          handleClose={handleClose}
        />
      ),
    },
  ];
  return (
    <Container id="tools">
      {stages.find((stage) => stage.status === status)?.component}

    </Container>
  );
}
