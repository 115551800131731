import React, { useEffect, useState } from "react";
import {
  Container,
  Box,
  Header,
  Search,
  Projeto,
  Send,
  Button,
} from "./styles";
import { FaRegIdCard } from "react-icons/fa";
import { IoCubeOutline } from "react-icons/io5";

import useLocalStorageState from "@hooks/useLocalStorage";
import axios from "axios";
import { useAuth } from "@hooks/auth";
import { useProjects } from "@hooks/projects";

export default function Projetos() {
  const { projeto, setProjeto, getProjeto, user, setUser, projetos } =
    useAuth();
  const { fetchUserProjects, userProjects } = useProjects();
  const [cnpj, setCnpj] = useState("");
  const [isCnpj, setIsCnpj] = useState(false);
  const [infos, setInfos] = useLocalStorageState(
    "projetos",
    "projetos_receita",
    []
  );

  function isValidCNPJ(cnpj) {
    cnpj = cnpj.replace(/[^\d]+/g, ""); // Remove all non-numeric characters

    if (cnpj.length !== 14) {
      return false;
    }

    // Invalid known CNPJs
    if (/^(\d)\1{13}$/.test(cnpj)) {
      return false;
    }

    let length = cnpj.length - 2;
    let numbers = cnpj.substring(0, length);
    let digits = cnpj.substring(length);
    let sum = 0;
    let pos = length - 7;
    for (let i = length; i >= 1; i--) {
      sum += numbers.charAt(length - i) * pos--;
      if (pos < 2) pos = 9;
    }
    let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (result != digits.charAt(0)) {
      return false;
    }

    length = length + 1;
    numbers = cnpj.substring(0, length);
    sum = 0;
    pos = length - 7;
    for (let i = length; i >= 1; i--) {
      sum += numbers.charAt(length - i) * pos--;
      if (pos < 2) pos = 9;
    }
    result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (result != digits.charAt(1)) {
      return false;
    }

    return true;
  }

  function buscarCnpj(str) {
    setCnpj(str);
    setIsCnpj(isValidCNPJ(str));
  }

  function getReceitaInfo() {
    const receita_uri = `https://dev.vidal-app.com/receita`;
    axios.get(`${receita_uri}/${cnpj}`).then((res) => {
      if (res.status === 200) {
        alert("CNPJ encontrado");
        setInfos(res.data);
      } else {
        alert("CNPJ não encontrado");
      }
    });
  }

  function criarProjeto() {
    const projetos_uri = `https://dev.vidal-app.com/projetos`;
    const projetoData = {
      nome: infos.nome,
      cnpj,
      users: [user._id], // ou quaisquer IDs de usuários que você queira associar ao projeto
    };

    axios
      .post(projetos_uri, projetoData)
      .then((res) => {
        if (res.status === 201) {
          alert("Projeto criado com sucesso");
          setProjeto(res.data);
        } else {
          alert("Erro ao criar projeto");
        }
      })
      .catch((error) => {
        alert("Erro ao criar projeto: " + error.message);
      });
  }

  const handleProject = (project) => {
    setProjeto(project);
  };

  useEffect(() => {
    if (infos.nome === undefined && isValidCNPJ(cnpj)) {
      getReceitaInfo();
    }
  }, [cnpj]);

  useEffect(() => {
    if(projetos.length > 0) {
      setProjeto(projetos[0]);
    }
  }, [projetos]);

  return (
    <Container>
      <Header>
        <div className="thumb">
          <div className="circle">
            <p>{user.name.slice(0, 1)}</p>
          </div>
        </div>
        <div className="text">
          <p>{user.name}</p>
          <p>{user.email}</p>
        </div>
      </Header>
      <Search className={!isCnpj && "ativ"}>
        <FaRegIdCard />
        <input
          onChange={(e) => buscarCnpj(e.target.value)}
          placeholder="Digite um CNPJ para criar um projeto"
        ></input>
      </Search>
      <Box>
        {projetos.length > 0 &&
          projetos.map((project) => (
            <Projeto key={project._id} onClick={() => handleProject(project)}>
              <div className="header">
                <div className="thumb">
                  <IoCubeOutline />
                </div>
                <div className="text">
                  <p>{project.nome}</p>
                  <p>{project.cnpj}</p>
                </div>
              </div>
            </Projeto>
          ))}
      </Box>
      <Send>
        <Button onClick={criarProjeto}>
          <p>Criar Projeto</p>
        </Button>
      </Send>
    </Container>
  );
}
