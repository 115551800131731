import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  input {
    background-color: ${({ theme }) => theme.custom4};
  }


`;

export const Card = styled.div`
  width: max-content;
  width: 26em;
  height: 70vh;
  display: flex;
  flex-direction: column;
  font-size: 1.2em;
  border-radius: 7px;
  z-index: 2;
  background-color: ${({ theme }) => theme.custom4};
  border: 1px solid ${({ theme }) => theme.border5};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  .header {
    width: 100%;
    min-height: 2em;
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${({ theme }) => theme.border3};
    background-color: ${({ theme }) => theme.custom};
    position: relative;
    justify-content: center;

    svg {
      font-size: 0.8em;
      margin-right: 10px;
    }

    p {
      font-size: 0.8em;
      font-weight: 600;
    }
    .btn {
      position: absolute;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      width: 2em;
      height: 100%;
      border-right: 1px solid ${({ theme }) => theme.border3};
      background-color: ${({ theme }) => theme.custom};

      svg {
        margin: 0;
        font-size: 0.8em;
        opacity: 0.7;
      }
    }
  }

  .search {
    width: 100%;
    min-height: 1.5em;
    display: flex;
    align-items: center;
    input {
      height: 100%;
      border-radius: 0;
      width: 100%;
      display: flex;
      font-size: 0.8em;
    }

    svg {
      position: absolute;
      margin-left: 0.5em;
      font-size: 0.8em;
      opacity: 0.6;
    }
  }

  .tag {
    width: 100%;
    min-height: 2em;
    display: flex;
    align-items: center;
    opacity: 0.9;
    border-bottom: 1px solid ${({ theme }) => theme.border5};
    position: relative;
    svg {
      margin: 0 10px;
      margin-right: 5px;
      font-size: 0.85em;
    }

    p {
      font-size: 0.65em;
      font-weight: 600;
    }

    border-bottom: 1px solid ${({ theme }) => theme.border3};
    background-color: ${({ theme }) => theme.custom};

    .info {
      width: max-content;
      height: 100%;
      display: flex;
      align-items: center;
      border-right: 1px solid ${({ theme }) => theme.border3};
      padding-right: 20px;
      opacity: 0.9;

      p {
        transform: translateY(1px);
      }
    }

    .btn {
      width: 2em;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-left: 1px solid ${({ theme }) => theme.border5};
      cursor: pointer;
      position: absolute;
      right: 0;
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }
  }

  .display {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    overflow-y: overlay;

    .display {
      width: 100%;
      height: max-content;
      overflow: hidden;
      overflow-y: overlay;
      display: flex;
      flex-direction: column;

      .card {
        width: 100%;
        display: flex;
        height: 2.5em;
        background-color: ${({ theme }) => theme.custom};
        border-top: 1px solid ${({ theme }) => theme.border3};

        .thumb {
          min-width: 2.5em;
          height: 100%;
          border-right: 1px solid ${({ theme }) => theme.border3};
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            font-size: 0.8em;
            opacity: 0.7;
          }
        }

        .content {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;

          p {
            margin-left: 1em;
            font-size: 0.75em;
            font-weight: 600;
            line-height: 18px;
          }

          p:last-child {
            font-size: 0.65em;
            opacity: 0.7;
            font-weight: 500;
          }
        }
      }
    }
    .picker {
      width: 100%;
      height: 2.5em;
      display: flex;
      align-items: center;
      .pick {
        width: max-content;
        height: 1.5em;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${({ theme }) => theme.custom};
        margin: 0 0.5em;
        border-radius: 5px;
        padding: 0 20px;
        opacity: 0.85;
        cursor: pointer;
        &:hover,
        &.ativ {
          opacity: 1;
        }

        svg {
          font-size: 0.8em;
          margin-right: 5px;
        }

        p {
          font-size: 0.7em;
          transform: translateY(1px);
        }

        &.user {
          border: 1px solid rgba(0, 200, 250, 0.6);

          svg {
            color: rgba(0, 200, 250, 0.6);
            fill: rgba(0, 100, 150, 1);
          }
        }

        &.cliente {
          border: 1px solid rgba(0, 250, 100, 0.6);

          svg {
            color: rgba(0, 150, 50, 0.6);
            fill: rgba(0, 200, 150, 1);
          }
        }

        &.adm {
          border: 1px solid rgba(250, 230, 0, 1);

          svg {
            fill: rgba(250, 230, 0, 1);
          }
        }
      }
    }
    .input {
      font-size: 1.1em;
      width: 100%;
      height: 3em;
      display: flex;
      align-items: center;
      background-color: ${({ theme }) => theme.custom3};
      border-bottom: 1px solid ${({ theme }) => theme.border3};
      svg {
        position: absolute;
        margin-left: 1.8em;
        font-size: 0.8em;
        opacity: 0.6;
      }

      input {
        height: 1.8em;
        border-radius: 7px;
        border: 1px solid ${({ theme }) => theme.border5};
        margin-left: 1em;
        width: 84%;
        font-size: 0.8em;
      }
    }

    .files {
      width: 100%;
      min-height: 10em;
      height: 100%;
      display: flex;
      flex-direction: column;
      background-color: ${({ theme }) => theme.custom4};
      overflow: overlay;

      .file {
        width: 100%;
        min-height: 3.2em;
        background-color: ${({ theme }) => theme.custom};
        display: flex;
        border-bottom: 1px solid ${({ theme }) => theme.border5};
        margin: 1px 0;
        opacity: 0.7;

        &:hover {
          opacity: 1;
        }

        .thumb {
          width: 3em;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          border-right: 1px solid ${({ theme }) => theme.border5};

          svg {
            opacity: 0.6;
          }
        }

        .content {
          width: calc(100% - 3em);
          display: flex;
          flex-direction: column;
          justify-content: center;
          position: relative;

          .text {
            width: 100%;
            height: max-content;
            display: flex;
            align-items: center;
            justify-content: space-between;

            p {
              font-size: 0.7em;
              font-weight: 500;
              margin-right: 2em;
            }
          }

          .load {
            width: 100%;
            height: 0.3em;
            display: flex;
            background-color: ${({ theme }) => theme.custom5};
            position: absolute;
            bottom: 0;
            border-top: 1px solid ${({ theme }) => theme.border3};

            .progress {
              width: 0%;
              height: 100%;
              background-color: rgba(0, 200, 100);
            }
          }

          svg {
            position: absolute;
            right: 0;
            margin-right: 1em;
            font-size: 0.8em;
            opacity: 0.5;
            color: rgba(200, 0, 0);
            cursor: pointer;
          }

          p {
            font-size: 0.8em;
            line-height: 18px;
            margin-left: 1em;
            font-weight: 600;
          }

          p.size {
            font-size: 0.7em;
            opacity: 0.7;
          }
        }
      }
    }

    .drop {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.85em;

      .drop-area {
        width: 95%;
        height: 85%;
        display: flex;
        border-radius: 10px;
        align-items: center;
        justify-content: center;
        border: 2px dashed rgba(0, 150, 255, 0.3);

        background-color: ${({ theme }) => theme.custom2};

        .content {
          width: 20em;
          height: max-content;
          display: flex;
          align-items: center;
          justify-content: center;

          .thumb {
            width: 2em;
            height: 2em;
            display: flex;
            align-items: center;
            border: 1px solid rgba(0, 150, 250, 0.5);
            justify-content: center;
            border-radius: 100%;
            margin-right: 1em;

            color: rgba(0, 150, 250);

            p {
              font-size: 0.8em;
            }
          }
          .text {
            width: 13em;
            height: max-content;
            display: flex;
            flex-direction: column;

            p:last-child {
              font-size: 0.7em;
              opacity: 0.7;
            }
          }
        }
      }
    }

    .item {
      width: 100%;
      min-height: 9em;
      display: flex;
      border-top: 1px solid ${({ theme }) => theme.border3};
      background-color: ${({ theme }) => theme.custom};
      margin: 1px 0;
      cursor: pointer;
      opacity: 0.9;
      transition: all 0.1s;

    
      &.deaf {
        opacity: .5;
      }
      &:hover,
      &.ativ {
        opacity: 1;
      }

      &.ativ {
        cursor: default;
      }
      .thumb {
        width: 6em;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid ${({ theme }) => theme.border3};
        background-color: ${({ theme }) => theme.custom5};

        svg {
          color: rgba(0, 150, 200);
          border-radius: 100%;
          box-shadow: 0px 0px 10px rgba(0, 150, 200, 0.3);
        }
        .circle {
          width: 2.5em;
          height: 2.5em;
          display: flex;
          align-items: center;
          border: 1px solid ${({ theme }) => theme.border3};
          border-radius: 50%;
          justify-content: center;
          font-weight: 800;
          color: white;
          background-color: rgba(0, 150, 70, 1);
        }
      }

      .content {
        width: calc(100% - 4em);
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        p {
          margin-bottom: 5px;
          width: 90%;
          margin-left: 1em;
          font-size: 0.8em;
          color: ${({ theme }) => theme.text};
          opacity: 0.9;
          font-weight: 600;
          line-height: 18px;
        }
        p:last-child {
          font-size: 0.7em;
          opacity: 0.7;
          font-weight: 500;
          margin-left: 16px;
        }
      }
    }
  }

  .bottom {
    width: 100%;
    min-height: 4em;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.custom};
    border-top: 1px solid ${({ theme }) => theme.border3};

    .btn {
      width: 15em;
      height: 2.7em;
      display: flex;
      align-items: center;
      background-color: rgba(0, 150, 70, 1);
      justify-content: center;
      border-radius: 7px;
      font-size: 0.55em;
      cursor: pointer;
      color: white;
      opacity: 0.9;
      transition: all 0.1s;
      border: 1px solid ${({ theme }) => theme.border5};
      box-shadow: 0px 0px 3px ${({ theme }) => theme.border3};
      &:hover {
        opacity: 1;
        box-shadow: 0px 0px 3px rgba(0, 255, 100, 0.4);
      }

      p {
        font-weight: 600;
        font-size: 1.2em;
      }
    }
  }
`;
