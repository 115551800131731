import React from "react";
import { Card, Display } from "./styles";
import { FaRegEye } from "react-icons/fa6";
import { IoCodeOutline } from "react-icons/io5";

export default function ScriptsCard() {
  function ReportScript() {
    return (
      <Card>
        <div className="head">
          <FaRegEye />
          <p>Revisar SPED's</p>
        </div>
        <div className="desc">
          <div className="text">
            <p>
              O algoritmo lê arquivos XML de Notas Fiscais Eletrônicas de um
              diretório específico, convertendo-os em objetos JSON estruturados
              através de um extrator personalizado. Ele extrai informações
              cruciais como dados do emitente, destinatário, valores e itens da
              nota.
            </p>
          </div>
        </div>
      </Card>
    );
  }
  return (
    <Display>
      <div className="header">
        <div className="side">
          <IoCodeOutline />
          <p>Scripts</p>
        </div>
      </div>
    </Display>
  );
}
