import React from "react";
import { Card, Container } from "./styles";

const DropBox = () => {
  return (
    <Container id="dropbox">
      <Card>{/* Conteúdo do DropBox */}</Card>
    </Container>
  );
};

export default DropBox;
