import { IoClose, IoSearch } from "react-icons/io5";
import { Card, Container } from "./styles";
import { useState } from "react";
import List from "./components/list";
import NewUser from "./components/new_user";
import EditUser from "./components/edit_user";
import { FaUber } from "react-icons/fa6";
import Alert from "../alert";

export default function Users() {
  const [status, setStatus] = useState("list");
  const [SelectedUser, setSelectedUser] = useState(null);

  function handleClose() {
    document.getElementById("users").style.display = "none";
  }

  const stages = [
    {
      status: "list",
      component: (
        <List
          status={status}
          setStatus={setStatus}
          handleClose={handleClose}
          setSelectedUser={setSelectedUser}
        />
      ),
    },
    {
      status: "new_user",
      component: (
        <NewUser
          status={status}
          setStatus={setStatus}
          handleClose={handleClose}
        />
      ),
    },
    {
      status: "edit_user",
      component: (
        <EditUser
          status={status}
          setStatus={setStatus}
          handleClose={handleClose}
          selectedUser={SelectedUser}
        />
      ),
    },
  ];

  return (
    <Container id="users">
      <Alert />
      {stages.find((stage) => stage.status === status)?.component}
    </Container>
  );
}
