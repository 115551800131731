import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { IoAddOutline, IoPencilOutline } from "react-icons/io5"; // Ícone de edição
import { StyledProjectDropdown } from "./styles";
import { useAuth } from "@hooks/auth";
import { useDropdown } from "@hooks/dropDown";
import Modal from "../modal/index";
import Projetos from "../../../auth/components/projetos/index";
import SelectProject from "./components/selectProjetos/index";
import { IoCubeOutline } from "react-icons/io5";
import { useProjects } from "../../../../hooks/projects";
import NewProject from "../../../modals/projetos/components/new_project";

const ProjectDropdown = ({
  triggerRef,
  onSelectProject,
  onCreateProject,
  projects,
  initialPosition = { top: 65, left: 210 },
  useCustomPosition = false,
  projectInfo,
}) => {
  const [selectedProject, setSelectedProject] = useState(null);
  const { userProjects } = useProjects();
  const { projeto } = useAuth();
  const {
    setIsOpen,
    isOpen,
    isModalOpen,
    setIsModalOpen,
    isEditModalOpen,
    setIsEditModalOpen,
  } = useDropdown();

  const dropdownRef = useRef(null);
  const [dropdownPosition, setDropdownPosition] = useState(initialPosition);

  // Ajustar a posição do dropdown
  useEffect(() => {
    if (triggerRef.current && dropdownRef.current && !useCustomPosition) {
      const triggerRect = triggerRef.current.getBoundingClientRect();
      const dropdownRect = dropdownRef.current.getBoundingClientRect();

      let top = triggerRect.bottom + window.scrollY + initialPosition.top;
      let left = triggerRect.left + window.scrollX + initialPosition.left;

      if (top + dropdownRect.height > window.innerHeight) {
        top =
          triggerRect.top -
          dropdownRect.height +
          window.scrollY +
          initialPosition.top;
      }

      if (left + dropdownRect.width > window.innerWidth) {
        left =
          window.innerWidth - dropdownRect.width - 10 + initialPosition.left;
      }

      setDropdownPosition({ top, left });
    }
  }, [triggerRef, initialPosition, useCustomPosition]);

  // Fechar dropdown ao clicar fora dele
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setIsOpen]);

  const handleCreateProject = () => {
    setIsModalOpen(true);
  };

  const handleEditProject = () => {
    setIsEditModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsEditModalOpen(false);
  };

  return (
    <>
      <StyledProjectDropdown
        ref={dropdownRef}
        style={{
          ...dropdownPosition,
          display: isModalOpen || isEditModalOpen ? "none" : "flex",
        }}
      >
        <ul>
          {userProjects.map((project) => (
            <li key={project._id} onClick={() => onSelectProject(project)}>
              <div className="thumb">
                <IoCubeOutline style={{ color: project.color }} />
              </div>
              <div className="text">
                <h2>{project.nome}</h2>
                <p>{project.cnpj}</p>
              </div>
            </li>
          ))}
          <li className="actions">
            <button
              onClick={() => {
                setIsOpen(false);
                document.getElementById("create-projetos").style.display =
                  "flex";
              }}
              className="new-project"
            >
              <IoAddOutline /> Criar Novo Projeto
            </button>
            <button
              onClick={() => {
                setIsOpen(false);
                document.getElementById("projetos").style.display = "flex";
              }}
              className="edit-project"
            >
              <IoPencilOutline /> Editar Projeto
            </button>
          </li>
        </ul>
      </StyledProjectDropdown>

      <NewProject />

      <SelectProject
        isOpen={isEditModalOpen}
        onClose={handleCloseModal}
        projects={projects}
        projectInfo={projectInfo}
      />
    </>
  );
};

export default ProjectDropdown;
