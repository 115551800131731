import React, {
  createContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
  useContext,
} from "react";

// Criação do contexto do LocalStorage
export const LocalStorageContext = createContext();

// Função para ler todos os dados do localStorage
export const readLocalStorage = () => {
  return Object.keys(localStorage).reduce((acc, key) => {
    try {
      acc[key] = JSON.parse(localStorage.getItem(key));
    } catch {
      acc[key] = localStorage.getItem(key); // Retorna como string se falhar a conversão para JSON
    }
    return acc;
  }, {});
};

// Função para verificar se um valor é serializável
const isSerializable = (value) => {
  try {
    JSON.stringify(value);
    return true;
  } catch {
    return false;
  }
};

// Provider para gerenciar o estado do LocalStorage
export function LocalStorageProvider({ children }) {
  const [localStorageState, setLocalStorageState] = useState(readLocalStorage);
  const prevLocalStorageState = useRef(localStorageState);

  // Atualiza o localStorage com a nova chave e valor
  const updateLocalStorage = useCallback((key, value) => {
    if (isSerializable(value)) {
      const storedValue = localStorage.getItem(key);
      const parsedStoredValue = storedValue ? JSON.parse(storedValue) : null;

      if (JSON.stringify(value) !== JSON.stringify(parsedStoredValue)) {
        value !== undefined && value !== null
          ? localStorage.setItem(key, JSON.stringify(value))
          : localStorage.removeItem(key);
      }
    } else {
      console.warn(`Valor não serializável detectado para a chave: ${key}`);
    }
  }, []);

  // Sincroniza o estado do localStorageState com o localStorage do navegador
  useEffect(() => {
    Object.keys(localStorageState).forEach((key) => {
      updateLocalStorage(key, localStorageState[key]);
    });
    prevLocalStorageState.current = localStorageState;
  }, [localStorageState, updateLocalStorage]);

  // Define um estado aninhado dentro de uma chave específica
  const setNestedState = useCallback((key, nestedKey, value) => {
    setLocalStorageState((prevState) => {
      const updatedState = {
        ...prevState,
        [key]: nestedKey
          ? { ...(prevState[key] || {}), [nestedKey]: value }
          : value,
      };
      return updatedState;
    });
  }, []);

  // Função para obter o estado de uma chave específica
  const getState = useCallback(
    (key) => localStorageState[key],
    [localStorageState]
  );

  // Memoize o valor do contexto para evitar renders desnecessárias
  const value = useMemo(
    () => ({
      localStorageState,
      setLocalStorageState,
      setNestedState,
      getState,
    }),
    [localStorageState, setNestedState, getState]
  );

  return (
    <LocalStorageContext.Provider value={value}>
      {children}
    </LocalStorageContext.Provider>
  );
}

// Hook customizado para acessar o estado do localStorage
export default function useLocalStorageState(
  key,
  nestedKeyOrInitialValue,
  maybeInitialValue
) {
  const context = useContext(LocalStorageContext);

  // Determina se o hook está sendo usado dentro do provider ou não
  const isUsingProvider = Boolean(context);

  // Se o contexto estiver presente, usa o provider, caso contrário, usa o localStorage diretamente
  const { localStorageState, setLocalStorageState } = isUsingProvider
    ? context
    : {
        localStorageState: readLocalStorage(),
        setLocalStorageState: (callback) => {
          const newState = callback(readLocalStorage());
          Object.keys(newState).forEach((k) => {
            if (isSerializable(newState[k])) {
              localStorage.setItem(k, JSON.stringify(newState[k]));
            } else {
              console.warn(
                `Valor não serializável detectado para a chave: ${k}`
              );
            }
          });
        },
      };

  // Determina a chave e o valor inicial para o estado
  const { nestedKey, initialValue } = useMemo(() => {
    if (typeof key === "object") {
      const nested = Object.keys(key)[0];
      return { nestedKey: nested, initialValue: key[nested] };
    }
    return maybeInitialValue !== undefined
      ? { nestedKey: nestedKeyOrInitialValue, initialValue: maybeInitialValue }
      : { nestedKey: null, initialValue: nestedKeyOrInitialValue };
  }, [key, nestedKeyOrInitialValue, maybeInitialValue]);

  // Inicializa o estado com base no localStorage ou no valor inicial
  const [state, setState] = useState(() => {
    const storedData = localStorageState[key];
    return storedData && nestedKey
      ? storedData[nestedKey] ?? initialValue
      : storedData ?? initialValue;
  });

  // Sincroniza o estado local com o localStorageState
  useEffect(() => {
    setLocalStorageState((prevState) => {
      if (nestedKey) {
        const updatedNestedState = {
          ...(prevState[key] || {}),
          [nestedKey]: state,
        };
        return { ...prevState, [key]: updatedNestedState };
      }
      return prevState[key] !== state
        ? { ...prevState, [key]: state }
        : prevState;
    });
  }, [key, nestedKey, state, setLocalStorageState]);

  return [state, setState];
}
