import { useEffect, useState } from "react";
import axios from "axios";
import { Card } from "../../styles";
import { useAuth } from "../../../../../hooks/auth";
import DeleteAlert from "../deleteAlert";

import { IoArrowBack, IoSearch, IoTrash } from "react-icons/io5";
import { FaCheck } from "react-icons/fa";
import { AiOutlineUser } from "react-icons/ai";
import { PiIdentificationBadge } from "react-icons/pi";
import { TbEditCircle } from "react-icons/tb";

export default function EditProject({ projeto, setStatus }) {
  const [name, setName] = useState(projeto.nome || "");
  const [cnpj, setCnpj] = useState(projeto.cnpj);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [sortedUsers, setSortedUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [searchedUser, setSearchedUser] = useState(null);

  const { token, user } = useAuth();

  // Filtra usuários com base no termo de pesquisa
  const filteredUsers = sortedUsers.filter((user) =>
    user.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    // Organiza os usuários em ordem alfabética, excluindo aqueles com cargo 'god'
    const sorted = [...projeto.users]
      .filter((user) => user.cargo !== "god")
      .sort((a, b) => a.name.localeCompare(b.name));

    setSortedUsers(sorted);

    // Define os IDs dos usuários do projeto como selecionados
    setSelectedUsers(
      sorted.map((user) => user._id).filter((id) => id !== undefined)
    );
  }, [projeto.users]);

  function handleAlert(item, type) {
    setItemToDelete({ ...item, type });
    setShowAlert(true);
  }

  function handleCloseAlert() {
    setShowAlert(false);
    setItemToDelete(null);
  }

  const handleUsersFromProject = async (email) => {
    try {
      const response = await axios.post(
        `https://dev.vidal-app.com/users/email`,
        { email },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            projeto: projeto._id,
            user: user._id,
          },
        }
      );

      if (response.data && response.data.name) {
        setSearchedUser(response.data);
        console.log(`Usuário encontrado: ${response.data.name}`);
      } else {
        console.error("Usuário não encontrado.");
      }
    } catch (error) {
      console.error("Erro ao buscar usuário:", error);
    }
  };

  const handleDelete = async () => {
    console.log(`Projeto ${itemToDelete?.nome || "Desconhecido"} excluído!`);
    handleCloseAlert();
  };

  const submit = async () => {
    try {
      const data = {
        projectData: {},
      };

      if (name !== projeto.nome) {
        data.projectData.nome = name;
      }

      const userIds = selectedUsers.sort((a, b) =>
        a.toLowerCase().localeCompare(b.toLowerCase())
      );

      if (userIds.length > 0) {
        data.userIds = userIds;
      }

      console.log("Enviando dados:", data);

      const response = await axios.put(
        "https://dev.vidal-app.com/projetos/update-project",
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setStatus("list");
    } catch (err) {
      console.error("Erro ao atualizar o projeto:", err);
    }
  };

  const handleSelectUser = (user) => {
    setSelectedUsers((prevSelectedUsers) => {
      const isUserSelected = prevSelectedUsers.includes(user._id);

      if (isUserSelected) {
        // Deselecionar: Remove o usuário da lista de selecionados
        return prevSelectedUsers.filter((id) => id !== user._id);
      } else {
        // Selecionar: Adiciona o usuário à lista de selecionados
        return [...prevSelectedUsers, user._id];
      }
    });

    // Se o usuário selecionado é o pesquisado, limpamos a pesquisa e o adicionamos à lista de usuários
    if (searchedUser && user._id === searchedUser._id) {
      setSearchTerm("");
      setSearchedUser(null);

      // Adiciona o usuário pesquisado aos sortedUsers se ainda não estiver presente
      setSortedUsers((prevSortedUsers) => {
        const isUserInSortedUsers = prevSortedUsers.some(
          (u) => u._id === user._id
        );
        if (!isUserInSortedUsers) {
          return [...prevSortedUsers, user].sort((a, b) =>
            a.name.localeCompare(b.name)
          );
        }
        return prevSortedUsers;
      });
    }
  };

  useEffect(() => {
    if (searchTerm) {
      const delayDebounceFn = setTimeout(() => {
        handleUsersFromProject(searchTerm);
      }, 500);

      return () => clearTimeout(delayDebounceFn);
    } else {
      setSearchedUser(null);
    }
  }, [searchTerm]);

  return (
    <Card>
      <div className="header">
        <div className="btn" onClick={() => setStatus("list")}>
          <IoArrowBack />
        </div>
        <TbEditCircle />
        <p>Editando projeto</p>
      </div>
      <div className="display">
        <div className="item ativ">
          <div className="thumb">
            <div className="circle" style={{ backgroundColor: projeto.color }}>
              <p>{projeto.nome.slice(0, 1)}</p>
            </div>
          </div>
          <div className="content">
            <p>{projeto.nome}</p>
            <p className="email">{projeto.cnpj}</p>
            <IoTrash onClick={() => handleAlert(projeto, "project")} />
          </div>
        </div>
        <div className="tag">
          <PiIdentificationBadge />
          <p>informações</p>
        </div>

        <div className="input">
          <AiOutlineUser />
          <input
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Nome do projeto"
          />
        </div>

        <div className="input">
          <input value={projeto.cnpj} readOnly />
        </div>
        <div className="tag">
          <PiIdentificationBadge />
          <p>Usuários</p>
        </div>

        <div className="search">
          <IoSearch />
          <input
            placeholder="Digite o nome do usuário"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <div className="display">
          {filteredUsers.map((user) => {
            const isSelected = selectedUsers.includes(user._id);
            return (
              <div
                className="card"
                key={user._id}
                onClick={() => handleSelectUser(user)}
                style={{
                  opacity: isSelected ? 1 : 0.4,
                }}
              >
                <div className="thumb">
                  <div
                    className="circle"
                    style={{ backgroundColor: user.color }}
                  >
                    <p>{user.name.charAt(0).toUpperCase()}</p>
                  </div>
                </div>
                <div className="content">
                  <p>{user.name}</p>
                  <p>{user.email}</p>
                </div>
                <div
                  className="check"
                  style={{
                    color: isSelected ? "green" : "gray",
                  }}
                >
                  <FaCheck />
                </div>
              </div>
            );
          })}
          {searchedUser && (
            <div
              className="card"
              onClick={() => handleSelectUser(searchedUser)}
              style={{
                opacity: selectedUsers.includes(searchedUser._id) ? 1 : 0.4,
              }}
            >
              <div className="thumb">
                <div
                  className="circle"
                  style={{ backgroundColor: searchedUser.color }}
                >
                  <p>{searchedUser.name.charAt(0).toUpperCase()}</p>
                </div>
              </div>
              <div className="content">
                <p>{searchedUser.name}</p>
                <p>{searchedUser.email}</p>
              </div>
              <div
                className="check"
                style={{
                  color: selectedUsers.includes(searchedUser._id)
                    ? "green"
                    : "gray",
                }}
              >
                <FaCheck />
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="bottom">
        <div className="btn" onClick={submit}>
          <p>SALVAR</p>
        </div>

        <DeleteAlert
          show={showAlert}
          handleClose={handleCloseAlert}
          handleDelete={handleDelete}
        />
      </div>
    </Card>
  );
}
